import {useContext}                     from 'react';
import { Link }                         from 'react-router-dom';
import Container                        from 'react-bootstrap/Container';
import Row                              from 'react-bootstrap/Row';
import Col                              from 'react-bootstrap/Col';

import { AuthContext }                  from 'App';

const habriLogo = require('../img/hc_logo_white_trans.png').default;


const AppFooter = () => {

    const globalAuth = useContext(AuthContext);

    return (

        <div id="footer" className={`footer container-fluid`}>

            <Container fluid>

                <Row>
                    <Container fluid className={`sponsors`}>
                        <Container className={`me-0`}>
                            <div id="sponsors" className={`row sponsors`}>
                                <div className={`container`}>
                                    <div className={`align-content-center h-100 row`}>
                                        <div id="logos" className={`col-4 offset-5 logos`}>
                                            <a href="http://www.habri.org/" rel="external" target="_blank">
                                                <img alt="HABRI" src="/img/habri-logo-s.png"/>
                                            </a>
                                            <a href="http://www.lib.purdue.edu/" rel="external" target="_blank">
                                                <img alt="Libraries, Purdue University" src="/img/purduelib.png"/>
                                            </a>
                                            <a href="http://www.vet.purdue.edu/" rel="external" target="_blank">
                                                <img alt="College of Veterinary Medicine, Purdue University" src="/img/vetmed.png"/>
                                            </a>
                                        </div>
                                        <div id="madepossible" className={`col-3 madepossible`}>
                                            <span>HABRI Central is made possible through a generous grant from the Human Animal Bond Research Institute (HABRI)</span>
                                            {/*<span>{`I think it ${globalAuth}.`}</span>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </Container>
                </Row>

                <Row>
                    <div id="bottom-nav" className={`bottom-nav`}>
                        <ul className="bmenu">
                            <li>
                                <Link to={`/legal/terms`}>Terms of Use</Link>
                            </li>
                            <li>
                                <Link to={`/about/sponsors`}>Sponsors</Link>
                            </li>
                            <li>
                                <Link to={`/about/contact`}>Contact Us</Link>
                            </li>
                            <li>
                                <Link to={`/support`}>Support</Link>
                            </li>
                            <li>
                                <Link to={`/about/accessibility`}>Accessibility Issues</Link>
                            </li>
                        </ul>
                    </div>
                </Row>

            </Container>
        
        </div>

    );

};

export default (AppFooter);
