import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import { OktaAuth } from '@okta/okta-auth-js';
// import { Security } from '@okta/okta-react';
import 'bootstrap/dist/css/bootstrap.css';
// import './core.scss';
import App from './App';
import reportWebVitals from './reportWebVitals'; 
import * as serviceWorker from './serviceWorkerRegistration';

import index from './js/index';

import store from './store.js';
import { Provider } from 'react-redux';



ReactDOM.render(

        <BrowserRouter>
            <App />
        </BrowserRouter>,
        document.getElementById('root'),

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) module.hot.accept();