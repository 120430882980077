import React, { Component, Fragment } from 'react';
import {
    withStyles,
    Card,
    CardContent,
    CardActions,
    Modal,
    Button,
    TextField,
} from '@material-ui/core';
import { compose } from 'recompose';
import { Route, Redirect, Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { find, orderBy } from 'lodash';


import ResourceAddSelectType       from 'components/ResourceAdder/ResourceAddSelectType'; 
import ResourceAddEssentialMetadata from 'components/ResourceAdder/ResourceAddEssentialMetadata'; 
import ResourceAddPayload           from 'components/ResourceAdder/ResourceAddPayload'; 


const API = process.env.REACT_APP_API || 'http://localhost:3001';



const styles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalCard: {
        width: '90%',
        maxWidth: 500,
    },
    modalCardContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
});


class ResourceAdder extends Component {

    state = {
        loading: true,
        resourceTypes: [],
        resourceModel: [],
        resource: {
            type: null,
            metadata: {}
        },
        error: null,
        formStage: 0,
    }

    componentDidMount() {
        this.getResourceTypes();
    }


    async fetch(method, endpoint, body) {

        try {

            const response = await fetch(`${API}${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    authorization: `bearer ${await this.props.authService.getAccessToken()}`,
                },
            });

            return await response.json();

        } catch (error) {

            console.error(error);
            this.setState({ error });

        }
    }

    async getResourceTypes() {
        var fetchedTypes = (await this.fetch('get', '/resource-types'));
        // var resourceTypes = fetchedTypes;
        try {
            fetchedTypes.forEach((fetchedType) => {
                if ('customFields' in fetchedType) {
                    if (fetchedType.customFields.length > 0) {
                        try {
                            var parsedFields = JSON.parse(fetchedType.customFields);
                            if ('fields' in parsedFields) {
                                if (parsedFields.fields.length > 0) {
                                    fetchedType.customFields = parsedFields;
                                } else {
                                    fetchedType.customFields = false;
                                }
                            }                   
                        } catch(e) {
                            fetchedType.customFields = false;
                        }
                    }
                }
            });
        } catch {
            
        }

        this.setState({ 
            loading: false, 
            resourceTypes: fetchedTypes || [] 
        });
    }



    async createResource() {


        // const resource = [
        //     {
        //         "id": 1,
        //         "resourceID": null,
        //         "title": "Comparison of Outcome Data for Shelter Dogs and Cats in the Czech Republic",
        //         "publicationStatus": null,
        //         "accessType": null,
        //         "dateCreated": null,
        //         "dateModified": null,
        //         "dateToPublish": null,
        //         "dateToUnpublish": null,
        //         "resourceType": "Journal Article",
        //         "license": "Creative Commons Attribution 4.0",
        //         "abstract": "Animal protection, which also comprises the subject of abandoned and stray animals, has become a pressing and widely discussed topic. The aim of this study was to compare dogs and cats from two shelters in a selected region of the Czech Republic, based on factors that affect the length of stay. The following factors were analyzed: outcome, sex, age, and purebred status. A total of 419 cats and 2580 dogs housed in the monitored shelters from 2013 to 2016 were included in the analysis. The results show that dogs (n = 1343; 52.1%) are returned to their owners significantly more often (p < 0.001) than cats (n = 10; 2.4%). Dogs stayed in the shelter significantly (p < 0.001) less time than cats regardless of the outcome (the median length of stay of dogs were 3 days, while that of cats was 51 days). Also the length of stay in the shelter until adoption is shorter in dogs than in cats (dogs: median 27 days; cats: median 53 days). Median length of stay tended to increase with the increasing age in both species. Monitored age categories of dogs and cats differed significantly (p < 0.05) in their median length of stay (LOS) until adoption. We found that purebred status does not affect the length of stay in the shelter until adoption, either in dogs or in cats. Overall, our results suggest that dogs are preferred over cats in the Czech Republic. Shelter operators should take into account this aspect affecting animal adoption. By targeted efforts and education of public, it is possible to mitigate the negative effects of favoring a certain category of animals over others.",
        //         "publicationYear": null,
        //         "publicationDate": null,
        //         "translatedTitle": null,
        //         "parentPublication": null,
        //         "volume": null,
        //         "issue": null,
        //         "pageRange": null,
        //         "isbn": null,
        //         "issn": null,
        //         "publisher": null,
        //         "publisherLoction": null,
        //         "doi": "10.3390/ani9090595",
        //         "url": null,
        //         "languagePrimary": "dfgdfgd",
        //         "languageAdditional": null,
        //         "createdAt": "2021-09-03T01:22:01.807Z",
        //         "updatedAt": "2021-09-03T01:49:49.533Z"
        //     }
        // ];


        const resourcePkg = [
            {
                "id": null,
                "resourceID": null,
                "type": this.state.resource.type,
                "metadata": this.state.resource.metadata,
            }
        ];

        var mintedResource = (await this.fetch('post', '/resources/create', resourcePkg));

        console.log(mintedResource);

        // this.props.history.goBack();
        // this.getResource();
    }



    retractForm = () => {
        const { formStage } = this.state;
        this.setState({formStage: formStage - 1 });
    }



    advanceForm = () => {
        const { formStage } = this.state;
        this.setState({formStage: formStage + 1 });
    }



    handleChange = input => e => {
        console.log("Attempting to handle resource draft form data..!");
        // this.setState({ [input]: e.target.value });
    }


    processMetadata = (target, input) => {
        console.log("Attempting to handle resource draft test data..!");
            
        switch(target) {

            case 'resource.type':

                var resourceModel = false;
                this.state.resourceTypes.map(resourceType => {
                    if (resourceType.id == input) {
                        resourceModel = resourceType.customFields.fields;
                    }
                });

                this.setState({ 
                    resource: { ...this.state.resource, type: input }, 
                    resourceModel: resourceModel 
                });

            break;


            case 'resource.metadata.partial':
            break;

            default:
            break;
        }
        
    }


    processEssentialMetadata = (mode, target, input) => {

        switch(mode) {

            case 'single':
                console.log(`Attempting to update resource (${target}: ${input})`);



                this.setState({ 
                    resource: { ...this.state.resource, metadata: {
                            ...this.state.resource.metadata, [target]: input 
                        }
                    } 
                });
            break;

            case 'full':
                console.log(`Attempting to update and commit resource metadata for new resource)`);
                console.log(target);
                console.log(input);

                var actualFields = [];

                target.map(fieldset => {
                    console.log(`now processing,,, ${fieldset.field}: ${fieldset.value}`);
                    if (fieldset.field in this.state.resource.metadata) {
                        actualFields.push(fieldset);
                    }
                });


                let resourceFrame = this.state.resource;

                actualFields.map(actualField => {
                    resourceFrame.metadata[actualField.field] = actualField.value;
                });

                this.setState({
                    resource: resourceFrame
                });

                this.createResource();


                // this.setState({ 
                //     resource: { ...this.state.resource, metadata: {
                //             ...this.state.resource.metadata, [target]: input 
                //         }
                //     } 
                // });


            break;

            default:
            break;
        }
    }



    render() {

        const { formStage }                     = this.state;
        const { loading }                       = this.state;
        const { resourceTypes }                 = this.state;
        const { resource }                      = this.state;
        const { resourceModel }                 = this.state;
        const values                            = { resource };

        switch(formStage) {

            case 0:
                return(
                    <Fragment>
                            <div className={`row`}>
                            <h3 className={`h5`}>Resource Adder > Form Stage {formStage}</h3>
                            </div>
                            <ResourceAddSelectType
                                advanceForm         = { this.advanceForm }
                                processMetadata     = { this.processMetadata }
                                resourceTypes       = { resourceTypes }
                                resource            = { resource }
                                loading             = { loading }
                                values              = { values }
                            />
                    </Fragment>
                )
            break;

            case 1:
                return(
                    <Fragment>
                            <div className={`row`}>
                            <h3 className={`h5`}>Resource Adder > {resource.type} > Form Stage {formStage}</h3>
                            </div>
                            <ResourceAddEssentialMetadata
                                advanceForm         = { this.advanceForm }
                                retractForm         = { this.retractForm }
                                handleChange        = { this.handleChange }
                                processMetadata     = { this.processEssentialMetadata }
                                resourceTypes       = { resourceTypes }
                                fieldset            = { resourceModel } 
                                resource            = { resource }
                                loading             = { loading }
                                values              = { values }
                            />
                    </Fragment>
                )
            break;

            case 2:
                return(

                    <Fragment>
                            <div className={`row`}>
                                <h3 className={`h5`}>Resource Adder > {resource.type} > Form Stage {formStage}</h3>
                            </div>
                            <ResourceAddPayload
                                advanceForm         = { this.advanceForm }
                                retractForm         = { this.retractForm }
                                processMetadata     = { this.processMetadata }
                                resource            = { resource }
                                values              = { values }
                            />
                    </Fragment>
                )
            break;

            case 3:
                return(
                    <Fragment>
                            <div className={`row`}>
                                <h3 className={`h5`}>Resource Adder > {resource.type} > Form Stage {formStage}</h3>
                            </div>
{/*                            <ResourceAddTagMetadata
                                advanceForm         = { this.advanceForm }
                                retractForm         = { this.retractForm }
                                processMetadata     = { this.processMetadata }
                                resource            = { resource }
                                values              = { values }
                            />*/}
                    </Fragment>
                )
            break;

            default:
                return(
                    <Fragment>
                            <div className={`row`}>
                                <h3>Resource Adder</h3>
                                <h4>No Stage Specified</h4>
                            </div>
                    </Fragment>
                )
            break;
        }

    }
 


}



export default compose(
    withStyles(styles),
)(ResourceAdder);