import React, { Fragment }              from 'react';
// import { Route, NoMatch }                        from 'react-router-dom';
import { Route }                        from 'react-router-dom';
import { CssBaseline, withStyles } from '@material-ui/core';


import AppHeader            from './components/AppHeader';
import AppFooter            from './components/AppFooter';
import Home                 from './pages/Home';
// import PostsManager         from './pages/PostsManager';
import ResourcesFrame       from './pages/ResourcesFrame';
import SearchFrame          from 'pages/SearchFrame';
import ResourcesDetailView  from './pages/ResourcesDetailView';
import ResourceEditorView   from './pages/resources/draft';

import Login                    from './components/Login';


import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/global.scss';

const styles = theme => ({
    main: {
        // padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            // padding: theme.spacing(2),
        },
    },
});

// const { authState, oktaAuth } = useOktaAuth();

const ConsumerApp = ({ classes }) => {

    // const { authState, oktaAuth } = useOktaAuth();
    // const [userInfo, setUserInfo] = useState(null);

    // useEffect(() => {
    //     if (!authState || !authState.isAuthenticated) {
    //         // When user isn't authenticated, forget any user info
    //         setUserInfo(null);
    //     } else {
    //         oktaAuth.getUser().then((info) => {
    //             setUserInfo(info);
    //         }).catch((err) => {
    //             console.error(err);
    //         });
    //     }
    //   }, [authState, oktaAuth]); // Update if authState changes


    // const login = async () => {
    //     oktaAuth.signInWithRedirect({ originalUri: '/' });
    // };




    // if (!authState) {
    //     return (
    //         <div>Loading...</div>
    //     );
    // } 

    return (

        <Fragment>
            <CssBaseline />
            <AppHeader />
            <main className={`main container-fluid px-0`}>
                <Route exact path="/"                   component={Home} />
                <Route exact path="/login"              component={Login} />
                <Route exact path="/resources"          component={ResourcesFrame} />
                <Route exact path="/search"             component={SearchFrame} />
                <Route exact path="/resources/:id"      component={ResourcesDetailView} />
                <Route path="/resources/draft">

                {/*<SecureRoute path="/resources/draft">*/}
                    <ResourceEditorView />
                {/*</SecureRoute>*/}
                </Route>
            </main>
            <AppFooter />
        </Fragment>

    );
};



export default withStyles(styles)(ConsumerApp);