import React, { useState, Fragment }                                from 'react';
import { Link, useHistory, useLocation, Redirect }                  from 'react-router-dom';
import LoginButton                                                  from './LoginButton';

import { useAuthState, useAuthDispatch, attemptLogin }                   from "components/Auth/AuthMgr";


import { connect, useDispatch }         from 'react-redux';
// import { registerUser }                 from '../js/actions';
import { loginUser }                    from '../js/actions';
import PrivateRoute                     from 'Routes/PrivateRoute';


import Toast                            from 'react-bootstrap/Toast';
import Container                        from 'react-bootstrap/Container';
import Row                              from 'react-bootstrap/Row';
import Col                              from 'react-bootstrap/Col';
import Button                           from 'react-bootstrap/Button';
import Form                             from 'react-bootstrap/Form';
import Axios                            from 'axios';

import ErrorSnackbar                    from 'components/ErrorSnackbar';

const siteLogo      = require('../img/hc_logo_color_v1_trans.png').default; 


// const mapDispatchToProps = (dispatch) => {
//     return {
//         loginUser: (credentials) => authDispatch(loginUser(credentials))
//     };
// };




const Login = () => {

    const API = process.env.REACT_APP_API_URL;// || 'https://habricentral.local';

    const { authUser, authStatus, authError }           = useAuthState();
    const authDispatch                                      = useAuthDispatch();
    // const [authUser]                                    = useState("");

    console.log('API: ' + API);
   

    // const { authState, oktaAuth } = useOktaAuth();
    // const [userInfo, setUserInfo] = useState(null);

    const history                                       = useHistory();
    const { state }                                     = useLocation();

    const [redirectToReferrer, setRedirectToReferrer]   = useState(false);

    const [registerUsername,    setRegisterUsername]    = useState("");
    const [registerPassword,    setRegisterPassword]    = useState("");
    // const [error,               setError]               = useState({
    //     type:       null,
    //     code:       null,
    //     message:    null
    // });
    const [errorMessage,        setErrorMessage]        = useState("");
    const [loginUsername,       setLoginUsername]       = useState("");
    const [loginPassword,       setLoginPassword]       = useState("");

    // const dispatch                                      = useDispatch();

    const register = () => {
        Axios({
            method: "POST",
            data: {
                username: registerUsername,
                password: registerPassword,
            },
            withCredentials: true,
            crossDomain: true,
            url: "http://habricentral.local:3001/users/register"
        }).then((res) => console.log(res));
    }



    const initiateLogin = async () => {

        let credentials = {
            username: loginUsername,
            password: loginPassword
        }

        attemptLogin(credentials, authDispatch);

    }

    // const login = async () => {

    //     const endpoint = '/api/users/login';

    //     console.log("Attempting to login...");  

    //     // try {
    //     //     const response = await fetch(`${API}${endpoint}`, {
    //     //         method,
    //     //         body: body && JSON.stringify(body),
    //     //         headers: {
    //     //             'content-type': 'application/json',
    //     //             accept: 'application/json',
    //     //             authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
    //     //         },
    //     //     });
    //     //     return await response.json();
    //     // } catch (error) {
    //     //     console.error(error);

    //     //     this.setState({ error });
    //     // }


    //     // this.props.dispatchLoginUser( {
    //     //     loginUsername,
    //     //     loginPassword
    //     // })





    //     //
    //     //
    //     //
    //     //
    //     //
    //     //

    //     console.log(`Attempting to log in via ${API}${endpoint}`);

    //     const loginSuccessful = await Axios({
    //         method: "POST",
    //         data: {
    //             username: loginUsername,
    //             password: loginPassword,
    //         },
    //         withCredentials: true,
    //         // credentials: 'same-origin',
    //         crossDomain: true,
    //         url: `${endpoint}`
    //     }).then((res) => {
    //         console.log('login attempted. response:');
    //         console.log(res);




    //         /*/
    //         //
    //         //      NEED TO SETUP USER DATA PROCESSING HERE (PROBABLY)
    //         //
    //         /*/ 




    //         // /* *
    //         //  * ░░░░░▒▒▓▓
    //         //  * ░░░░░▒▒▓▓    Payload schema
    //         //  * ░░░░░▒▒▓▓
    //         // */

    //         // const resPayload = {
    //         //     status:     null,       //  200
    //         //     statusText: '',         //  'OK'
    //         //     error:      {
    //         //         type:       null,
    //         //         code:       null,
    //         //         message:    null        //  'An error has occurred.'
    //         //     },
    //         //     data:       {},
    //         // }

    //         if (res.hasOwnProperty('data')) {
    //             if (res.data.hasOwnProperty('error')) {
    //                 // if (res.data.error) {
    //                 //     setError({
    //                 //         type: res.data.error.type || null,
    //                 //         code: res.data.error.code || null,
    //                 //         message: res.data.error.message | null
    //                 //     });
    //                 // }
    //                 if (res.data.error) {
    //                     setErrorMessage(res.data.error.message)
    //                 }
    //             }

    //             if (res.data.hasOwnProperty('data')) {
    //                 if (res.data.data.hasOwnProperty('uid')) {

    //                     if (res.data.data.uid) {

    //                         //  Login successful

    //                         const isLoggedIn = await doLogin(dispatch, res.data.data.uid);

    //                         if (isLoggedIn && loggedUser) {

    //                             /*/
    //                             //
    //                             //      NEED TO MAKE THIS HISTORY-BASED
    //                             //
    //                             /*/ 

    //                             return <Redirect to="/" />;
    //                         }

    //                         // setRedirectToReferrer(true);


    //                     }
    //                 }
    //             }
    //         }


    //         // localStorage.setItem('JWT', res.data.token);



    //         // authDispatch(loginUser())
    //         // .then(() => history.push('/'))
    //         // .catch(() => setError ({ error: true }));


    //     // }).then(() => { 
    //     //     if (redirectToReferrer === true) {
    //     //         return <Redirect to={state?.from || '/'} />
    //     //     } else { 
    //     //         history.push('/');
    //     //     }

    //         return loginSuccessful;

    //     });

    // }

    const getUser = () => {
        Axios({
            method: "GET",
            data: {
                username: loginUsername,
                password: loginPassword,
            },
            withCredentials: true,
            url: "https://habricentral.local:4000/users"
        }).then((res) => console.log(res));
    }




    if (authUser) {

        /*/
        //
        //      NEED TO MAKE THIS HISTORY-BASED
        //
        /*/ 

        // /api/session/authenticate

        console.log('authUser:');
        console.log(authUser);

        console.log('User logged in; redirecting to front...');
        
        // return <Redirect to="/" />;
    }



    return (

        <Fragment>

            <Container className="vh-66">
                <Row>
                    <Col sm={{span: 6, offset: 3}}>

                        <img className={`w-100 mb-5 p-5`} src={siteLogo} alt={`HABRI Central`} />

                        <Form className={`user-authenticator block-unit`}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" placeholder="username" onChange={(e) => setLoginUsername(e.target.value)}/>
                                <Form.Text className="text-muted">
                                    Enter your username.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="password" onChange={(e) => setLoginPassword(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3 d-flex gap-2" controlId="formSubmission">
                                <Button className={`flex-fill rounded-pill`} variant="primary" size="lg" type="button" onClick={initiateLogin}>
                                    Submit
                                </Button>
                        {/*        <Button variant="primary" type="button" onClick={login}>
                                    Submit
                                </Button>*/}
                            </Form.Group>

                        </Form>

                    </Col>
                </Row>
            </Container>

            {errorMessage && (
                <ErrorSnackbar
                    onClose={ () => setErrorMessage(null) }
                    message={ errorMessage }
                />
            )}

        </Fragment>

    );
};

export default Login;
