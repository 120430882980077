import { 
    ADD_ARTICLE,
    AUTHENTICATED,
    NOT_AUTHENTICATED 
} from '../constants/action-types';

import Axios                            from 'axios';


const API = process.env.REACT_APP_API || 'http://localhost:3001';



const setToken = (token) => {
    console.log('setToken');
    console.log(token);
    localStorage.setItem('JWT', token);
    localStorage.setItem('lastLoginTime', new Date(Date.now()).getTime());
}



export const getToken = () => {
    const now = new Date(Date.now()).getTime();
    const timeAllowed = 1000 * 60 * 60;
    const timeSinceLastLogin = now - localStorage.getItem('lastLoginTime');
    if (timeSinceLastLogin < timeAllowed) {
        return localStorage.getItem('JWT');
    }
};



const deleteToken = () => {
    localStorage.removeItem('JWT');
    localStorage.removeItem('lastLoginTime');
}



export const registerUser = (credentials) => {

    const endpoint = '/users/register';

    return (dispatch) => {
        return fetch(API + endpoint, {
            method: 'POST',
            headers: {
                Accepts: 'application/json',
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({ user: credentials })
        }).then((res) => {
            if (res.ok) {
                setToken(res.headers.get("Authorization"));
                return res
                    .json()
                    .then((userJSON) => {
                        dispatch({ 
                            type: AUTHENTICATED, 
                            payload: userJSON
                        })
                    })
            } else {
                return res
                    .json()
                    .then((errors) => {
                        dispatch({
                            type: NOT_AUTHENTICATED
                        });

                        return Promise.reject(errors);
                    });
            }
        });
    };
};



export const loginUser = (payload) => dispatch => {

    const endpoint = '/users/login';

    console.log('loginUser...');
    console.log(payload);

    // return function(dispatch) {

        console.log('dispatching...');



            return Axios({
                method: "POST",
                data: {
                    username: payload.username,
                    password: payload.password,
                },
                withCredentials: true,
                url: payload.endpoint
            }).then((res) => {


                    return (dispatch) => {
                        dispatch({
                            type: NOT_AUTHENTICATED
                        });
                    }

                    if (res.ok) {
                        console.log(res); 
                        setToken(res.headers.get('Authorization'));
                        return res
                            .json()
                            .then((userJson) => {
                                return (dispatch) => {

                                    dispatch({
                                        type: AUTHENTICATED,
                                        payload: userJson
                                    });
                                }
                            });

                    } else {
                        console.log(res);
                        return res.json().then((errors) => {
                            return (dispatch) => {

                                dispatch({
                                    type: NOT_AUTHENTICATED
                                });
                            }
                            return Promise.reject(errors);
                        });
                    }
                
               
            });



            // console.log('login attempted. response:');
            // //console.log(res)
            // localStorage.setItem('JWT', res.data.token);


          //   return fetch(API + endpoint, {
          //   method: "POST",
          //   headers: {
          //     Accept: "application/json",
          //     "Content-Type": "application/json",
          //   },
          //   body: JSON.stringify({ user: credentials }),
          // }).then((res) => {
          //   console.log('checking auth action response...');
          //   console.log(res);
          //   if (res.ok) {
          //       console.log('authorization success?');
          //     setToken(res.headers.get("Authorization"));
          //     return res
          //       .json()
          //       .then((userJson) =>
          //         dispatch({ type: AUTHENTICATED, payload: userJson })
          //       );
          //   } else {
          //     return res.json().then((errors) => {
          //       dispatch({ type: NOT_AUTHENTICATED });
          //       return Promise.reject(errors);
          //     });
          //   }
          // });
       // };
    // }
};
  


export const logoutUser = () => {

    const endpoint = '/users/logout';

    return (dispatch) => {
        return fetch(API + endpoint, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getToken(),
            },
        }).then((res) => {
            deleteToken()
            if (res.ok) {
                return res.json()
                .then(() => dispatch({ type: NOT_AUTHENTICATED }))
            } else {
                return res.json().then((errors) => {
                    dispatch({ type: NOT_AUTHENTICATED })
                    return Promise.reject(errors)
                })
            }
        })
    }
}

export const checkAuth = () => {
    
    const endpoint = '/users/current';

    return (dispatch) => {
        return fetch(API + endpoint, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getToken()
        }
      }).then((res) => {
        if (res.ok) {
          return res
          .json()
          .then(user => {         
          user.data ? dispatch({type: AUTHENTICATED, payload: user}) : dispatch({type: NOT_AUTHENTICATED})})
        } else {
          return Promise.reject(dispatch({type: NOT_AUTHENTICATED})) 
        }
     });
   };
};


export function addArticle(payload) {
    return { type: ADD_ARTICLE, payload };
}