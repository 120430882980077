const SearchStringParser = ( existingFilters ) => {

    if (typeof(existingFilters) == 'string') {
        var searchString = existingFilters;
    } else if (typeof(existingFilters) == 'object') {
        var searchString = existingFilters.rawSearchString || '';
    }
    

    let filters = {
        "rawSearchString"                   :   searchString,
        "limit"                             :   existingFilters.limit,
        "start"                             :   existingFilters.start,
        "type"                              :   existingFilters.type,
        "filetype"                          :   existingFilters.filetype,
        "authors"                           :   existingFilters.authors,
        "publishedafter"                    :   existingFilters.publishedafter,
        "publishedbefore"                   :   existingFilters.publishedbefore,
        "publisher"                         :   existingFilters.publisher,
        "submittedafter"                    :   existingFilters.submittedafter,
        "submittedbefore"                   :   existingFilters.submittedbefore,
        "tags"                              :   existingFilters.tags,
        "sortby"                            :   existingFilters.sortby,
        "order"                             :   existingFilters.order,
        "explodedCoreSearchString"          :   existingFilters.explodedCoreSearchString,               //      [dog,   "therapy animal",       benefits]
        // "encodedExplodedCoreSearchString"   :   existingFilters.encodedExplodedCoreSearchString,        //      [dog,   %22therapy+animal%22,   benefits]
        "explodedFullSearchString"          :   [], //existingFilters.explodedFullSearchString,               //      [dog,   "therapy animal",       type:journalarticles,       benefits]
        // "encodedExplodedFullSearchString"   :   existingFilters.encodedExplodedFullSearchString,        //      [dog,   %22therapy+animal%22,   type%3Ajournalarticles,     benefits]
        "explodedFilterString"              :   existingFilters.explodedFilterString,                   //      [type:journalarticles]
        // "encodedExplodedFilterString"       :   existingFilters.encodedExplodedFilterString,            //      [type%3Ajournalarticles]
        // "encodedCoreSearchString"           :   existingFilters.encodedCoreSearchString,                //      dog+%22therapy+animal%22+benefits
        "searchQueryString"                 :   existingFilters.searchQueryString                       //      ?q=dog+%22therapy+animal%22+benefits&type=journalarticles
    }

    let cleanFilters = {
        "rawSearchString"                   :   '',         //      dog "therapy animal" type:journalarticles benefits
        "limit"                             :   [],
        "start"                             :   [],
        "type"                              :   [],
        "filetype"                          :   [],
        "authors"                           :   [],
        "publishedafter"                    :   [],
        "publishedbefore"                   :   [],
        "publisher"                         :   [],
        "submittedafter"                    :   [],
        "submittedbefore"                   :   [],
        "tags"                              :   [],
        "sortby"                            :   [],
        "order"                             :   [],
        "explodedCoreSearchString"          :   [],         //      [dog,   "therapy animal",       benefits]
        // "encodedExplodedCoreSearchString"   :   [],         //      [dog,   %22therapy+animal%22,   benefits]
        "explodedFullSearchString"          :   [],         //      [dog,   "therapy animal",       type:journalarticles,       benefits]
        // "encodedExplodedFullSearchString"   :   [],         //      [dog,   %22therapy+animal%22,   type%3Ajournalarticles,     benefits]
        "explodedFilterString"              :   [],         //      [type:journalarticles]
        // "encodedExplodedFilterString"       :   [],         //      [type%3Ajournalarticles]
        // "encodedCoreSearchString"           :   '',         //      dog+%22therapy+animal%22+benefits
        "searchQueryString"                 :   '',         //      ?q=dog+%22therapy+animal%22+benefits&type=journalarticles
    }





    /*/
    //
    //      Dedupe exploded filter string
    //
    /*/ 

    var dedupedFilterSet = [];

    for (var i = 0; i < filters.explodedFilterString.length; i++) {

        let alreadyExists = false;

        for (var j = 0; j < dedupedFilterSet.length; j++) {

            if (dedupedFilterSet[j].name === filters.explodedFilterString[i].name && dedupedFilterSet[j].value === filters.explodedFilterString[i].value) {

                // filter already exists
                alreadyExists = true;

            } else {

                // filter may not already exist

            }
        }        

        if (!alreadyExists) {

            dedupedFilterSet.push(filters.explodedFilterString[i]);

        }
    }

    filters.explodedFilterString = dedupedFilterSet;





    /*/
    //
    //      Extract exploded search string
    //
    /*/ 



    var literals        = [];
    var transLiterals   = [];
    var output          = [];

    

    let safety = 0;

    literals = searchString.match(/(\"[^\"]*\")/g) || [];

    if (literals && literals.length > 0) {

        literals.forEach((singleLiteral) => {
            let transLiteral = singleLiteral.replace(/^\"/g, '|||"').replace(/\"$/g, '"|||');
            if (transLiteral && transLiteral.length > 0) {
                transLiterals.push(transLiteral);
            }
        })
    }



    /*/
    //
    //      Break apart original search string by literal demarcations
    //
    /*/ 

    let splitString = searchString.split('"') || [];



    /*/
    //
    //      Remove any contentless array elements
    //
    /*/ 

    for (var i = splitString.length; i >= 0 ; i--) {
        if (splitString[i] == '') {
            splitString.splice(i, 1);
        }
    }


    /*/
    //
    //      Replace literals with transformed versions
    //
    /*/ 

    var mergedSet = [];

    if (splitString && Array.isArray(splitString)) {


        for (var i = 0; i < splitString.length; i++) {

            var changed = false;

            for (var j = 0; j < literals.length; j++) {

                if (`"${splitString[i]}"` == literals[j]) {
                    mergedSet.push(literals[j].trim());
                    changed = true;
                }
            }

            if (!changed) {

                let subString = splitString[i].trim().split(' ');

                for (var k = 0; k < subString.length; k++) {
                    mergedSet.push(subString[k]);
                }
            }
        }
    }






    let mergedString = '';

    if (mergedSet && mergedSet.length > 0) {

        mergedSet.forEach( singleTermUnit => {

            mergedString += singleTermUnit + ' ';

        });

    }




    // let cleanedString = mergedSet.split('|||');


    // splitString = searchString.split('|||');


    // console.log(splitString);
    console.log('BEHOLD:');
    console.log(mergedSet);
    console.log(searchString);
    console.log(mergedString);
    // console.log(cleanedString);

    // an elaborate "and long" search string with "multiple parts" and other things

    //return mergedString;

    cleanFilters.explodedFullSearchString = mergedSet || [];
    // cleanFiltersmergedString || [];









    // let literalSet = [];

    // let regi = 1;

    // for (const match of searchString.matchAll(/(?:\"([^\"]*)\")/g)) {
      
    //   // console.log(`Hello ${match[1]} ${match[2]}`);

    //   literalSet.push(match[regi]);

    // }



    /*/
    //
    //      Extract (valid) filters from search string
    //
    /*/ 

    let queryTerms = [];
    let strippedQueryTerms = [];

    if (cleanFilters.explodedFullSearchString.length > 0) {

        cleanFilters.explodedFullSearchString.forEach( explodedSubstring => {

            if (explodedSubstring.indexOf(':') > -1) {

                for (const match of searchString.matchAll(/((\w+)\:(\w+))/g)) {

                    console.log(match);

                    let queryTerm = {
                        "full"  : match[1],
                        "name"  : match[2],
                        "value" : match[3]
                    }

                    cleanFilters.explodedFilterString.push(queryTerm);
                    queryTerms.push(queryTerm);
                }

            } else {

                // cleanFilters.explodedFullSearchString.push(explodedSubstring);
                // strippedQueryTerms.push(explodedSubstring);

            }

        });






        queryTerms.forEach( queryTerm => {

            Object.entries(filters).forEach( ([key, value]) => {
                
                console.log(`filter ${key}: ${value}`);

                let alreadyExists = false;

                if (queryTerm.name === key) {

                    for (var i = 0; i < filters[key].length; i++) {

                        if (filters[key][i] === queryTerm.value) {

                            alreadyExists = true

                        } else {


                        }

                    }

                    if (!alreadyExists) {
                        filters[key].push(queryTerm.value);
                    }
                }

            });

        });

    }

    





    // let basicTermString = searchString.replace(/(?:\"([^\"]*)\")/g, "").replace(/\w+\:\w+/g, "").replace(/\s+/g, ' ').trim();
    // let basicTermSet = basicTermString.split(/(\s)/);

    // console.log(basicTermString);
    // console.log(basicTermSet);

    // let fullTermSet = [];

    // //  everything except quoted: ([^\"]*)(?:\"[^\"]*\")([^\"])?


    


    // /*/
    // //
    // //      Remove double quotes
    // //
    // /*/ 

    // // let basicTermSet = searchString.replace(/\"/g, "").split(/(\s)/).map(function (value) {
    // //     return value.trim();
    // // });


    // if (literalSet.length > 1) {
    //     literalSet.forEach( (aLiteral) => {
    //         fullTermSet.push(aLiteral.replace(/"/g, ""));
    //     })
    // } else if (literalSet.length > 0) {
    //     fullTermSet.push(literalSet[0].replace(/"/g, ""));
    // }





    // /*/
    // //
    // //      Remove extraneous whitespace
    // //
    // /*/ 

    // if (basicTermSet.length > 0) {
    //     basicTermSet.forEach(subString => {
    //         let transString = subString.trim();

    //         if (transString) {
    //             fullTermSet.push(transString);
    //         }
    //     })
    // }





    // /*/
    // //
    // //      Remove the full original string
    // //
    // /*/ 

    // console.log('---------------------');
    // console.log(fullTermSet);


    // fullTermSet.slice(0, -1);

    // console.log(fullTermSet);

    // console.log('---------------------');


    // let subStrings = searchString.split(/(\s+)/);

    // subStrings.forEach(subString => {
    //     fullTermSet.push()
    // })





    
    

    // console.log("Matches:");
    // console.log("Basic term set: ");
    // console.log(basicTermSet);
    // console.log("Literals: ");
    // console.log(literalSet);
    // console.log("Filters: ");
    // console.log(filters);
    // console.log("Full Term Set: ");
    // console.log(fullTermSet);

    // // .replace(/"/g, "")

    // let encodedExplodedFullSearchString = [];
    // let encodedExplodedCoreSearchString = [];


    // if (cleanFilters.explodedFullSearchString.length > 0) {

    //     cleanFilters.explodedFullSearchString.forEach(aString => {
    //         encodedExplodedFullSearchString.push(encodeURIComponent(aString));
    //     }); 

    // }




    /*/
    //
    //      Create query string structure
    //
    /*/ 

    let queryComponents = [
        { 
            "field"     : "q",
            "localName" : "explodedFullSearchString",
            "values"    : cleanFilters.explodedFullSearchString
        },
        { 
            "field"     : "type",
            "localName" : "type",
            "values"    : []
        },
        { 
            "field"     : "tag",
            "localName" : "tags",
            "values"    : []
        },
        { 
            "field"     : "author",
            "localName" : "authors",
            "values"    : []
        },
        { 
            "field"     : "filetype",
            "localName" : "filetype",
            "values"    : []
        },
        { 
            "field"     : "publishedbefore",
            "localName" : "publishedbefore",
            "values"    : []
        },
        { 
            "field"     : "publishedafter",
            "localName" : "publishedafter",
            "values"    : []
        },
        { 
            "field"     : "submittedbefore",
            "localName" : "submittedbefore",
            "values"    : []
        },
        { 
            "field"     : "submittedafter",
            "localName" : "submittedafter",
            "values"    : []
        },
        { 
            "field"     : "publisher",
            "localName" : "publisher",
            "values"    : []
        },
        { 
            "field"     : "limit",
            "localName" : "limit",
            "values"    : []
        },
        { 
            "field"     : "start",
            "localName" : "start",
            "values"    : []
        },
        { 
            "field"     : "sortby",
            "localName" : "sortby",
            "values"    : []
        },
        { 
            "field"     : "order",
            "localName" : "order",
            "values"    : []
        },
    ];







    let ignoredFilterProps = [
        'rawSearchString',
        'explodedFullSearchString',
        'searchQueryString'
    ];

    let knownSingleValueFilters = [
        'start',
        'limit',
        'publishedafter',
        'publishedbefore',
        'submittedafter',
        'submittedbefore',
        'sortby',
        'order'
    ];




    /*/
    //
    //      Add/merge new/existing filters
    //
    /*/ 

    Object.entries(filters).forEach( ([key, value]) => {
        
        console.log(`${key}: ${value}`);

        if (ignoredFilterProps.indexOf(key) < 0) {

            if (value != undefined) {

                if (value.length > 0 && value.indexOf(null) < 0) {

                    if (knownSingleValueFilters.indexOf(key) > -1) {


                        /*/
                        //
                        //      These fields accept only one argument
                        //
                        /*/ 

                        cleanFilters[key].push(value[0]);


                        /*/
                        //
                        //      Add to temporary queryComponents set
                        //
                        /*/ 

                        for (var i = 0; i < queryComponents.length; i++) {

                            if (key == queryComponents[i].localName) {

                                if (!(key == 'start' && value[0] == 0)) {
                                    queryComponents[i]['values'].push(value[0]);
                                }
                                
                            }

                        }

                        // fullQueryString += `&${encodeURIComponent(key)}=${encodeURIComponent(value[0])}`;

                    } else {

                        /*/
                        //
                        //      These fields can accept multiple arguments
                        //
                        /*/ 

                        if (Array.isArray(value) && value.length > 0 ) {
                            
                            value.forEach(singleValue => {
                                cleanFilters[key].push(singleValue);
                                // fullQueryString += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

                                for (var i = 0; i < queryComponents.length; i++) {

                                    if (key == queryComponents[i].localName) {

                                        // for (var k = 0; k < value.length; k++) {
                                            queryComponents[i]['values'].push(singleValue);
                                        // }
                                    }
                                }
                            })
                        }
                    }
                }
            }
        }

    });



    /*/
    //
    //      Create full encoded query string
    //
    /*/ 

    let searchQueryString = '';
    let querySeparator = '?';

    // if (cleanFilters.explodedSearchString.length > 0) {

    // }

    if (queryComponents.length > 0) {

        queryComponents.forEach( queryComponent => { 

            if (queryComponent.values.length > 0) {


                /*/
                //
                //      Format the search query argument
                //
                /*/ 

                if (queryComponent.field == 'q') {

                    searchQueryString += `${querySeparator}${queryComponent.field}=`;
                    querySeparator = '&'; 

                    for (var i = 0; i < queryComponent.values.length; i++) {

                        let singleValue = queryComponent.values[i];


                        /*/
                        //
                        //      Check for filter strings and omit
                        //
                        /*/ 

                        let isFilterString = false;

                        if (singleValue.indexOf(':') > -1) {

                            for (const match of singleValue.matchAll(/((\w+)\:(\w+))/g)) {

                                // console.log(match);

                                let queryTerm = {
                                    "full"  : match[1],
                                    "name"  : match[2],
                                    "value" : match[3]
                                }


                                for (var j = 0; j < queryComponents.length; j++) {
                                    if (queryTerm.name === queryComponents[j].field || queryTerm.name === queryComponents[j].localName) {
                                        isFilterString = true;
                                    }
                                }


                            }

                        }


                        if (!isFilterString) {

                            let stringValue = singleValue.toString().replace(/\s+/g, '+');
                            let cleanValue = encodeURI(stringValue.trim()).replace('%22', '"').replace("'", '%27');

                            searchQueryString += `${cleanValue}+`;
                            querySeparator = '&';
                        }

                    }

                    searchQueryString = searchQueryString.replace(/\+$/, '');



                /*/
                //
                //      Format all other query arguments
                //
                /*/ 

                } else {

                    for (var i = 0; i < queryComponent.values.length; i++) {

                        let singleValue = queryComponent.values[i];
                        let stringValue = singleValue.toString().replace(/\s+/g, '+');
                        let cleanValue = encodeURI(stringValue.trim()).replace('%22', '"').replace("'", '%27');

                        searchQueryString += `${querySeparator}${queryComponent.field}=${cleanValue}`;
                        querySeparator = '&';

                    }
                }
            }

        });
    }


    cleanFilters.searchQueryString = searchQueryString;

    if (filters.rawSearchString.trim().length == 0) {

        cleanFilters.rawSearchString = '';

        for (var i = 0; i < cleanFilters.explodedFullSearchString.length; i++) {

            cleanFilters.rawSearchString += `${cleanFilters.explodedFullSearchString[i]} `;

        }

    } else {

        cleanFilters.rawSearchString = filters.rawSearchString;

    }

    cleanFilters.rawSearchString = cleanFilters.rawSearchString.trim();






    /*/
    //
    //      Generate synthetic, "canonical" query string
    //
    /*/ 

    // cleanFilters.explodedCoreSearchString.forEach(searchUnit)

    // cleanFilters.searchQueryString = fullQueryString.replace(/\s+/g, ' ').trim();



    console.log('clean search filters:')
    console.log(cleanFilters);

    return cleanFilters;

}



export default (SearchStringParser);