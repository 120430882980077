import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

class SearchFilterSorting extends React.Component {

    state = {
        sortby:         'date_pub',
        order:          'desc',
        sortModes:      [
            {
                label:      'date published',
                type:       'date_pub',
                order:      'desc',
                mode:       'numeric',
                active:     'false'
            },
            {
                label:      'date added',
                type:       'date_add',
                order:      'desc',
                mode:       'numeric',
                active:     'false'
            },
            {
                label:      'title',
                type:       'title',
                order:      'asc',
                mode:       'alpha',
                active:     'false'
            },
            {
                label:      'Altmetric score',
                type:       'alt_score',
                order:      'desc',
                mode:       'numeric',
                active:     'false'
            },
            {
                label:      'mainstream popularity',
                type:       'alt_cohort_pub',
                order:      'desc',
                mode:       'numeric',
                active:     'false'
            },
            {
                label:      'practitioner popularity',
                type:       'alt_cohort_doc',
                order:      'desc',
                mode:       'numeric',
                active:     'false'
            },
            {
                label:      'researcher popularity',
                type:       'alt_cohort_sci',
                order:      'desc',
                mode:       'numeric',
                active:     'false'
            },
            {
                label:      'journalist popularity',
                type:       'alt_cohort_com',
                order:      'desc',
                mode:       'numeric',
                active:     'false'
            },
        ]
    };



    componentDidMount() {

        let sortModes = this.props.sortModes || this.state.sortModes;
        
        // let activeResourceTypes = this.props.activeResourceTypes || this.state.activeResourceTypes;

        
    };



    componentWillReceiveProps(nextProps) {

        let sortModes = nextProps.sortModes || this.state.sortModes;
        let sortby    = this.state.sortby;
        let order     = this.state.order;

        for (var i = 0; i < sortModes.length; i++) {
            if (sortModes[i].type == nextProps.activeSortMode[0]) {
                sortModes[i].active = 'true';

                if ('activeorder' in nextProps && Array.isArray(nextProps.activeorder) && nextProps.activeorder.length > 0) {
                    sortModes[i].order = nextProps.activeorder[0];
                }
            }
        }

        

        if (sortModes.length > 0) {
            sortModes.forEach( sortMode => {

                if (sortMode.active == 'true') {

                    sortby = sortMode.type;
                    order = sortMode.order;

                }

            });
        }

        this.setState({
            sortModes:      sortModes,
            sortby:         sortby,
            order:          order
        });
    }



    onChange = (value) => {



    }



    processChange = (value) => {

        console.log(value);

    }



    removeActiveType = (droppedType) => {
        
        // if (this.state.activeResourceTypes.length > 0) {

        //     let newActiveAliases = [];
        //     let newActiveTypes = [];

        //     this.state.activeResourceTypes.forEach((sortby) => {
        //         if (sortby.alias !== droppedType) {
        //             newActiveAliases.push(sortby.alias);
        //         }
        //     }, this);

        //     newActiveAliases.forEach((alias) => {
        //         this.state.resourceTypes.forEach((resourceType) => {
        //             if (resourceType.alias === alias) {
        //                 newActiveTypes.push(resourceType.alias);
        //             }
        //         })
        //     })
            
        //     // this.setState({
        //     //     activeResourceTypes: newActiveTypes
        //     // });

        //     this.props.onChange(newActiveTypes);

        // }
    }



    addActiveType = (addedType) => {
        
        // if (this.state.resourceTypes.length > 0) {

        //     let newActiveAliases = [];
        //     let newActiveTypes = [];
        //     let alreadyAdded = false;

        //     if (this.state.activeResourceTypes.length > 0) {

        //         this.state.activeResourceTypes.forEach((sortby) => {
        //             if (sortby.alias === addedType) {
        //                 alreadyAdded = true;
        //             } else {
                        
        //             }

        //             newActiveAliases.push(sortby.alias);

        //         }, this);

        //     }

        //     if (!alreadyAdded) {
        //      newActiveAliases.push(addedType);
        //         this.state.resourceTypes.forEach((resourceType) => {
        //             newActiveAliases.forEach((activeAlias) => {
        //                 if (resourceType.alias === activeAlias) {
        //                     newActiveTypes.push(resourceType.alias);
        //                 }
        //             })
        //         })
        //     }

        //     // this.setState({
        //     //     activeResourceTypes: newActiveTypes
        //     // });

        //     this.props.onChange(newActiveTypes);
        // }

    }


    buttonHandler = (event) => {

        event.preventDefault();
        event.stopPropagation();
        
        // this.setState ({
        //     value: event.target.value
        // });

        let sortby          = this.state.sortby;
        let order     = this.state.order;

        const onChange          = this.props.onChange || this.processChange;

        let existingModes       = this.state.sortModes;

        if (event.target.attributes.active) {

            /*/
            //
            //      Deactivate
            //
            /*/ 


            existingModes.forEach(existingMode => {
                if ('type' in existingMode && existingMode.type == event.target.value) {
                    existingMode.active = 'false'
                }
            });


            // event.target.removeAttribute('active');
            // removeActiveType(event.target.value);
        } else {
            
            /*/
            //
            //      Activate
            //
            /*/ 

            existingModes.forEach(existingMode => {
                if ('type' in existingMode && existingMode.type == event.target.value) {
                    existingMode.active = 'true'
                } else {
                    existingMode.active = 'false'
                }
            });




            // let alreadyExists = false;

            // sortbys.forEach(sortby => {
            //     if (sortby == event.target.value) {
            //         alreadyExists = true;
            //     } else {
            //         updatedSet.push(sortby);
            //     }
            // });

            // if (!alreadyExists) {
            //     updatedSet.push(event.target.value);
            // }

            // event.target.setAttribute('active', true);
            // addActiveType(event.target.value);
        }

        onChange(existingModes);

    }



    sortingFilter = (sortModes, sortby, order, onChange) => {
        var filterModesCount = sortModes.length;
        var outputBuffer = [];
        var typeState = {
            active : ""
        };

        if (filterModesCount > 0) {

            sortModes.map((sortMode, i) => {
                // outputBuffer.push(<ToggleButton className={`filter-resource-type`} key={resourceType.id} value={resourceType.alias}>{resourceType.type}</ToggleButton>)
                typeState.active = '';

      
                if (sortby === sortMode.type) {
                    typeState.active = 'active';
                }
    

                if (typeState.active) {
                    outputBuffer.push(<Button className={`filter-resource-type`} onClick={onChange} variant="outline-primary" size="sm" active key={sortMode.type} value={sortMode.type}>{sortMode.label}</Button>)
                } else {
                    outputBuffer.push(<Button className={`filter-resource-type`} onClick={onChange} variant="outline-primary" size="sm" key={sortMode.type} value={sortMode.type}>{sortMode.label}</Button>)

                }
            });

        }
        return (outputBuffer);
    }



    render() {

        

        const onChange = this.props.onChange || this.processChange;

        const addActiveType = this.addActiveType;
        const removeActiveType = this.removeActiveType;

        const changeHandler = (event) => {

            event.preventDefault();
            event.stopPropagation();
            
            // this.setState ({
            //     value: event.target.value
            // });

            onChange(event.target.value);
        }

        const buttonHandler = this.buttonHandler;

        // const buttonHandler         = this.buttonHandler;

        const activeResourceTypes   = this.state.activeResourceTypes;
        const sortingFilters        = this.sortingFilter(this.state.sortModes, this.state.sortby, this.state.order, buttonHandler);



        return (

                    <Fragment>
                        <Form className={`filter-sorting`} onChange={changeHandler}>
                            <Form.Group controlId="filterSorting">
                                <Form.Label>Sort By</Form.Label>
                                {/*<ToggleButtonGroup className={`filter-resource-types mb-2`} type="checkbox" defaultValue={[1, 3]}>*/}
                                <div className={`filter-resource-types mb-2`}>

                                    {sortingFilters}

                                </div>
                                {/*</ToggleButtonGroup>*/}
                            </Form.Group>
                        </Form>
                    </Fragment>

        );

    }
}

export default SearchFilterSorting;