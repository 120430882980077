import { 
    AUTHENTICATED,
    NOT_AUTHENTICATED 
}                           from '../constants/action-types';
// import { combineReducers }  from 'redux';

// const initialState = {
//     articles: []
// };

// function rootReducer(state = initialState, action) {

//     // if (action.type === ADD_ARTICLE) {
//     //     return Object.assign({}, state, {
//     //         articles: state.articles.concat(action.payload)
//     //     })
//     // }


//     return state;
// };

const initialState = {
    authChecked: false,
    loggedIn: false,
    currentUser: {}
};
  
export default function authorization(state = initialState, action) {

    switch (action.type) {

        case AUTHENTICATED:
            return {
                authChecked: true,
                loggedIn: true,
                currentUser: action.payload,
            };
        break;
        
        case NOT_AUTHENTICATED:
            return {
                authChecked: true,
                loggedIn: false,
                currentUser: {}
            };
        break;
      
        default:
            return state;
        break;

    }

}