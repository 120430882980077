import React, { Component, Fragment } from 'react';
// import { withOktaAuth } from '@okta/okta-react';
import {
    withStyles,
    Card,
    CardContent,
    CardActions,
    Modal,
    Button,
    TextField,
} from '@material-ui/core';
import { compose } from 'recompose';
// import { withRouter, Route, Redirect, Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { find, orderBy } from 'lodash';


// const API = process.env.REACT_APP_API || 'http://localhost:3001';



// const styles = theme => ({
//     modal: {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//     },
//     modalCard: {
//         width: '90%',
//         maxWidth: 500,
//     },
//     modalCardContent: {
//         display: 'flex',
//         flexDirection: 'column',
//     },
//     marginTop: {
//         marginTop: theme.spacing(2),
//     },
// });


const ResourceAddEssentialMetadata = ({ advanceForm, retractForm, handleChange, processMetadata, resourceTypes, fieldset, resource, values, loading }) => {

    const Advance = e => {
        e.preventDefault();
        // advanceForm();

        var metaValues = [];

        Array.prototype.forEach.call(e.target.elements, (element) => {
            if (['INPUT', 'TEXTAREA'].includes(element.tagName)) {
                if (element.value) {
                    console.log(`${element.id}: ${element.value}`);
                    var metaField = {
                        'field': element.id.replace(/^input-meta-/, ''),
                        'value': element.value
                    };
                    metaValues.push(metaField);
                }
            }
        });

        processMetadata('full', metaValues);
    }



    const Retract = e => {
        e.preventDefault();
        retractForm();
    }



    const RouteChange = e => {
        console.log(e);
        var metaField = e.target.id.replace(/^input-meta-/, '');
        processMetadata('single', metaField, e.target.value);
    }




    // const resourceModel = resourceTypes => {
    //     resourceTypes.map(resourceType => {
    //         if (resourceType.id == resource.type) {
    //             return resourceModel = resourceType;
    //         }
    //     });
    // }

    return (

        <Fragment>
            <form onSubmit={Advance}>
                <div className={`row`}>

                    <div className={`col-10`}>

                        <div className={`form-super-group`}>
                            <div className={`form-super-group-title-bar`}>
                                <h3 className={`form-super-group-title`}>About</h3>
                            </div>

                            <div className={`form-floating mb-3`}>
                                <input type={`text`} className={`form-control`} id={`input-meta-title`} aria-describedby={`title` + `Help`} placeholder={`Title`} onBlur={RouteChange}/>
                                <label htmlFor={`input-meta-title`}>Title</label>
                                <small id={`title` + `Help`} className={`form-text text-muted`}>{`The title of this resource.`}</small>
                            </div>

                            <div className={`form-floating mb-3`}>
                                <textarea type={`textarea`} className={`form-control`} id={`input-meta-description`} aria-describedby={`description` + `Help`} onBlur={RouteChange}/>
                                <label htmlFor={`input-meta-description`}>Abstract/Description</label>
                                <small id={`description` + `Help`} className={`form-text text-muted`}>{`A summary of this resource.`}</small>
                            </div>

                        </div>

                    </div>

                </div>



                <div className={`row`}>
                    <div className={`col-10`}>

                        <div className={`form-super-group`}>
                            <div className={`form-super-group-title-bar`}>
                                <h3 className={`form-super-group-title`}>Details</h3>
                            </div>




                            {fieldset.map(fieldset => (

                                <div className={`form-floating mb-3`}>
                                    <input type={fieldset.type} className={`form-control`} id={`input-meta-` + fieldset.name} aria-describedby={fieldset.name + `Help`} placeholder={fieldset.label} onBlur={RouteChange}/>
                                    <label htmlFor={`input-meta-`+ fieldset.name}>{fieldset.label}</label>
                                    <small id={fieldset.name + `Help`} className={`form-text text-muted`}>{fieldset.helptext}</small>
                                </div>

                            ))}
                              
  
                            

                            <div className={`col-3 p-5`}>
                                <button className={`btn btn-sm btn-primary w-100`}>Next</button>
                            </div>

                        </div>
                    </div>
                </div>

            </form>
        </Fragment>
    )

}



export default compose()(ResourceAddEssentialMetadata);