import Axios from 'axios';

let API = process.env.REACT_APP_API_URL || 'https://habricentral.local:4400';





export async function AuthenticateUser(username = false, password = false) {

	const endpoint = '/sessions/authenticate';

	console.log("Attempting to login...");
	console.log(`Preparing request for: ` + API + endpoint);

	// try {
	//     const response = await fetch(`${API}${endpoint}`, {
	//         method,
	//         body: body && JSON.stringify(body),
	//         headers: {
	//             'content-type': 'application/json',
	//             accept: 'application/json',
	//             authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
	//         },
	//     });
	//     return await response.json();
	// } catch (error) {
	//     console.error(error);

	//     this.setState({ error });
	// }


	// this.props.dispatchLoginUser( {
	//     loginUsername,
	//     loginPassword
	// })




	//
	//
	//
	//
	//
	//

	// const authResponse = await Axios({
	//     method: "POST",
	//     data: {
	//         username: 'username',
	//         password: 'password',
	//     },
	//     withCredentials: true,
	//     credentials: 'same-origin',
	//     crossDomain: true,
	//     url: `${API}${endpoint}`
	// });

	// await console.log('authResponse:');
	// await console.log(authResponse);

	// const authProcessing = (authResponse) => {
	// 	console.log('--AuthenticateUser--');
	//     console.log('login attempted. response:');
	//     console.log(res);



	return await Axios({
		    method: "POST",
		    data: {
		        username: 'username',
		        password: 'password',
		    },
		    headers: {
		        'content-type': 'application/json',
		        accept: 'application/json',
		        //authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
		        // authorization: `JWT ${localStorage.getItem('JWT') || null}`,
		    },
		    withCredentials: true,
		    credentials: 'same-origin',
		    crossDomain: true,
		    url: `${API}${endpoint}`
		}).then((res) => {

			console.log('--AuthenticateUser--');
		    console.log('login attempted. response:');
		    console.log(res);


		    // /* *
		    //  * ░░░░░▒▒▓▓
		    //  * ░░░░░▒▒▓▓    Payload schema
		    //  * ░░░░░▒▒▓▓
		    // */

		    var resPayload = {     
		        status:      {
		        	error:  	true,
		            type:       `danger`, 		//  e.g., success, danger, warning, info
		            message:    `An error occurred while attempting to log in.`        //  'An error has occurred.'
		        },
		        data:       {
		        	authenticated: false,
		        	currentUser:   false
		        },
		    };

		    if (res.hasOwnProperty('data')) {
		    	if (res.data.hasOwnProperty('auth')) {
		    		if (res.data.auth === true) {

		    			console.log('authentic!');


		    			resPayload.status.error 	= false;
		    			resPayload.status.type 		= 'success';
		    			resPayload.status.message 	= `User logged in.`;
		    			resPayload.data 			= {
		    				authenticated: 	true,
		    				currentUser: 	res.data.user.id
		    			}

		    		}
		    	}
		    }



	    // if (res.hasOwnProperty('data')) {
	    //     if (res.data.hasOwnProperty('error')) {
	    //         // if (res.data.error) {
	    //         //     setError({
	    //         //         type: res.data.error.type || null,
	    //         //         code: res.data.error.code || null,
	    //         //         message: res.data.error.message | null
	    //         //     });
	    //         // }
	    //         if (res.data.error) {
	    //             setErrorMessage(res.data.error.message)
	    //         }
	    //     }
	    // }


	   		return resPayload;

	    }).catch((err) => {
	    	console.log('axios auth error');
	    	console.log(err);
	    });

	    // localStorage.setItem('JWT', res.data.token);

}



export function LogoutUser() {

	const endpoint = '/logout';

	console.log("Attempting to log out...");

	Axios({
	    method: "POST",
	    data: {},
	    withCredentials: true,
	    url: `${API}${endpoint}`
	}).then((res) => {
		console.log('--LogoutUser--');
	    console.log('logout attempted. response:');
	    console.log(res);

	    // if (res.hasOwnProperty('data')) {
	    // 	if (res.data.hasOwnProperty('auth')) {
	    // 		if (res.data.auth === true) {
	    // 			return true;
	    // 		}
	    // 	}
	    // }

	    // return false;

	    return null;

	    // localStorage.setItem('JWT', res.data.token);

	});
}