import React, { Component }                 from 'react';
import {
    Button,
    IconButton,
    Menu,
    MenuItem,
    ListItemText,
}                                           from '@material-ui/core';
import { Link }                             from "react-router-dom";
import { AuthenticateUser, LogoutUser }     from './ApiMethods';
import { AccountCircle }                    from '@material-ui/icons';
import Nav                                  from 'react-bootstrap/Nav';
import Axios                                from 'axios';
import AuthMgr                              from 'components/Auth/AuthMgr';
import Cookies                              from 'js-cookie';


const API = process.env.REACT_APP_API_URL;

class AuthActions extends Component {

    

    
    // const authenticated = await this.checkAuth();

    state = {
        loading:        true,
        authenticated:  null,
        user:           null,
        menuAnchorEl:   null,
        error:          null
    };

    componentDidUpdate() {
        console.log('AuthActions: Component updated.');
        // this.checkAuthentication();
    }

    componentDidMount() {
        console.log('AuthActions: Component mounted.');

        //await this.checkAuth();
        this.checkSessionCookie();

        //this.checkAuth();
    }

    // async checkAuthentication(oktaAuth) {
    //     const authenticated = this.props.authState.isAuthenticated;
    //     if (authenticated !== this.state.authenticated) {
    //         const user = await this.oktaAuth.getUser();
    //         this.setState({ authenticated, user });
    //     }
    // }

    // async checkAuthentication() {
    //     console.log('AuthActions: checking authentication...');
    //     // const authenticated =  await AuthenticateUser();

        // const API = process.env.REACT_APP_API_URL || 'https://habricentral.local:8080';
    //     const endpoint = '/sessions/authenticate';

    //     const authenticated = await Axios({
    //         method: "POST",
    //         data: {
    //             username: 'username',
    //             password: 'password',
    //         },
    //         headers: {
    //             'content-type': 'application/json',
    //             accept: 'application/json',
    //             //authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
    //             // authorization: `JWT ${localStorage.getItem('JWT') || null}`,
    //         },
    //         withCredentials: true,
    //         credentials: 'same-origin',
    //         crossDomain: true,
    //         url: `${API}${endpoint}`
    //     }).then((res) => {

    //         console.log('--AuthenticateUser--');
    //         console.log('login attempted. response:');
    //         console.log(res);


    //         // /* *
    //         //  * ░░░░░▒▒▓▓
    //         //  * ░░░░░▒▒▓▓    Payload schema
    //         //  * ░░░░░▒▒▓▓
    //         // */

    //         var resPayload = {     
    //             status:      {
    //                 error:      true,
    //                 type:       `danger`,       //  e.g., success, danger, warning, info
    //                 message:    `An error occurred while attempting to log in.`        //  'An error has occurred.'
    //             },
    //             data:       {
    //                 authenticated: false,
    //                 currentUser:   false
    //             },
    //         };

    //         if (res.hasOwnProperty('data')) {
    //             if (res.data.hasOwnProperty('auth')) {
    //                 if (res.data.auth === true) {

    //                     console.log('authentic!');


    //                     resPayload.status.error     = false;
    //                     resPayload.status.type      = 'success';
    //                     resPayload.status.message   = `User logged in.`;
    //                     resPayload.data             = {
    //                         authenticated:  true,
    //                         currentUser:    res.data.user.id
    //                     }

    //                 }
    //             }
    //         }



    //     // if (res.hasOwnProperty('data')) {
    //     //     if (res.data.hasOwnProperty('error')) {
    //     //         // if (res.data.error) {
    //     //         //     setError({
    //     //         //         type: res.data.error.type || null,
    //     //         //         code: res.data.error.code || null,
    //     //         //         message: res.data.error.message | null
    //     //         //     });
    //     //         // }
    //     //         if (res.data.error) {
    //     //             setErrorMessage(res.data.error.message)
    //     //         }
    //     //     }
    //     // }


    //         return resPayload;

    //     }).catch((err) => {
    //         console.log('axios auth error');
    //         console.log(err);
    //     });

    //     console.log(AuthenticateUser);

    //     console.log('Authenticated via this authenticator: ');
    //     console.log(authenticated);
    //     console.log('Component\'s existing authentication state:');
    //     console.log(this.state);

    //     if (authenticated !== this.state.authenticated) {

            
    //         // const user = await this.oktaAuth.getUser();
    //         this.setState({ authenticated });
    //     }
    // }

    // login = () => this.props.oktaAuth.login('/');
    // logout = () => {
    //     this.handleMenuClose();
    //     this.props.oktaAuth.logout('/');
    // };

    handleMenuOpen = event => this.setState({ menuAnchorEl: event.currentTarget });
    handleMenuClose = () => this.setState({ menuAnchorEl: null });

    async fetch(method, endpoint, body) {

        let queryString = '';
        // if (accessToken == null) {
        //     this.setState({
        //         isLoading: false,
        //         error: true,
        //     });
        // } else {
            try {
                const response = await fetch(`${API}${endpoint}${queryString}`, {
                    method,
                    body: body && JSON.stringify(body),
                    // credentials: 'include',
                    credentials: 'same-origin',
                    headers: {
                        'content-type': 'application/json',
                        accept: 'application/json',
                        //authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
                        // authorization: `JWT ${localStorage.getItem('JWT') || null}`,
                    },
                });

                return await response.json();
            
            } catch (error) {
                console.error(error);

                this.setState({ error });
            }
        // }
    }


    // async fetch(method, endpoint, body) {

    //     try {

    //         console.log('async fetchin');

    //         fetch(`${endpoint}`, {
    //                 method,
    //                 body: body && JSON.stringify(body),
    //                 // mode: "same-origin",
    //                 credentials: "include",
    //                 headers: {
    //                     'content-type': 'application/json',
    //                     accept: 'application/json',
    //                     //authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
    //                     // authorization: `JWT ${localStorage.getItem('JWT') || null}`,
    //                 },
    //             }).then((response) => {

    //                 if (!response.ok) {
    //                     throw new Error(response.statusText);
    //                 }

    //                 console.log(response);

    //                 return response.json();


    //             }).then(response => {
    //                 this.setState({data: response});
    //             }).catch(err => {
    //                 console.error('error');

    //                 this.setState({
    //                     error: err
    //                 });
    //         }); 
    //     } catch (error) {
    //         console.log('error: ');
    //         console.log(error);
    //         this.setState({ error });
    //     }
    // }







    checkAuth() {

        this.fetch('POST', '/api/session/authenticate', {test: "yo"}).then(authResponse => {
 
            console.log('auth response:');
            console.log(authResponse);

            this.setState({ 
                loading: false, 
                authenticated: (authResponse) || false 
            });

        });
        
    }





    checkSessionCookie() {

        console.log('checking for valid session cookie...');

        // console.log(AuthMgr);

        // const sessionCookie = Cookies.get('hcsessionactive');

        // console.log(sessionCookie);

        console.log(AuthMgr.validateSession);
        // console.log

        // if (sessionCookie) {
        if (AuthMgr.validateSession) {
            console.log('Session exists and is active.');
            var sessionExists = true;
        } else {
            console.log('Session does not exist or is expired.');
            var sessionExists = false;
        }

        this.setState({
            loading: false,
            authenticated: (sessionExists) || false
        });

    }



    render() {


        console.log('Rendering AuthActions...');

        // this.checkAuth();

        // this.checkAuthentication();
        const { authenticated, loading, user, menuAnchorEl } = this.state;

        // if (authenticated === null) return null;
        // if (!authenticated) return <Button color="inherit" onClick={this.login}>Login</Button>;

        if (authenticated === null) {
            
            return (

                <Nav as="div" className={`global-header-access-control text-light`}>
                    
                    <Nav.Item as="div" className={`border-end border-light border-opacity-25`}>
                        <Nav.Link className={`link-light`} href="/login" className={`navbar-access-control link-light`}>Log in</Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item as="div">
                        <Nav.Link className={`link-light`} href="/register" className={`navbar-access-control link-light`}>Register</Nav.Link>
                    </Nav.Item>

                </Nav>

            );

        } else if (!authenticated) {

            return (

                <Nav as="div" className={`global-header-access-control text-light`}>
                    
                    <Nav.Item as="div" className={`border-end border-light border-opacity-25`}>
                        <Nav.Link className={`link-light`} href="/login" className={`navbar-access-control link-light`}>Log in</Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item as="div">
                        <Nav.Link className={`link-light`} href="/register" className={`navbar-access-control link-light`}>Register</Nav.Link>
                    </Nav.Item>

                </Nav>

            );

        } else if (authenticated) {

            return (

                <Nav as="div" className={`global-header-access-control text-light`}>
                    
                    <Nav.Item as="div">
                        <Nav.Link className={`link-light`} href="/logout" className={`navbar-access-control link-light`}>Log out</Nav.Link>
                    </Nav.Item>
                
                </Nav>

            );
        }



        const menuPosition = {
            vertical: 'top',
            horizontal: 'right',
        };

        if (loading) {

            return null

        } else {

            return (
                <div>
                    <IconButton onClick={this.handleMenuOpen} color="inherit">
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        anchorEl={menuAnchorEl}
                        anchorOrigin={menuPosition}
                        transformOrigin={menuPosition}
                        open={!!menuAnchorEl}
                        onClose={this.handleMenuClose}
                    >
                        <MenuItem onClick={this.logout}>
                            <ListItemText
                                primary="Logout"
                                secondary={user && user.name}
                            />
                        </MenuItem>
                    </Menu>
                </div>
            );

        }
    }
}

export default AuthActions;