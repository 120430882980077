import React, { Component, Fragment }               from 'react';
// import { SecureRoute, withOktaAuth }                from '@okta/okta-react';
import { Route, Redirect, Link }        from 'react-router-dom';
import { find, orderBy }                            from 'lodash';
import { compose }                                  from 'recompose';
import {
    Typography,
} from '@material-ui/core';

import ResourceAdder from 'components/ResourceAdder/ResourceAdder'; 
import ErrorSnackbar from 'components/ErrorSnackbar';





const API = process.env.REACT_APP_API || 'https://localhost:3001';



class ResourceDraftView extends Component {
    
    state = {
        loading: true,
        resources: [],
        error: null,
    };


    componentDidMount() {
        // this.setState({
        //     error: {
        //         message: "ResourceDraftView mounted."
        //     }
        // });
    }



    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`${API}${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
                },
            });
            return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }



    async getResources() {
        this.setState({ 
            loading: false, 
            resources: (await this.fetch('get', '/resources')) || [] 
        });
    }



    saveResource = async (resource) => {
        if (resource.id) {
            await this.fetch('put', `/resources/${resource.id}`, resource);
        } else {
            await this.fetch('resource', '/resources', resource);
        }

        this.props.history.goBack();
        this.getresources();
    }


    renderResourceEditor = () => {

        // if (this.state.loading) return null;
        // const resource = find(this.state.resources, { id: Number(id) });
        
        // if (!resource && id !== 'new') {
        //     return <Redirect to="/resources/new" />;
        // }

        // this.setState({
        //     error: {
        //         message: "Attempting to render resource editor..."
        //     }
        // });

        return (
            <Fragment>
{/*                <ResourceAdder onSave={this.saveResource}/*/}
                <ResourceAdder/>
            </Fragment>
        );

    }



    render() {

        const { classes } = this.props;

        return (
            <Fragment>
                <div className={`container-fluid`}>
                    <div className={`row`}>  
                        <div className={`col-12`}>
                            <h3 className={`p-3`}>Resources: New</h3>
                        </div>
                    </div>
                    <div className={`row`}>  
                        <div className={`col-12`}>
                            {this.renderResourceEditor()}
                        </div>
                    </div>
                </div>
                {this.state.error && (
                    <ErrorSnackbar
                        onClose={() => this.setState({ error: null })}
                        message={this.state.error.message}
                    />
                )}
            </Fragment>
        );
    }
}







export default compose()(ResourceDraftView);
