import React, { Component, Fragment } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Route, Redirect, Link, Outlet } from 'react-router-dom';
import {
    withStyles,
    Typography,
    Fab,
    IconButton,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core';
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import moment from 'moment';
import { find, orderBy } from 'lodash';
import { compose } from 'recompose';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AdminDashboardGauge from '../components/Admin/Dashboard/AdminDashboardGauge';
import ResourceEditor from          '../components/Admin/Components/ComponentFrame';
import AdminResourcesComponent      from '../components/Admin/Components/Resources/AdminResourcesComponent';
import AdminResourcesBrowser from     '../components/Admin/Components/Resources/AdminResourcesBrowser';
import ErrorSnackbar from '../components/ErrorSnackbar';
import AdminHeader from '../components/AdminHeader';

const styles = theme => ({
    resources: {
        marginTop: theme.spacing(2),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
    },
});

const API = process.env.REACT_APP_API || 'http://localhost:3001'; 






class AdminPanel extends Component {
    state = {
        loading: true,
        resources: [],
        error: null,
        currentView: 'home',
    };

    componentDidMount() {
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`${API}${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
                },
            });
            return await response.json();
        } catch (error) {
            console.error(error);

            this.setState({ error });
        }
    }



    /*/
    //
    //      Admin Header Nav Handler
    //
    /*/ 

    handleMainNav = (event, currentView) => {
        console.log('Message from headnav received!');
        console.log(currentView);
        console.log(event);
        console.log(this);
        this.setState({currentView});
        this.forceUpdate();
    }





    renderView() {


        console.log('Rendering currentView: ');
        console.log(this.state.currentView);

        switch(this.state.currentView) {

            case 'home':

                return(
                    <div className={`container-fluid bg-secondary py-2`}>
                        <div className={`row`}>
                            <AdminDashboardGauge gaugeName="Support Tickets" />
                            <AdminDashboardGauge gaugeName="Resources" />
                            <AdminDashboardGauge gaugeName="Questions & Answers" />
                            <AdminDashboardGauge gaugeName="Who's Online" />
                            <AdminDashboardGauge gaugeName="Members" />
                            <AdminDashboardGauge gaugeName="Groups!" />
                        </div>
                    </div>
                );


            break;

            case 'resources':

                return(
                    <AdminResourcesComponent/>
                );

            break;

            default:

                return(
                    <div className={`container-fluid bg-secondary py-2`}>
                        <div className={`row`}>
                            <AdminDashboardGauge gaugeName="Support Tickets" />
                            <AdminDashboardGauge gaugeName="Resources" />
                            <AdminDashboardGauge gaugeName="Questions & Answers" />
                            <AdminDashboardGauge gaugeName="Who's Online" />
                            <AdminDashboardGauge gaugeName="Members" />
                            <AdminDashboardGauge gaugeName="Groups" />
                        </div>
                    </div>
                );

            break;


        }

    }





    render() {
        const { classes } = this.props;

        return (
            <Fragment>

                <AdminHeader parentTie={this.handleMainNav.bind(this)}/>


                <Container fluid className={`py-3 admin-panel-section-title-block`}>
                    <Row>
                        <Col className={`admin-panel-section-title-area`}>
                            <div className={`admin-panel-section-title align-items-center`}>
                                <h1 className={`admin-panel-section-title-text h3 text-uppercase mb-0`}>Control Panel</h1>
                            </div>
                            <div className={`admin-panel-section-actions`}>
                                <Button className={`btn-nav-action`}>
                                    {/*<FontAwesomeIcon icon="fa-solid fa-circle-question" />*/}?
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>


                {this.renderView()}

                
                
            </Fragment>
        );
    }
}

export default compose(
    withOktaAuth,
    withStyles(styles),
)(AdminPanel);