// console.log('dirname:');
// console.log(__dirname);
// console.log(`${__dirname}/../.env.${process.env.REACT_APP_ENV}`);

console.log('process.env...')
console.log(process.env);

// require('dotenv').config({path: `${__dirname}/../.env.${process.env.REACT_APP_ENV}`});
// import dotenv from 'dotenv';
import React, { Fragment, createContext, useContext, useState }              from 'react';
import { Route, Redirect, Switch, NoMatch, useHistory }                        from 'react-router-dom';

// import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
// import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
// import { SecureRoute, Security, LoginCallback, useOktaAuth }   from '@okta/okta-react';

// import config from './config';
// import CorsErrorModal from './CorsErrorModal';
// import AuthRequiredModal from './AuthRequiredModal';
// import jwtDecode                        from 'jwt-decode';



import {  
    CssBaseline,
    withStyles,
} from '@material-ui/core';

import ConsumerApp              from 'ConsumerApp';
import AdminPanel               from './pages/AdminPanel';
// import ComponentFrame           from 'components/Admin/Components/ComponentFrame';
// import AdminResourcesBrowser    from 'components/Admin/Components/Resources/AdminResourcesBrowser';
import Login                    from 'components/Login';
import Logout                   from 'components/Logout';

import { AuthProvider, useAuthState, useAuthDispatch }         from 'components/Auth/AuthMgr';

// import history                  from 'history';

import SecureRoute             from 'Routes/PrivateRoute';

import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/global.scss';

const styles = theme => ({
    main: {
        // padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            // padding: theme.spacing(2),
        },
    },
});




export const AuthContext   = createContext();
const BasicContext  = createContext();

// const dotenvConfig = dotenv.config({path: `${__dirname}/../.env.${process.env.REACT_APP_ENV}`});



 

const App = ({ classes }) => {

        // const [globalAuth, setGlobalAuth] = useState('seems fine');

        const history       = useHistory();




        // const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
        // const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);

        

        // const triggerLogin = async () => {
        //     await oktaAuth.signInWithRedirect();
        // };

        // const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        //     history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
        //   };

        // const customAuthHandler = async () => {
        //     const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
            
        //     if (!previousAuthState || !previousAuthState.isAuthenticated) {
        //         // App initialization stage
        //         await triggerLogin();
        //     } else {
        //         // Ask the user to trigger the login process during token autoRenew process
        //         setAuthRequiredModalOpen(true);
        //     }
        // };



        return (

                // <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>    

                <AuthProvider>

                    <Fragment>

                        <CssBaseline />

                        <Switch>

                            <Route path="/login"    component={Login}/>
                            <Route path="/logout"   component={Logout}/>

                            {/*<SecureRoute isAllowed={!!authUser && authUser.roles.includes('admin')}>*/}


                            <Route exact path='/administrator'>
                                <SecureRoute permissionsRequired={'registered'}>
                                    <AdminPanel />
                                </SecureRoute>
                            </Route>


                            <Route path="/" >
                                <ConsumerApp />
                            </Route>

                        </Switch>

                    </Fragment>

                </AuthProvider>


        );
};


export default withStyles(styles)(App);