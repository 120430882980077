// import React, { useEffect, useState, useContext }   from 'react';
import React from 'react';
import { Route, Redirect, Outlet }              from 'react-router-dom';
import { useSelector }                  from 'react-redux';
// import jwtDecode                        from 'jwt-decode';
// import Axios from 'axios';
import { useAuthState }                 from 'components/Auth/AuthMgr';

// import AuthContext from 'App';


// const API = process.env.REACT_APP_API || 'https://habricentral.local';


/*/
//
//      Secure Routes, as implemented here: https://www.robinwieruch.de/react-router-private-routes/
//
/*/ 


const SecureRoute = ({
    permissionsRequired     = false,
    redirectPath            = '/login',
    children,
}) => {

    const { authUser }      = useAuthState();
    let isAllowed           = false;

    console.log("...shh..!");
    console.log('isAllowed?:');
    console.log(isAllowed);
    console.log('redirect path:');
    console.log(redirectPath);
    console.log('children:');
    console.log(children);
    console.log('authUser:');
    console.log(authUser);


    switch(permissionsRequired) {

        case 'registered':

            if (!!authUser) {
                isAllowed = true;
            }

        break;



        default:
            isAllowed = false;
        break;

    }


    if (!isAllowed) {
        return <Redirect to={redirectPath}/>;
    }

    return children; // ? children : <Outlet />
}

    



export default SecureRoute;