import React, { Component, Fragment } from 'react';
import { Route, Redirect, useLocation, Link } from 'react-router-dom';
import {
    withStyles,
    Typography,
    Fab,
    IconButton,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';



import ReactHtmlParser from 'react-html-parser';
import validURL from 'valid-url';
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import moment from 'moment';
import { find, orderBy } from 'lodash';
import { compose } from 'recompose';

import ResourceEditor   from 'components/ResourceEditor';
import ErrorSnackbar    from 'components/ErrorSnackbar';
import MetadataBlock    from 'components/MetadataBlock/MetadataBlock';
import TagSet           from 'components/MetadataBlock/TagSet/TagSet';
import BadgeSet         from 'components/MetadataBlock/BadgeSet/BadgeSet';


// import WordLimit from 'react-word-limit'; 
import trimCharacters from 'trim-characters';

const styles = theme => ({
    resources: {
        marginTop: theme.spacing(2),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(2), 
            right: theme.spacing(2),
        },
    },
});

const API = process.env.REACT_APP_API;






class ResourceDetails extends Component {

    // const location = useLocation();

    state = {
        resourceID: Number(this.props.resourceID),
        loading: true,
        resources: [],
        error: null,
    };

    componentDidMount() {
        
        /*/
        //
        //      Append Altmetric badge resources
        //
        /*/ 


        /*/
        //
        //      Fetch current resource
        //
        /*/ 

        this.getResource();
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    //authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
                    // authorization: `JWT ${localStorage.getItem('JWT') || null}`,
                },
            });
            return await response.json();
        } catch (error) {
            console.error(error);

            this.setState({ error });
        }
    }

    async getResource() {
        this.setState({ 
            loading: false, 
            resources: (await this.fetch('get', `/api/resources/${this.state.resourceID}`)) || []
        });
    }



    validateDOI = (doi) => {

        console.log("Testing DOI:");
        console.log(doi);

        const doiPattern = /^\b(10[.][0-9]{4,}(?:[.][0-9]+)*\/(?:(?!["&\'<>])\S)+)\b/;
        return doiPattern.test(doi);

    }



    preRenderSubAuthors = (resource) => {

        let outputBuffer = [];

        let useAmpersand    = false;
        let useComma        = false;

        let properAuthors   = [];
        let contributors    = [];

        if ('authors' in resource) {
            resource.authors.forEach(singleContributor => {
                switch(singleContributor.role) {

                    case 'submitter':
                        contributors.push(singleContributor);
                    break;

                    default:
                        properAuthors.push(singleContributor);
                    break;
                }
            })
        }


        if (properAuthors.length > 1) {

            //  Prepare to use an ampersand
            useAmpersand    = true;

        }

        if (properAuthors.length > 2) {

            //  Prepare to use a comma
            useComma        = true;

        } 



        // resource.authors.forEach(singleAuthor {
        for (let i = 0; i < properAuthors.length; i++) {

            let author = properAuthors[i];
        
            let nameSeparator   = '';

            if (properAuthors.length > 2) {

                if (i < properAuthors.length - 1) {
                    nameSeparator += ", ";
                }

                if (i == properAuthors.length - 2) {
                    nameSeparator += ' & ';
                }

            } else {
                if (i < properAuthors.length - 1) {
                    nameSeparator += " & ";
                }
            }

                

            // }


            if (author.name && author.name.length > 0) {

                outputBuffer.push(
                    <span className={`single-author-name`} key={author.id}>{author.name}</span>
                );

                if (nameSeparator.length > 0) {
                    outputBuffer.push(
                        <span className={`author-name-separator`}>{nameSeparator}</span>
                    )
                }

            }

        }

        return outputBuffer;

    }



    preRenderYear = (resource) => {

        let outputBuffer = [];

        let dateFieldLabel = '';

        let yearValue = false;

        
        /*/
        //
        //      Attempt to extract standardized generic publication date
        //
        /*/ 

        if ('metadataStructure' in resource && 'fields' in resource.metadataStructure) {
            resource.metadataStructure.fields.forEach( metaField => {

                if ('label' in metaField) {
                    if (metaField.label == 'Date') {
                        dateFieldLabel = metaField.name;
                    }
                }

            });
        }

        // if ('metadata' in resource && 'yearofpublication' in resource.metadata && 'value' in resource.metadata.yearofpublication && 'year' in resource.metadata.yearofpublication.value) {
        // if ('metadata' in resource && dateFieldLabel in resource.metadata && 'value' in resource.metadata[dateFieldLabel] && typeof(resource.metadata[dateFieldLabel].value) == 'object' && resource.metadata[dateFieldLabel].value != null && 'year' in resource.metadata[dateFieldLabel].value) {
        if ('metadata' in resource && dateFieldLabel in resource.metadata && 'value' in resource.metadata[dateFieldLabel]) {
            yearValue = resource.metadata[dateFieldLabel].value;
        }

        if (yearValue) {
            outputBuffer.push(
                <span className={`publication-date`}>
                    {yearValue}
                </span>
            );
        }

        return outputBuffer;

    }



    preRenderSubYear = (resource) => {

        let outputBuffer = [];

        let dateFieldLabel = '';

        let yearValue = false;

        
        /*/
        //
        //      Attempt to extract standardized generic publication date
        //
        /*/ 

        if ('metadataStructure' in resource && 'fields' in resource.metadataStructure) {
            resource.metadataStructure.fields.forEach( metaField => {

                if ('label' in metaField) {
                    if (metaField.label == 'Date') {
                        dateFieldLabel = metaField.name;
                    }
                }

            });
        }

        // if ('metadata' in resource && 'yearofpublication' in resource.metadata && 'value' in resource.metadata.yearofpublication && 'year' in resource.metadata.yearofpublication.value) {
        if ('metadata' in resource && dateFieldLabel in resource.metadata && 'value' in resource.metadata[dateFieldLabel] && 'year' in resource.metadata[dateFieldLabel].value) {
            yearValue = resource.metadata[dateFieldLabel].value.year;
        }

        if (yearValue) {
            outputBuffer.push(
                <span className={`publication-date`}>
                    {yearValue}
                </span>
            );
        }

        return outputBuffer;

    }



    preRenderTags = (resource) => {

        let outputBuffer = [];

        if ('tags' in resource && resource.tags.length > 0) {

            outputBuffer.push(

                <TagSet metadataSet={resource.tags}/>            
            );

        }

        return outputBuffer;

    }



    preRenderBadges = (resource) => {

        let outputBuffer = [];
        let activeBadges = [];

        if ('tags' in resource && resource.tags.length > 0) {

            resource.tags.forEach( singleTag => {

                if (singleTag.tag == 'openaccess') {

                    let activeBadge = Object.assign({}, singleTag);
                    activeBadge.raw_tag = '🔓 ' + singleTag.raw_tag;
                    activeBadges.push(activeBadge);

                }

            });            

            outputBuffer.push(

                <BadgeSet metadataSet={activeBadges}/>            
            );

        }

        return outputBuffer;

    }





    preRenderMetadata = (resource) => {

        // let resource = this.state.resources[0];

        // console.log("Prerendering resource:");
        // console.log(resource);

        // const preRenderYear = this.preRenderYear.bind(this);

        let outputBuffer = [];

        outputBuffer.push(

            <div className={'metadata-set'} key={`metadata-set-category`}>
                <div className={`metadata-key-block`}>
                    <span className={`metadata-key`}>Category</span>
                </div>
                <div className={`metadata-value-block`}>
                    <span className={`metadata-value`}><a href={`/resources/browse/${resource.type.name}`}>{resource.type.label}</a></span> 
                </div>
            </div>

        );


        if (resource.hasOwnProperty('abstract') && resource.abstract.length > 0) {

            outputBuffer.push(

                <div className={'metadata-set'} key={`metadata-set-abstract`}>
                    <div className={`metadata-key-block`}>
                        <span className={`metadata-key`}>Abstract</span>
                    </div>
                    <div className={`metadata-value-block`}>
                        <span className={`metadata-value`}>{ReactHtmlParser(resource.abstract)}</span>
                    </div>
                </div>

            );
        }

        // resource.metadata.forEach(singleField => {
        Object.values(resource.metadata).map((singleField, index) => {
        

            if ((singleField.value) && (singleField.type != 'hidden')) {

                console.log(singleField);

                // console.log(singleField.value);

                let valueString = '';

                
                switch(singleField.type) {

                    case 'date':

                        if (typeof(singleField.value) === 'string') {
                            valueString += singleField.value;
                        } else {

                            if (singleField.value.year) {
                                // console.log(singleField.value);
                                valueString += singleField.value.year;
                            }

                            if (singleField.value.month) {
                                valueString += singleField.value.month;
                            }

                            if (singleField.value.day) {
                                valueString += singleField.value.day;
                            }
                        }

                    break;


                    case 'geo':

                        if ((singleField.value.value || (singleField.value.lat + singleField.value.lng) == 0)) {
                            if (singleField.value.value.length > 0) {
                                valueString += singleField.value.value;
                            } else if (singleField.value.lat && singleField.value.lat != '0.0' && singleField.value.lng && singleField.value.lng != '0.0') {    
                                valueString += `${singleField.value.lat}, ${singleField.value.lng}`;
                            }
                        }

                    break;


                    case 'languages':

                        if (singleField.value && singleField.value.length > 0) {

                            if (singleField.value == 'en') {
                                valueString += 'English';
                            } else {
                                valueString += singleField.value;
                            }

                        }

                    break;


                    default:
                        valueString = singleField.value;
                    break;

                }



                if (singleField.label && valueString) {

                    /*/
                    //
                    //      Change formatting and advanced behaviors in response to metadata type
                    //
                    /*/ 

                    switch (singleField.label) {

                        case 'DOI':

                            if (this.validateDOI(valueString)) {

                                outputBuffer.push(

                                    <div className={'metadata-set'} key={`metadata-set-${singleField.label}`}>
                                        <div className={`metadata-key-block`}>
                                            <span className={`metadata-key`}>{singleField.label}</span>
                                        </div>
                                        <div className={`metadata-value-block`}>
                                            <span className={`metadata-value`}><a href={`https://dx.doi.org/${valueString}`} target={`_blank`}>{valueString}</a></span>
                                        </div>
                                    </div>

                                );

                            } else {
                                console.log('Resource DOI not valid.');
                            }

                        break;



                        case 'URL':

                            if (validURL.isUri(valueString)){

                                outputBuffer.push(

                                    <div className={'metadata-set'} key={`metadata-set-${singleField.label}`}>
                                        <div className={`metadata-key-block`}>
                                            <span className={`metadata-key`}>{singleField.label}</span>
                                        </div>
                                        <div className={`metadata-value-block`}>
                                            <span className={`metadata-value`}><a href={`${valueString}`} target={`_blank`}>{valueString}</a></span>
                                        </div>
                                    </div>

                                );

                            } else {
                                console.log('Resource URI not valid.');
                            }

                        break;



                        default:

                            outputBuffer.push(

                                <div className={'metadata-set'} key={`metadata-set-${singleField.label}`}>
                                    <div className={`metadata-key-block`}>
                                        <span className={`metadata-key`}>{singleField.label}</span>
                                    </div>
                                    <div className={`metadata-value-block`}>
                                        <span className={`metadata-value`}>{valueString}</span>
                                    </div>
                                </div>

                            );

                        break;

                    }

                }

            }

        });


        
        if (resource.type.name == 'bibliographies') {

            let childBlocks = [];

            const returnChildBlocks = () => {

                if (childBlocks.length > 0) {
                    return childBlocks;
                } else {
                    return null;
                }
            }

            if (resource.childResources.length > 0) {
            

                orderBy(resource.childResources, ['title', 'publishUp'], ['asc', 'desc']).map((childResource, index) => {

                    const childResourceTagBlock = (childResource) => {

                        if (childResource.tags.length > 0) {

                        return (
                            <span className={`resource-tags`}>
                                {this.preRenderTags(childResource)}
                            </span>
                        );

                        } else {
                            return null
                        }

                    }

                    childBlocks.push(

                    
                        <div className={`resource-record`} key={childResource.id}>
                            <div className={`top-line`}>
                                <span className={`resource-type`}>{childResource.type.label}</span>
                                {this.preRenderBadges(childResource)}
                            </div>
                            <a className={`h6 resource-title`} href={`/resources/${childResource.id}`}>{childResource.title}</a>
                            <div className={`resource-metadata`}>
                                <div className={`resource-byline`}>
                                    {this.preRenderYear(childResource)}
                                    <span className={`resource-contributors`}>
                                        {this.preRenderSubAuthors(childResource)} 
                                    </span>
                                </div>
                                <span className={`resource-abstract`}>
                                    {ReactHtmlParser(childResource.introtext)}
                                </span>
    
                                {childResourceTagBlock(childResource)}
                            
                            </div>
                        </div>

                    );


                });
            }



            outputBuffer.push(

                <div className={'metadata-set'} key={`metadata-set-${resource.type.name}`}>
                    <div className={`metadata-key-block`}>
                        <span className={`metadata-key`}>Contents</span>
                    </div>
                    <div className={`metadata-value-block`}>

                    {returnChildBlocks()}

                    </div>
                </div>

            );
    

        }



        // this.preRenderTags({resource});


        outputBuffer.push(

            <MetadataBlock metadataSetName={`Tags`} key={`resource-metadata-tags`} metadataSet={resource.tags} />
            
        );

        return outputBuffer;

    }



    preRenderSponsorBlock = (resource) => {

        let displaySponsorBlock = false;


        resource.tags.forEach(singleTag => {

            console.log(singleTag);


            switch(singleTag.tag) {

                case 'bookpup':

                // console.log('BOOKPUP BPOOKUPUP');

                    displaySponsorBlock = true;

                break;

                default:
                break;
            }

        });


        if (displaySponsorBlock) {
            return (

                <div className={`sidebar-block sponsor-block`}>
                    <div className={`sponsor-block-header`}>
                        <div className={`sidebar-header-nib`}></div>
                        <h4 className={`h6 sidebar-title`}>Sponsored By</h4>
                    </div>

                    <div className={`sponsor-block-body`}>
                        <div className={`sponsor-block-img`}>
                            <img src="/img/purdue_uni_press.jpg"/>
                        </div>
                        <div className={`sponsor-block-text`}>
                            <span className={`sponsor-text`}>To purchase this book, visit <a href="http://www.thepress.purdue.edu/">the Purdue University Press website.</a></span>
                        </div>
                    </div>
                </div>
            );
        }

    }




    preRenderAltmetricBlock = (resource) => {

        let displayAltmetricBlock = true;


        if (displayAltmetricBlock && ('doi' in resource.metadata) && ('value' in resource.metadata.doi)) {
            return (

                <div className={`sidebar-block altmetric-block sponsor-block`}>
                    <div className={`sponsor-block-header`}>
                        <div className={`sidebar-header-nib`}></div>
                        <h4 className={`h6 sidebar-title`}>Altmetrics</h4>
                    </div>

                    <div className={`sponsor-block-body`}>
                        <div className={'sponsor-block-payload'}>
                            {console.log("Altmetric ID:")}
                            {console.log(resource.metadata.doi)}

                            <div data-badge-popover="bottom" data-badge-type="medium-donut" data-doi={resource.metadata.doi.value} className={`altmetric-embed`}></div>
                        </div>
                    </div>
                </div>
            );
        }

    }






    preRenderMainPayload = (resource) => {

        let outputBuffer = [];
        let licenseIconBuffer = [];

        console.log('BBB resource:');
        console.log(resource);


        // resource.childResources.forEach(singleChild => {
        if (('childResources' in resource) && Array.isArray(resource.childResources) && (resource.childResources.length > 0)) {

            const singleChild = resource.childResources[0];

            console.log('child resource:');
            console.log(singleChild);

            var buttonPayload     = '#';
            var buttonText        = 'View Link';
            var buttonFiletype    = 'HTM';
            var buttonElement     = 'a';

            console.log('child resource type:');
            console.log(singleChild.type);

            console.log('child resource 0:');
            console.log(singleChild);

            const tempFilePrefix    = 'file://';
            const tempFileRoot      = '/Volumes/tmpdat00/quarantine/www/habricentral/app/site';
            const tempResourcePath  = '/resources';


            switch(singleChild.type.id) {

                case 11:    // External Link

                    buttonPayload     = singleChild.path;
                    buttonText        = 'View Link';
                    buttonFiletype    = 'HTM';
                    buttonElement     = 'a';

                break;

                

                case 33:    // PDF

                    buttonPayload     = `${tempFilePrefix}${tempFileRoot}${tempResourcePath}/${singleChild.path}`;
                    buttonText        = 'View Resource';
                    buttonFiletype    = 'PDF';
                    buttonElement     = 'a';

                break;

                

                default:    // Default

                    buttonPayload     = singleChild.path;
                    buttonText        = '';
                    buttonFiletype    = 'HTM';
                    buttonElement     = 'a';

                break;
            }


            /* *
             * ░░░░░▒▒▓▓
             * ░░░░░▒▒▓▓    Pre-render license details
             * ░░░░░▒▒▓▓
            */

            // console.log('uhhhhmmm...')
            // console.log(resource.license);

            // if (licenseIconBuffer.length > 0) { 
            //     licenseIconBuffer.forEach(singleSegment => {
            //         outputBuffer.push(
            //             singleSegment
            //         )
            //     })
            // }


            if (buttonElement == 'a') {
                outputBuffer.push(
                    <div className={`resource-launch-block col-4`}>
                    <Link to={{pathname: buttonPayload}} className={`bbb btn btn-primary btn-bbb`} target="_blank" rel="noopener">{buttonText} <span className={`bbb-type`}>({buttonFiletype})</span></Link>
                        <div className={`resource-license-block`}>
                            <div className={`license-properties`}>
                                {this.preRenderLicense(resource)}
                            </div>
                        </div>
                    </div>
                )
            } else {
                outputBuffer.push(
                    <div className={`resource-launch-block col-4`}>
                        <button className={`bbb btn btn-primary btn-bbb`}>{buttonText} <span className={`bbb-type`}>({buttonFiletype})</span></button>
                        <div className={`resource-license-block`}>
                            <div className={`license-properties`}>
                                {this.preRenderLicense(resource)}
                            </div>
                        </div>
                    </div>
                )
            }

        };


        

        return outputBuffer;

    }




    preRenderLicense = (resource) => {

        let licenseIconBuffer = [];



        if ((resource.hasOwnProperty('license') && resource.license.hasOwnProperty('display_assets') && resource.license.display_assets)) {

            console.log(resource);

            if (resource.license.display_assets.hasOwnProperty('icons')) {

                // console.log('icons...');

        


                for (const [key, value] of Object.entries(resource.license.display_assets.icons)) {

                    console.log(key);
                    console.log(value);

                    console.log(resource.license.display_assets.icons[key]);
                    console.log(resource['license']['display_assets']['icons'][key]['image']);


                    licenseIconBuffer.push(
                            <img className={`license-property-icon`} src={`/img/licenses/icons/${resource['license']['display_assets']['icons'][key]['image']}`} alt={`${resource['license']['display_assets']['icons'][key]['image']}`}/>
                    )
                }

                // licenseIconBuffer.push(
                //      </div>
                // )
            }


            if (resource.license.display_assets.hasOwnProperty('badges') && resource.license.display_assets.badges) {

                console.log('badgess...')

                for (const [key, value] of Object.entries(resource.license.display_assets.badges)) {

                    console.log(`${key}:`)
                    console.log(value)

                    licenseIconBuffer.push(
                        <div className={`license-badges`}>
                            <img className={`license-badge`} src={`/img/licenses/badges/${resource.license.display_assets.badges[key]['image']}`} alt={`${resource.license.title}`}/>
                        </div>
                    )
                }
            }

        }


        if (licenseIconBuffer.length > 0) {
            return licenseIconBuffer;
        } else {
            return (
                <div></div>
            );
        }

    }








    preRenderAuthors = (resource) => {


        const altmetricBadgeScript = document.createElement("script");
        altmetricBadgeScript.src = "https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js";
        altmetricBadgeScript.async = true;
        document.body.appendChild(altmetricBadgeScript);

        let outputBuffer = [];

        let useAmpersand    = false;
        let useComma        = false;

        let properAuthors   = [];
        let contributors    = [];

        if ('authors' in resource && resource.authors.length > 0) { 
            resource.authors.forEach(singleContributor => {
                switch(singleContributor.role) {

                    case 'submitter':
                        contributors.push(singleContributor);
                    break;

                    default:
                        properAuthors.push(singleContributor);
                    break;
                }
            })
        }


        if (properAuthors.length > 1) {

            //  Prepare to use an ampersand
            useAmpersand    = true;

        }

        if (properAuthors.length > 2) {

            //  Prepare to use a comma
            useComma        = true;

        } 



        // resource.authors.forEach(singleAuthor {
        for (let i = 0; i < properAuthors.length; i++) {

            let author = properAuthors[i];
        
            let nameSeparator   = '';

            if (properAuthors.length > 2) {

                if (i < properAuthors.length - 1) {
                    nameSeparator += ", ";
                }

                if (i == properAuthors.length - 2) {
                    nameSeparator += ' & ';
                }

            } else {
                if (i < properAuthors.length - 1) {
                    nameSeparator += " & ";
                }
            }

                

            // }


            if (author.name && author.name.length > 0) {

                outputBuffer.push(
                    <span className={`single-author-name`}>{author.name}</span>
                );

                if (nameSeparator.length > 0) {
                    outputBuffer.push(
                        <span className={`author-name-separator`}>{nameSeparator}</span>
                    )
                }

            }

        }

        return outputBuffer;

    }



    render() {

        const { classes } = this.props;

        const truncateText = (longText) => {
            return trimCharacters(longText, 500, false, '...');
        }

        return (

            <Fragment key={`resource-details`}>            

                {this.state.resources.length > 0 ? (

                    <Fragment key={`metadata-container`}>

                        <div className={`${classes.resources} detail-view`} key={`resource-metadata-container`}>

                            <div className={`row`}>

                            {orderBy(this.state.resources, ['updatedAt', 'title'], ['desc', 'asc']).map(resource => (

                                <Fragment key={`metadata-fragment-${resource.id}`}>

                                    <div className={`col-10`} key={`metadata-${resource.id}`}>

                                        <section className={`resource-primary`}>
                                            <div className={`overview-container`}>
                                                <div className={`row`}>
                                                    <div className={`resource-title-block col-8`}>
                                                        <header className={`resource-header h4`}>{resource.title}</header>
                                                        <div className={`resource-byline-block`}>
                                                            {this.preRenderAuthors(resource)}
                                                            {/*<span className={`resource-byline`}>J. Doe, M. Smith, T. Author, et al.</span>*/}
                                                        </div>
                                                    </div>
                                                    
                                                    {this.preRenderMainPayload(resource)}

                                                </div>
                                            </div>
                                        </section>

                                        <section className={`resource-metadata`}>

                                            <div className={`row`}>
                                                <div className={`col-12`}>

                                                    {console.log('pre resource:')}
                                                    {console.log(resource)}

                                                    <Tabs>
                                                        <Tab eventKey="about" title="About">
                                                            {this.preRenderMetadata(resource)}
                                                        </Tab>

                                                        <Tab eventKey="reviews" title="Reviews">
                                                            <p>...</p>
                                                        </Tab>

                                                        <Tab eventKey="supportingDocs" title="Supporting Docs">
                                                            <p>...</p>
                                                        </Tab>

                                                        <Tab eventKey="findThisText" title="Find This Text">
                                                            <p>...</p>
                                                        </Tab>

                                                    </Tabs>

                                                </div>
                                            </div>

                                        </section> 

                                    </div>


                                    <div className={`sidebar col-2`}>

                                        {this.preRenderAltmetricBlock(resource)}

                                        {this.preRenderSponsorBlock(resource)}

                                        
                                    </div>




                                </Fragment>

                            ))}

                            </div>
      
                        </div>

                    </Fragment>


                ) : (
                    !this.state.loading && <Typography variant="subtitle1">No resources to display</Typography>
                )}

            </Fragment>
        );
    }
}

export default compose(
    withStyles(styles),
)(ResourceDetails);