import React, { Component, Fragment } from 'react';
// import { withOktaAuth } from '@okta/okta-react';
// import {
//     withStyles,
//     Card,
//     CardContent,
//     CardActions,
//     Modal,
//     Button,
//     TextField,
// } from '@material-ui/core';
// import { compose } from 'recompose';
// import { Route, Redirect, Link } from 'react-router-dom';
// import { Form, Field } from 'react-final-form';
import { find, orderBy } from 'lodash';

// import ResourceAdder from 'resourceAdder/ResourceAdder';


const API = process.env.REACT_APP_API || 'http://localhost:3001';



// const ResourceEditorOld = ({ classes, resource, stage, onSave, history }) => (
//     <Form initialValues={resource} onSubmit={onSave}>
//         {({ handleSubmit }) => (
//             <Modal
//                 className={classes.modal}
//                 onClose={() => history.goBack()}
//                 open
//             >
//                 <Card className={classes.modalCard}>
//                     <form onSubmit={handleSubmit}>
//                         <CardContent className={classes.modalCardContent}>
//                             <Field name="title">
//                                 {({ input }) => <TextField label="Title" autoFocus {...input} />}
//                             </Field>
//                             <Field name="resourceType">
//                                 {({ input }) => <TextField label="Resource Type" autoFocus {...input} />}
//                             </Field>
//                             <Field name="license">
//                                 {({ input }) => <TextField label="License" autoFocus {...input} />}
//                             </Field>
//                             <Field name="abstract">
//                                 {({ input }) => (
//                                     <TextField
//                                         className={classes.marginTop}
//                                         label="Abstract"
//                                         multiline
//                                         rows={4}
//                                         {...input}
//                                     />
//                                 )}
//                             </Field>


//                             <Field name="publicationYear">
//                                 {({ input }) => <TextField label="Publication Year" autoFocus {...input} />}
//                             </Field>
//                             <Field name="publicationDate">
//                                 {({ input }) => <TextField label="Publication Date" autoFocus {...input} />}
//                             </Field>
//                             <Field name="parentPublication">
//                                 {({ input }) => <TextField label="Publication" autoFocus {...input} />}
//                             </Field>
//                             <Field name="volume">
//                                 {({ input }) => <TextField label="Volume" autoFocus {...input} />}
//                             </Field>
//                             <Field name="issue">
//                                 {({ input }) => <TextField label="Issue" autoFocus {...input} />}
//                             </Field>
//                             <Field name="pageRange">
//                                 {({ input }) => <TextField label="Page Numbers" autoFocus {...input} />}
//                             </Field>
//                             <Field name="issn">
//                                 {({ input }) => <TextField label="ISSN" autoFocus {...input} />}
//                             </Field>
//                             <Field name="publisher">
//                                 {({ input }) => <TextField label="Publisher" autoFocus {...input} />}
//                             </Field>
//                             <Field name="doi">
//                                 {({ input }) => <TextField label="DOI" autoFocus {...input} />}
//                             </Field>
//                             <Field name="url">
//                                 {({ input }) => <TextField label="URL" autoFocus {...input} />}
//                             </Field>
//                             <Field name="languagePrimary">
//                                 {({ input }) => <TextField label="Primary Language" autoFocus {...input} />}
//                             </Field>






//                         </CardContent>
//                         <CardActions>
//                             <Button size="small" color="primary" type="submit">Save</Button>
//                             <Button size="small" onClick={() => history.goBack()}>Cancel</Button>
//                         </CardActions>
//                     </form>
//                 </Card>
//             </Modal>
//         )}
//     </Form>
// );



class AdminResourceEditor extends Component {

    state = {
        loading: true,
        resourceTypes: [],
        resource: {
            type: null,
            metadata: {}
        },
        error: null,
    };


    componentDidMount() {
        this.getResourceTypes();
    }


    async fetch(method, endpoint, body) {

        try {

            const response = await fetch(`${API}${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    authorization: `bearer ${await this.props.authService.getAccessToken()}`,
                },
            });

            return await response.json();

        } catch (error) {

            console.error(error);
            this.setState({ error });

        }
    }

    async getResourceTypes() {
        this.setState({ 
            loading: false, 
            resourceTypes: (await this.fetch('get', '/resource-types')) || [] 
        });
    }

    render() {

        return(

            <Fragment>
                    <div className={`row`}>

                            {this.state.resourceTypes.length > 0 ? (

                                
                                <Fragment>

                                            {orderBy(this.state.resourceTypes, ['type'], ['desc', 'asc']).map(resourceType => (
                                                <div className={`col-3 p-5`}>
                                                    <button className={`btn btn-sm btn-primary w-100`}>{resourceType.type}</button>
                                                    <p className={`resource-type-description my-2`}>{resourceType.description}</p>
                                                </div>
                                            ))}

                                </Fragment>


                            ) : (
                                !this.state.loading && <span>No resource types to display</span>
                            )}
                            
                        
                    </div>
            </Fragment>

        );

    }


}



export default AdminResourceEditor;