import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { withRouter, useHistory } from 'react-router-dom';
// import { Form, Field } from 'react-final-form';
import SearchBarSuggestions         from 'components/SearchBar/SearchBarSuggestions';
import SearchStringParser           from 'components/SearchBar/SearchStringParser';


export const Component = withRouter(({ history, location }) => {

})


class SearchBar extends React.Component {

    state = {
        useSuggestions                      :   this.props.useSuggestions                   ||      false,
        loading                             :   false,
        error                               :   null,
        rawSearchString                     :   '',
        placeholderText                     :   this.props.placeholderText                  ||      null,
        searchDelayTimeout                  :   false,
        searchDelayTimeouts                 :   [],
        lastTimeout                         :   new Date(),
        suggestions                         :   [],
        filterPackage                       :   this.props.filterPackage                    || {
                                                                                                    "rawSearchString"                   :   '',
                                                                                                    "limit"                             :   [5],
                                                                                                    "start"                             :   [0],
                                                                                                    "type"                              :   [],
                                                                                                    "filetype"                          :   [],
                                                                                                    "authors"                           :   [],
                                                                                                    "publishedafter"                    :   [],
                                                                                                    "publishedbefore"                   :   [],
                                                                                                    "publisher"                         :   [],
                                                                                                    "submittedafter"                    :   [],
                                                                                                    "submittedbefore"                   :   [],
                                                                                                    "tags"                              :   [],
                                                                                                    "sortby"                            :   ['alt_score'],
                                                                                                    "order"                             :   ['desc'],
                                                                                                    "explodedCoreSearchString"          :   [],                 //      [dog,   "therapy animal",       benefits]
                                                                                                    "encodedExplodedCoreSearchString"   :   [],                 //      [dog,   %22therapy+animal%22,   benefits]
                                                                                                    "explodedFullSearchString"          :   [],                 //      [dog,   "therapy animal",       type:journalarticles,       benefits]
                                                                                                    "encodedExplodedFullSearchString"   :   [],                 //      [dog,   %22therapy+animal%22,   type%3Ajournalarticles,     benefits]
                                                                                                    "explodedFilterString"              :   [],                 //      [type:journalarticles]
                                                                                                    "encodedExplodedFilterString"       :   [],                 //      [type%3Ajournalarticles]
                                                                                                    "encodedCoreSearchString"           :   [],                 //      dog+%22therapy+animal%22+benefits
                                                                                                    "searchQueryString"                 :   ''                  //      ?q=dog+%22therapy+animal%22+benefits&type=journalarticles
                                                                                                },   
        suggestionUpdateRequired                :   false   
        // newFilter.rawSearchString   = qSearchString;
        // newFilter.limit             = qLimit;
        // newFilter.type              = qFilterResourceTypes;
        // newFilter.filetype          = qFileType;
        // newFilter.authors           = qAuthors;
        // newFilter.publishedafter    = qPublishedAfter;
        // newFilter.publishedbefore   = qPublishedBefore;
        // newFilter.publisher         = qPublisher;
        // newFilter.submittedafter    = qSubmittedAfter;
        // newFilter.submittedbefore   = qSubmittedBefore;
        // newFilter.tags              = qTags;
        // newFilter.sortby            = qSortBy;
        // newFilter.order     = 'asc'; 


        //qorder;
        // limit                               :   this.props.limit                            || ['20'],
        // type                                :   this.props.type                             || [],
        // filetype                            :   this.props.filetype                         || [],
        // authors                             :   this.props.authors                          || [],
        // publishedafter                      :   this.props.publishedafter                   || [],
        // publishedbefore                     :   this.props.publishedbefore                  || [],
        // publisher                           :   this.props.publisher                        || [],
        // submittedafter                      :   this.props.submittedafter                   || [],
        // submittedbefore                     :   this.props.submittedbefore                  || [],
        // tags                                :   this.props.tags                             || [],
        // explodedSearchString                :   this.props.explodedSearchString             || [],
        // explodedCoreSearchString            :   this.props.explodedCoreSearchString         || [],         //      [dog,   "therapy animal",       benefits]
        // encodedExplodedCoreSearchString     :   this.props.encodedExplodedCoreSearchString  || [],         //      [dog,   %22therapy+animal%22,   benefits]
        // explodedFullSearchString            :   this.props.explodedFullSearchString         || [],         //      [dog,   "therapy animal",       type:journalarticles,       benefits]
        // encodedExplodedFullSearchString     :   this.props.encodedExplodedFullSearchString  || [],         //      [dog,   %22therapy+animal%22,   type%3Ajournalarticles,     benefits]
        // explodedFilterString                :   this.props.explodedFilterString             || [],         //      [type:journalarticles]
        // encodedExplodedFilterString         :   this.props.encodedExplodedFilterString      || [],         //      [type%3Ajournalarticles]
        // encodedCoreSearchString             :   '',         //      dog+%22therapy+animal%22+benefits
        // searchQueryString                   :   this.props.searchQueryString                || "",      // The string of parameters that will be passed to both the browser and the API   
    }




    componentWillMount() {

        // if (this.rawSearchString == null) {
        //     this.setState ({
        //         rawSearchString: this.props.rawSearchString
        //     });
        // }
            // this.setState// onChange(this.preprocessSearchQuery(this.state.rawSearchString));

    }


    componentDidMount() {

        // const onChange = this.props.onChange || (() => {});
        // onChange(this.preprocessSearchQuery(this.state.rawSearchString));

    } 





    componentDidUpdate() {

        /*/
        //
        //      Perform search with updated parameters
        //
        /*/ 

        if (this.state.suggestionUpdateRequired) {
            this.getSearchSuggestions(this.state.filterPackage)
            // this.setState({
            //     suggestionUpdateRequired: false
            // }, function() {
            //     let suggestions = await this.getSearchSuggestions(this.state.filterPackage);
            //     return suggestions;
            // });
        }
    }





    async fetch(searchInput, method, endpoint, body) {

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}${searchInput}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    //authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
                    // authorization: `JWT ${localStorage.getItem('JWT') || null}`,
                },
            });
            
            return await response.json();
        
        } catch (error) {
            console.error(error);

            return error;
            //this.setState({ error });
        }
    }



    preprocessSearchQuery = (rawSearchString) => {

        if (typeof(rawSearchString) === 'undefined') {
            rawSearchString = false;
        }

        // let outboundFilters = {
        //     "rawSearchString"                   :   this.state.rawSearchString,
        //     "limit"                             :   this.state.limit,
        //     "type"                              :   this.state.type,
        //     "filetype"                          :   this.state.filterFileType,
        //     "authors"                           :   this.state.filterAuthors,
        //     "publishedafter"                    :   this.state.filterPublishedAfter,
        //     "publishedbefore"                   :   this.state.filterPublishedBefore,
        //     "publisher"                         :   this.state.filterPublisher,
        //     "submittedafter"                    :   this.state.filterSubmittedAfter,
        //     "submittedbefore"                   :   this.state.filterSubmittedBefore,
        //     "tags"                              :   this.state.filterTags,
        //     // "sortBy"                            :   this.state.activeSortType
        //     // "order"                           :   this.state.activeorder
        //     "explodedCoreSearchString"          :   this.state.explodedCoreSearchString,               //      [dog,   "therapy animal",       benefits]
        //     "encodedExplodedCoreSearchString"   :   this.state.encodedExplodedCoreSearchString,        //      [dog,   %22therapy+animal%22,   benefits]
        //     "explodedFullSearchString"          :   this.state.explodedFullSearchString,               //      [dog,   "therapy animal",       type:journalarticles,       benefits]
        //     "encodedExplodedFullSearchString"   :   this.state.encodedExplodedFullSearchString,        //      [dog,   %22therapy+animal%22,   type%3Ajournalarticles,     benefits]
        //     "explodedFilterString"              :   this.state.explodedFilterString,                   //      [type:journalarticles]
        //     "encodedExplodedFilterString"       :   this.state.encodedExplodedFilterString,            //      [type%3Ajournalarticles]
        //     "encodedCoreSearchString"           :   this.state.encodedCoreSearchString,                //      dog+%22therapy+animal%22+benefits
        //     "searchQueryString"                 :   this.state.searchQueryString                       //      ?q=dog+%22therapy+animal%22+benefits&type=journalarticles
        // }

        let modFilters = this.state.filterPackage;



        if (rawSearchString) {

            modFilters.rawSearchString = rawSearchString;

        }

        // if (this.state.useSuggestions) {
        //     this.setState({
        //         filterPackage:          filters,
        //         suggestionUpdateRequired:   true
        //     });
        // } else {
        // }

        let filters = SearchStringParser(modFilters);


        // let filters = this.extractLiterals(rawSearchString);
        // let filters = this.extractSearchFilters(rawSearchString);

        // let fullQueryString = filters.searchQueryString

        // let outputQuery = `?q=${filters.searchQueryString}`;

        // console.log(`output query: ${outputQuery}`);

        // filters.searchQueryString = outputQuery;

        // this.setState({
        //     searchFilters: filters,
        // }, ( (filters) => {
        //     return (filters);
        // }).bind(this, filters));

        return filters;

    }




    prepareToGetSearchSuggestions = async (searchFilters = false) => {

        if (!searchFilters) {
            searchFilters = this.state.filterPackage;
        }

        // set search input lag timeout

        console.log('waiting to fetch suggestions...');

        const timeoutDate = new Date();
        const searchPackage = {
            "time"          : timeoutDate,
            "searchFilters" : searchFilters
        }

        const existingTimeouts = this.state.searchDelayTimeouts;


        // setTimeout( (that, searchPkg) => {
        //                 console.log('waiting to fetch suggestions...');
        //                 console.log(this)
        //                 console.log(searchPkg.searchValue);
        //                 that.setState({
        //                     filterPackage:              searchPkg.searchFilters,
        //                     suggestionUpdateRequired:   false,
        //                 })
        //             }, 800, this, searchPackage, existingTimeouts)

        let that = this;

        existingTimeouts.push({
            "time" : timeoutDate,
            "timeout" : setTimeout( (that, searchPkg, existingTimeouts) => {
                console.log('waiting to fetch suggestions...');
                console.log(this);
                console.log(searchPkg.searchValue);
                this.setState({
                    filterPackage:              searchPkg.searchFilters,
                    suggestionUpdateRequired:   true,
                    searchDelayTimeouts:        existingTimeouts,
                    lastTimeout:                timeoutDate
                });
            }, 800, that, searchPackage, existingTimeouts)
        });

    }



    getSearchSuggestions = async (searchPackage) => {

        // const inputValue = input.currentTarget.value;

        let searchDelayTimeouts = this.state.searchDelayTimeouts;

        if (searchDelayTimeouts[searchDelayTimeouts.length - 1].time === this.state.lastTimeout) {

            const moddedSearchQuery = searchPackage.searchQueryString.replace(/\&limit=\d*/, '&limit=5');

            console.log(moddedSearchQuery);

            let searchResponse = await (this.fetch(moddedSearchQuery, 'get', `/api/search`));

            /*/
            //
            //      Clear older entries
            //
            /*/ 

            searchDelayTimeouts.forEach(singleTimeout => {
                clearTimeout(singleTimeout.timeout);
            })

            searchDelayTimeouts = [];


            // clearTimeout(this.searchDelayTimeouts[0]);
            console.log('search suggestion timeout cleared.');

            this.setState({ 
                loading: false, 
                suggestions: searchResponse.resources || [], 
                searchDelayTimeouts: searchDelayTimeouts,
                suggestionUpdateRequired: false
            });

        }
    }



    newExtractLiterals = (rawSearchString) => {
            var literals, output = [];
            var transLiterals = [];

            let safety = 0;

            literals = rawSearchString.match(/(\"[^\"]*\")/g) || [];

            // while (literals = /(\"[^\"]*\")/g.exec(rawSearchString)) {
            //     output.push(literals[1]);
            //     console.log(output);



            //     safety++;

            //     if (safety >= 10) {
            //         break;
            //     }
            // }

            if (literals && literals.length > 0) {

                literals.forEach((singleLiteral) => {
                    let transLiteral = singleLiteral.replace(/^\"/g, '|||"').replace(/\"$/g, '"|||');
                    if (transLiteral && transLiteral.length > 0) {
                        transLiterals.push(transLiteral);
                    }
                })
            }








            /*/
            //
            //      Break apart original search string by literal demarcations
            //
            /*/ 

            let splitString = rawSearchString.split('"') || [];


            /*/
            //
            //      Replace literals with transformed versions
            //
            /*/ 

            var mergedSet = [];

            if (splitString && Array.isArray(splitString)) {


                for (var i = 0; i < splitString.length; i++) {

                    var changed = false;

                    for (var j = 0; j < literals.length; j++) {

                        if (`"${splitString[i]}"` == literals[j]) {
                            // mergedSet.push(transLiterals[j]);
                            mergedSet.push(literals[j].trim());
                            changed = true;
                        }
                    }

                    if (!changed) {
                        mergedSet.push(splitString[i].trim());
                    }
                }
            }






            let mergedString = '';

            if (mergedSet && mergedSet.length > 0) {

                mergedSet.forEach( singleTermUnit => {

                    mergedString += singleTermUnit + ' ';

                });

            }




            // let cleanedString = mergedSet.split('|||');


            // splitString = rawSearchString.split('|||');


            // console.log(splitString);
            console.log('BEHOLD:');
            console.log(mergedSet);
            console.log(rawSearchString);
            console.log(mergedString);
            // console.log(cleanedString);

            // an elaborate "and long" search string with "multiple parts" and other things




            
    }




    extractSearchFilters = (rawSearchString) => {

        return SearchStringParser(rawSearchString);

    }




    performSearch = () => {

        // e.preventDefault();

        let preFilters = this.state.filterPackage;
        // preFilters.searchQueryString = e.target[0].value;
        let filters = SearchStringParser(preFilters);

        // let filters = this.preprocessSearchQuery(e.target[0].value);

        this.props.history.push(`/search${filters.searchQueryString}`);    

    }


    render() {

        const useSuggestions = this.props.useSuggestions || false;

        let onChange = this.prepareToGetSearchSuggestions;
        let onSubmit = this.performSearch;
        let preOnChange = (input) => {
            onChange(this.preprocessSearchQuery(input.target.value));
        }
        let preOnLoad = (input) => {
            onChange(this.preprocessSearchQuery(input.target.value));
        }
        let preOnSubmit = (event) => {
            event.preventDefault();
            onSubmit(this.preprocessSearchQuery(event.target[0].value));
        }


        if (useSuggestions) {
            onChange = this.props.onChange || this.prepareToGetSearchSuggestions;
            onSubmit = this.props.onSubmit || this.performSearch;

            

        } else {
            onChange = this.props.onChange || (() => {});
            onSubmit = this.props.onSubmit || (() => {});

            preOnChange = (input) => {
                // onChange(this.preprocessSearchQuery(input.target.value));
            }
        }



        


        let placeholderString = this.props.placeholderText;
        if (this.props.placeholderText == null) {
            placeholderString = `Enter a keyword or phrase, e.g., "animal behavior"`;
        } else {
            placeholderString = this.props.placeholderText;
        }

        // let currentValue = this.state.currentValue;


        // if (this.state.currentValue === false) {
        //     currentValue = '';
        // }
        // const onChange = ({
        //     if (this.suggestions) {
        //         return (this.props.onChange || this.prepareToGetSearchSuggestions);
        //     }
        // });



        const { location, history } = this.props;

        

        


        const existingSearchString = this.state.filterPackage.rawSearchString;


        return (

            <Fragment>

                <form onSubmit={preOnSubmit} className={`row mb-3`}>


                    <div className={`col-12`}>
                        {/*<label for="heroSearchQuery" className={`form-label`}>Search</label>*/}
                        <div className={`d-flex`}>
                            <input type="text" className={`form-control hero-search-field`} id="heroSearchQuery" aria-describedby="heroSearchQueryHelp" placeholder={placeholderString} defaultValue={existingSearchString} onLoad={preOnLoad} onChange={preOnChange}/>
                            <button type="submit" className={`btn btn-primary hero-search-button`}>search</button>
                        </div>
                        <SearchBarSuggestions suggestions={this.state.suggestions} />
                    </div>
                </form>

            </Fragment>

        );
    }
}

export default withRouter(SearchBar);