// import React                            from 'react';
import React, { Fragment }              from 'react';
import {
    Typography,
} from '@material-ui/core';

import SearchBar from '../components/SearchBar/SearchBar';


const heroBG = require('../img/bg-1299974297.jpg').default;


export default () => (
    <Fragment>
        <div className={`container-fluid bg-translucent home-hero`}>
            <div className={`container hero-content`}>
                <div className={`row`}>        
                    <div className={`col-12`}>
                        <SearchBar useSuggestions={true} />
                    </div>
                </div>
            </div>
            <div className={`bg-image`}>
                <img src={heroBG} />
            </div>
        </div>
        <div className={`container d-none`}>
            <div className={`row`}>        
                <div className={`col-3 vh-25`}>
                    <h2 className={`h3`}>Latest Discussions</h2>
                </div> 
                <div className={`col-3 vh-25`}>
                    <h2 className={`h3`}>Latest Resource</h2>
                </div> 
                <div className={`col-3 vh-25`}>
                    <h2 className={`h3`}>Latest Blog</h2>
                </div> 
                <div className={`col-3 vh-25`}>
                    <h2 className={`h3`}>Upcoming Events</h2>
                </div> 
                <div className={`col-3 vh-25`}>
                    <h2 className={`h3`}>Latest News</h2>
                </div> 
                <div className={`col-3 vh-25`}>
                    <h2 className={`h3`}>Subscribe to Our Newsletter</h2>
                </div>
                <div className={`col-6 vh-25`}>
                    <h2 className={`h3`}>GeoSearch</h2>
                </div>
            </div>
        </div>
    </Fragment>
);