import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { withRouter, useHistory } from 'react-router-dom';
// import { Form, Field } from 'react-final-form';
import MetadataBlock    from 'components/MetadataBlock/MetadataBlock';
import TagSet           from 'components/MetadataBlock/TagSet/TagSet';
import BadgeSet         from 'components/MetadataBlock/BadgeSet/BadgeSet';
import ReactHtmlParser  from 'react-html-parser';
import Tab              from 'react-bootstrap/Tab';
import Tabs             from 'react-bootstrap/Tabs';
import validURL         from 'valid-url';
import Axios            from 'axios';
import Col              from 'react-bootstrap/Col';
import Form             from 'react-bootstrap/Form';
import Row              from 'react-bootstrap/Row';





// export const Component = withRouter(({ history, location }) => {

// })


class ImportedResourceDestination extends React.Component {

    state = {
        loading:                true,
        error:                  null,
        resourceType:           null,
        //metadataSchemes:        this.props.metadataSchemes          
        resource:               this.props.destinationResource, 
        id:                     this.props.id, 
        // elaborated:             false,                                       // The resource metadata that will eventually be used to create the proper resource
        // importedResource:       this.props.importedResource,          // The resource metadata as imported via XML
        // elaboratedResource:     null,
        // sourceResource:         this.props.importedResource,
        destinationResource:    this.props.destinationResource,
        potentialDuplicates:    [],                                // A set of existing resources that may be duplicates of the one currently being submitted/evaluated
    }


    componentWillReceiveProps(nextProps) {

        console.log('receiving props...');
        console.log(nextProps);

        this.setState({ 
            resource:               nextProps.destinationResource, 
            destinationResource:    nextProps.destinationResource,
        });  
    }



    componentWillMount() {

    }


    componentDidMount() {

    } 



    shouldComponentUpdate(nextProps) {

        console.log('receiving props...');
        console.log(nextProps);

        if (nextProps.destinationResource.metadata !== this.state.resource.metadata) {

            // this.forceUpdate();

            return true;
            // this.setState({ 
            //     resource:               nextProps.destinationResource, 
            //     destinationResource:    nextProps.destinationResource,
            // });  

        } else {
            return false;
        }
    }





    componentDidUpdate() {

    }



    // async fetch(method, endpoint, body) {

    //     let self = this;

    //     Axios({
    //         method: "POST",
    //         data: body,
    //         withCredentials: true,
    //         crossDomain: false,
    //         url: "https://habricentral.local:3001/api/resources/elaborate"
    //     }).then((res) => {
            
    //         console.log('elaborate response:');
    //         console.log(res);

    //         if (res.hasOwnProperty('data') && res.data.hasOwnProperty('payload') && res.data.payload.hasOwnProperty('resource') && res.data.payload.resource.hasOwnProperty('title')) {
                
    //             console.log('elaborate title:');
    //             console.log(res.data.payload.resource.title);
    //             console.log(res.data.payload.resource); 

    //             if (res.data.payload.resource.hasOwnProperty('crossRefResource') && res.data.payload.resource.crossRefResource.hasOwnProperty('title')) {
    //                 console.log('CrossRef record found!');
    //                 console.log(res.data.payload.resource.crossRefResource);
    //             }
    //         }

    //         self.setState({ 
    //             elaborated:         true, 
    //             elaboratedResource:     res.data.payload.resource || false,
    //             sourceResource:         res.data.payload.resource || false,
    //             destinationResource:    res.data.payload.resource || false
    //         });



    //     }).catch( error => {
    //         console.error(error);
    //     });

    // }


    validateDOI = (doi) => {

        console.log("Testing DOI:");
        console.log(doi);

        const doiPattern = /^\b(10[.][0-9]{4,}(?:[.][0-9]+)*\/(?:(?!["&\'<>])\S)+)\b/;
        return doiPattern.test(doi);

    }



    preRenderSubAuthors = (resource) => {

        let outputBuffer = [];

        let useAmpersand    = false;
        let useComma        = false;

        let properAuthors   = [];
        let contributors    = [];

        if ('authors' in resource) {
            resource.authors.forEach(singleContributor => {
                switch(singleContributor.role) {

                    case 'submitter':
                        contributors.push(singleContributor);
                    break;

                    default:
                        properAuthors.push(singleContributor);
                    break;
                }
            })
        }


        if (properAuthors.length > 1) {

            //  Prepare to use an ampersand
            useAmpersand    = true;

        }

        if (properAuthors.length > 2) {

            //  Prepare to use a comma
            useComma        = true;

        } 



        // resource.authors.forEach(singleAuthor {
        for (let i = 0; i < properAuthors.length; i++) {

            let author = properAuthors[i];
        
            let nameSeparator   = '';

            if (properAuthors.length > 2) {

                if (i < properAuthors.length - 1) {
                    nameSeparator += ", ";
                }

                if (i == properAuthors.length - 2) {
                    nameSeparator += ' & ';
                }

            } else {
                if (i < properAuthors.length - 1) {
                    nameSeparator += " & ";
                }
            }

                

            // }


            if (author.name && author.name.length > 0) {

                outputBuffer.push(
                    <span className={`single-author-name`} key={author.id}>{author.name}</span>
                );

                if (nameSeparator.length > 0) {
                    outputBuffer.push(
                        <span className={`author-name-separator`}>{nameSeparator}</span>
                    )
                }

            }

        }

        return outputBuffer;

    }



    preRenderYear = (resource) => {

        let outputBuffer = [];

        let dateFieldLabel = '';

        let yearValue = false;

        
        /*/
        //
        //      Attempt to extract standardized generic publication date
        //
        /*/ 

        if ('metadataStructure' in resource && 'fields' in resource.metadataStructure) {
            resource.metadataStructure.fields.forEach( metaField => {

                if ('label' in metaField) {
                    if (metaField.label == 'Date') {
                        dateFieldLabel = metaField.name;
                    }
                }

            });
        }

        // if ('metadata' in resource && 'yearofpublication' in resource.metadata && 'value' in resource.metadata.yearofpublication && 'year' in resource.metadata.yearofpublication.value) {
        // if ('metadata' in resource && dateFieldLabel in resource.metadata && 'value' in resource.metadata[dateFieldLabel] && typeof(resource.metadata[dateFieldLabel].value) == 'object' && resource.metadata[dateFieldLabel].value != null && 'year' in resource.metadata[dateFieldLabel].value) {
        if ('metadata' in resource && dateFieldLabel in resource.metadata && 'value' in resource.metadata[dateFieldLabel]) {
            yearValue = resource.metadata[dateFieldLabel].value;
        }

        if (yearValue) {
            outputBuffer.push(
                <span className={`publication-date`}>
                    {yearValue}
                </span>
            );
        }

        return outputBuffer;

    }



    preRenderSubYear = (resource) => {

        let outputBuffer = [];

        let dateFieldLabel = '';

        let yearValue = false;

        
        /*/
        //
        //      Attempt to extract standardized generic publication date
        //
        /*/ 

        if ('metadataStructure' in resource && 'fields' in resource.metadataStructure) {
            resource.metadataStructure.fields.forEach( metaField => {

                if ('label' in metaField) {
                    if (metaField.label == 'Date') {
                        dateFieldLabel = metaField.name;
                    }
                }

            });
        }

        // if ('metadata' in resource && 'yearofpublication' in resource.metadata && 'value' in resource.metadata.yearofpublication && 'year' in resource.metadata.yearofpublication.value) {
        if ('metadata' in resource && dateFieldLabel in resource.metadata && 'value' in resource.metadata[dateFieldLabel] && 'year' in resource.metadata[dateFieldLabel].value) {
            yearValue = resource.metadata[dateFieldLabel].value.year;
        }

        if (yearValue) {
            outputBuffer.push(
                <span className={`publication-date`}>
                    {yearValue}
                </span>
            );
        }

        return outputBuffer;

    }



    preRenderTags = (resource) => {

        let outputBuffer = [];

        if ('tags' in resource && resource.tags.length > 0) {

            outputBuffer.push(

                <TagSet metadataSet={resource.tags} parentKey={this.props.sourceResource.title}/>            
            );

        }

        return outputBuffer;

    }



    preRenderBadges = (resource) => {

        let outputBuffer = [];
        let activeBadges = [];

        if ('tags' in resource && resource.tags.length > 0) {

            resource.tags.forEach( singleTag => {

                if (singleTag.tag == 'openaccess') {

                    let activeBadge = Object.assign({}, singleTag);
                    activeBadge.raw_tag = '🔓 ' + singleTag.raw_tag;
                    activeBadges.push(activeBadge);

                }

            });            

            outputBuffer.push(

                <BadgeSet metadataSet={activeBadges}/>            
            );

        }

        return outputBuffer;

    }





    preRenderMetadata = (resource, ownerName, editable: false) => {

        // let resource = this.state.resources[0];

        console.log("Prerendering destination resource metadata:");
        console.log(resource);


        // const preRenderYear = this.preRenderYear.bind(this);

        let metadataFields = [];

        // outputBuffer.push(

        //     {}

        //     <div className={'metadata-set'} key={`metadata-set-category`}>
        //         <div className={`metadata-key-block`}>
        //             <span className={`metadata-key`}>Category</span>
        //         </div>
        //         <div className={`metadata-value-block`}>
        //             <span className={`metadata-value`}><a href={`/resources/browse/${resource.type.name}`}>{resource.type.label}</a></span> 
        //         </div>
        //     </div>

        // );


        // if (!resource.hasOwnProperty('abstract') || !(resource.abstract.length > 0)) {
        //     resource.abstract = '';
        // }

        // outputBuffer.push(

        //     <div className={'metadata-set'} key={`metadata-set-abstract`}>
        //         <div className={`metadata-key-block`}>
        //             <span className={`metadata-key`}>Abstract</span>
        //         </div>
        //         <div className={`metadata-value-block`}>
        //             <span className={`metadata-value`}>{ReactHtmlParser(resource.abstract)}</span>
        //         </div>
        //     </div>

        // );
      

        // resource.metadata.forEach(singleSchemaField => {

        if (!resource.hasOwnProperty('metadataStructure')) {
            resource.metadataStructure = {
                fields: resource.metadata
            }
        }

        metadataFields = Object.values(resource.metadataStructure.fields).map((singleSchemaField, index) => {

            console.log('in structure loop...');
            console.log(resource.metadataStructure);

            console.log('associated metadata:');
            console.log(resource.metadata);

            console.log('singleSchemaField');
            console.log(singleSchemaField);

            console.log('metadata prop singleSchemaField.name');
            console.log(resource.metadata[singleSchemaField.name]);

            let dataField = {
                value: ''
            }

            if (resource.metadata.hasOwnProperty(singleSchemaField.name) && resource.metadata[singleSchemaField.name].hasOwnProperty('value')) {
                dataField = resource.metadata[singleSchemaField.name];

                console.log('fresh dataField:');
                console.log(dataField);
            }
        
            if (typeof(dataField) === 'string' || typeof(dataField) === 'number') {
                const singleSchemaFieldValue = dataField;
                dataField = {
                    value: singleSchemaFieldValue
                };
            }

            if (!dataField.hasOwnProperty('value')) {
                console.log('dataField:');
                console.log(dataField);
                dataField.value = '';
            }

            if (dataField.hasOwnProperty('value') && (!dataField.value)) {
                dataField.value = '';
            }

            console.log('dataField:');
            console.log(dataField);

            // console.log(singleSchemaField.value);

            let valueString = '';

            
            if (singleSchemaField.hasOwnProperty('type')) {

                switch(singleSchemaField.type) {

                    case 'date':

                        if (typeof(dataField.value) === 'string') {
                            valueString += dataField.value;
                        } else {

                            if (dataField.value.year) {
                                // console.log(singleSchemaField.value);
                                valueString += dataField.value.year;
                            }

                            if (dataField.value.month) {
                                valueString += dataField.value.month;
                            }

                            if (dataField.value.day) {
                                valueString += dataField.value.day;
                            }
                        }

                    break;


                    case 'geo':

                        if ((dataField.value.value || (dataField.value.lat + dataField.value.lng) == 0)) {
                            if (dataField.value.value.length > 0) {
                                valueString += dataField.value.value;
                            } else if (dataField.value.lat && dataField.value.lat != '0.0' && dataField.value.lng && dataField.value.lng != '0.0') {    
                                valueString += `${dataField.value.lat}, ${dataField.value.lng}`;
                            }
                        }

                    break;


                    case 'languages':

                        if (dataField.value && dataField.value.length > 0) {

                            if (dataField.value == 'en') {
                                valueString += 'English';
                            } else {
                                valueString += dataField.value;
                            }

                        }

                    break;


                    default:
                        valueString = dataField.value;
                    break;

                }

                console.log('valueString:');
                console.log(valueString);

            }



            if (singleSchemaField.hasOwnProperty('label') && singleSchemaField.label) {

                console.log('single field has label...');

                /*/
                //
                //      Change formatting and advanced behaviors in response to metadata type
                //
                /*/ 

                console.log('generating metadata fields:');
                console.log(singleSchemaField);
                console.log(valueString);

                let returnedField = {}


                switch (singleSchemaField.label) {

                    case 'DOI':

                        if (!(this.validateDOI(valueString))) {
                            valueString = '';
                        }

                        // outputBuffer.push(

                        //     <div className={'metadata-set'} key={`metadata-set-${singleSchemaField.label}`}>
                        //         <div className={`metadata-key-block`}>
                        //             <span className={`metadata-key`}>{singleSchemaField.label}</span>
                        //         </div>
                        //         <div className={`metadata-value-block`}>
                        //             <span className={`metadata-value`}><a href={`https://dx.doi.org/${valueString}`} target={`_blank`}>{valueString}</a></span>
                        //         </div>
                        //     </div>

                        // );

                        returnedField = {
                            label:          singleSchemaField.label,
                            name:           singleSchemaField.name,
                            options:        singleSchemaField.options,
                            required:       singleSchemaField.required,
                            type:           singleSchemaField.type,
                            value:          valueString,
                            defaultValue:   singleSchemaField.default
                        }

                    break;



                    case 'URL':

                        if (!(validURL.isUri(valueString))) {
                            valueString = '';
                        }


                        // outputBuffer.push(

                        //     <div className={'metadata-set'} key={`metadata-set-${singleSchemaField.label}`}>
                        //         <div className={`metadata-key-block`}>
                        //             <span className={`metadata-key`}>{singleSchemaField.label}</span>
                        //         </div>
                        //         <div className={`metadata-value-block`}>
                        //             <span className={`metadata-value`}><a href={`${valueString}`} target={`_blank`}>{valueString}</a></span>
                        //         </div>
                        //     </div>

                        // );



                        returnedField = {
                            label:          singleSchemaField.label,
                            name:           singleSchemaField.name,
                            options:        singleSchemaField.options,
                            required:       singleSchemaField.required,
                            type:           singleSchemaField.type,
                            value:          valueString,
                            defaultValue:   singleSchemaField.default
                        };

                    break;



                    default:

                        returnedField = {
                            label:          singleSchemaField.label,
                            name:           singleSchemaField.name,
                            options:        singleSchemaField.options,
                            required:       singleSchemaField.required,
                            type:           singleSchemaField.type,
                            value:          valueString,
                            defaultValue:   singleSchemaField.default
                        };

                    break;

                }

                return returnedField

            }

        });



        // outputBuffer.push(

        //     <MetadataBlock metadataSetName={`Tags`} key={`resource-metadata-tags`} metadataSet={resource.tags} />
            
        // );

        if (metadataFields && metadataFields.length > 0) {

            console.log('metadata fields:');
            console.log(metadataFields);

            return (

                /* *
                 * ░░░░░▒▒▓▓
                 * ░░░░░▒▒▓▓    Resource type select
                 * ░░░░░▒▒▓▓
                */

                /* *
                 * ░░░░░▒▒▓▓
                 * ░░░░░▒▒▓▓    Abstract
                 * ░░░░░▒▒▓▓
                */

                /* *
                 * ░░░░░▒▒▓▓
                 * ░░░░░▒▒▓▓    Other metadata
                 * ░░░░░▒▒▓▓
                */

                metadataFields.map((singleSchemaField, i) => {

                    if (singleSchemaField) {

                        console.log('metadata single field:');
                        console.log(singleSchemaField);

                        return (
                            <Form.Group as={Row} className="mb-3" controlId={`resource-${this.state.id}-destination-metadata-${singleSchemaField.name}`}>
                                <Form.Label column sm={5}>{singleSchemaField.label}</Form.Label>
                                <Col sm={7}>
                                    <Form.Control type="text" defaultValue={singleSchemaField.value} value={singleSchemaField.value} onChange={(event) => this.props.reportChanges(event, singleSchemaField.name, event.currentTarget.value)}/>
                                </Col>
                            </Form.Group>
                        )

                    } else {

                        return null

                    }

                })

                /* *
                 * ░░░░░▒▒▓▓
                 * ░░░░░▒▒▓▓    Tags
                 * ░░░░░▒▒▓▓
                */


            );
        } else {
            return null;
        }

    }



    preRenderAuthors = (resource, editable: false) => {

        if (resource && resource.hasOwnProperty('authors') && resource.authors.length > 0) {

            console.log('destination authors:');
            console.log(resource.authors);

            return (

                    <Form.Group as={Row} controlId={`resource-${this.state.id}-destination-authors`}>
                        
                        {
                            resource.authors.map((author, i) => (
                                <Fragment>
                                    <Form.Label>Given Name</Form.Label>
                                    <Form.Control key={`resource-${this.state.is }-author-${i}-given-name`} size="sm" type="text" placeholder={author.givenName} />
                                    <Form.Label>Middle Name</Form.Label>
                                    <Form.Control key={`resource-${this.state.is }-author-${i}-middle-name`} size="sm" type="text" placeholder={author.middleName} />
                                    <Form.Label>Family Name</Form.Label>
                                    <Form.Control key={`resource-${this.state.is }-author-${i}-family-name`} size="sm" type="text" placeholder={author.familyName} />
                                    <Form.Label>Role</Form.Label>
                                    <Form.Control key={`resource-${this.state.is }-author-${i}-role`} size="sm" type="text" placeholder={author.role} />
                                </Fragment>
                            ))
                        }

                    </Form.Group>
            );

        } else {

            return null;

        }

    }





    // const { location, history } = this.props;






    //     <Form.Group className="mb-3" controlId="formBasicEmail">
    //         <Form.Label>Username</Form.Label>
    //         <Form.Control type="text" placeholder="username" onChange={(e) => setLoginUsername(e.target.value)}/>
    //         <Form.Text className="text-muted">
    //             Enter your username.
    //         </Form.Text>
    //     </Form.Group>

    //     <Form.Group className="mb-3" controlId="formBasicPassword">
    //         <Form.Label>Password</Form.Label>
    //         <Form.Control type="password" placeholder="password" onChange={(e) => setLoginPassword(e.target.value)}/>
    //     </Form.Group>

    //     <Form.Group className="mb-3 d-flex gap-2" controlId="formSubmission">
    //         <Button className={`flex-fill rounded-pill`} variant="primary" size="lg" type="button" onClick={initiateLogin}>
    //             Submit
    //         </Button>
    // {/*        <Button variant="primary" type="button" onClick={login}>
    //             Submit
    //         </Button>*/}
    //     </Form.Group>

        
    render() {

        // const sourceResource            = this.state.sourceResource;
        let destinationResource       = this.state.destinationResource;
        let id                        = this.state.id;

        let preRenderMetadata         = this.preRenderMetadata;

        let test = 4;

        console.log('rendered resource object:');
        console.log(destinationResource);

        let preRenderedMetadata = <div></div>

        if (destinationResource.hasOwnProperty('metadata')) {

            preRenderedMetadata = preRenderMetadata(destinationResource, `destination${id}`, true);

        }

        if (!preRenderedMetadata) {
            preRenderedMetadata = <div></div>
        }

        

        return (

            <Fragment key={`resource-${id}-destination`}>

                <Form className={`resource-primary`}>

                    <div className={`overview-container`}>

                        <Row>

                            <Col className={`resource-title-block`} sm={8}>
                                <Form.Group as={Row} controlId={`resource-${id}-destination-title`}>
                                    <Form.Control as="textarea" rows={5} defaultValue={destinationResource.title || ''}/>
                                </Form.Group>

                                {/*{this.preRenderAuthors(destinationResource, true)}*/}

                            </Col>

                        </Row>

                    </div>


                    <section className={`resource-metadata detail-view`}>

                        <Row>
                            <div className={`col-12`}>

                                {console.log('preparing to render destination metadata fields...')}
                                {console.log(destinationResource)}

                                {preRenderedMetadata}
 

                            </div>
                        </Row>

                    </section> 

                </Form>

            </Fragment>

        )
    }
}

export default withRouter(ImportedResourceDestination);