import React, { Component, Fragment } from 'react';
// import { withOktaAuth } from '@okta/okta-react';
import { Route, Link } from 'react-router-dom';
import {
    withStyles,
    Typography,
    Fab,
    IconButton,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import moment from 'moment';
import { Form, Field } from 'react-final-form';
import { find, orderBy } from 'lodash';
import { compose } from 'recompose';

import AdminResourcesBrowser   from 'components/Admin/Components/Resources/AdminResourcesBrowser';
import AdminResourcesBulkImporter   from 'components/Admin/Components/Resources/AdminResourcesBulkImporter';
import ErrorSnackbar    from 'components/ErrorSnackbar';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


// import WordLimit from 'react-word-limit'; 
// import trimCharacters from 'trim-characters';


// const API = process.env.REACT_APP_API || 'http://localhost:3001';
const API = process.env.REACT_APP_API;



class AdminResourcesComponent extends Component {

    state = {
        loading:        false,
        error:          null,
        currentView:    'resources',
    };

    componentDidMount() {

        /*/
        //
        //      Get query vars
        //
        /*/ 

        let search = window.location.search;
        let params = new URLSearchParams(search);

        // this.setState({
        //     sortBy:     params.get('sortby')    ||  this.state.sortBy,
        //     limit:      params.get('limit')     ||  this.state.limit,
        //     start:      params.get('start')     ||  this.state.start
        // });

        // this.getResourceTypes();
        // this.getResources();
    }




    // async fetch(method, endpoint, body) {

    //     let querySet = [];

    //     switch (endpoint) {
    //         case '/api/resource-types':
    //         break;

    //         default:

    //             if (this.state.sortBy) {
    //                 querySet.push(`sortby=${this.state.sortBy}`);
    //             }

    //             if (this.state.limit) {
    //                 querySet.push(`limit=${this.state.limit}`);
    //             }

    //             if (this.state.start) {
    //                 querySet.push(`start=${this.state.start}`);
    //             }

    //             if (this.state.typeFilter) {
    //                 querySet.push(`type=${this.state.typeFilter}`);
    //             }

    //         break;
    //     }

    //     let queryString = '';

    //     let queryVarI = 0;

    //     querySet.forEach( queryVar => {

    //         if (queryVarI == 0) {
    //             queryString += '?';
    //         } else {
    //             queryString += '&';
    //         }

    //         queryString += queryVar;

    //         queryVarI++;

    //     });

    //     try {
    //         const response = await fetch(`${endpoint}${queryString}`, {
    //             method,
    //             body: body && JSON.stringify(body),
    //             headers: {
    //                 'content-type': 'application/json',
    //                 accept: 'application/json',
    //                 //authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
    //                 // authorization: `JWT ${localStorage.getItem('JWT') || null}`,
    //             },
    //         });
    //         return await response.json();
    //     } catch (error) {
    //         console.error(error);

    //         this.setState({ error });
    //     }
    // }





    handleNavLinkClick = (event, currentView) => {
        // props.parentTie(event, currentView);
        console.log("Attempting to change tab view to...");
        console.log(currentView);
        console.log(event);
        this.setState({currentView});
    }





    // renderResourceEditor = ({ match: { params: { id } } }) => {
    //     if (this.state.loading) return null;
    //     const resource = find(this.state.resources, { id: Number(id) });

    //     if (!resource && id !== 'new') return <Route render to="/resources" />;

    //     return <AdminResourceEditor resource={resource} onSave={this.saveResource} />;
    // };




    renderView() {


        console.log('Rendering resources tab currentView: ');
        console.log(this.state.currentView);

        switch(this.state.currentView) {

            case 'home':

                return(
                    <AdminResourcesBrowser/>
                );


            break;


            case 'resources':

                return(
                    <AdminResourcesBrowser/>
                );

            break;


            case 'orphans':

                return(
                    <Component/>
                );

            break;


            case 'types':

                return(
                    <Component/>
                );

            break;


            case 'licenses':

                return(
                    <Component/>
                );

            break;


            case 'authors':

                return(
                    <Component/>
                );

            break;


            case 'roles':

                return(
                    <Component/>
                );

            break;


            case 'plugins':

                return(
                    <Component/>
                );

            break;


            case 'import':

                return(
                    <AdminResourcesBulkImporter/>
                );

            break;


            case 'hooks':

                return(
                    <Component/>
                );

            break;


            default:

                return(
                    <AdminResourcesBrowser/>
                );

            break;


        }

    }









   




    render() {
        // const { classes } = this.props;

        // const truncateText = (longText) => {
        //     return trimCharacters(longText, 500, false, '...');
        // }

        return (

            <Fragment>

                <Tabs defaultActiveKey="resources" id="admin-resource-component-tabs" className="mb-3">
                    
                        <Tab eventKey="resources"   title="Resources"   className="container py-4 h-full">  
                            <AdminResourcesBrowser/>
                        </Tab>
                        <Tab eventKey="orphans"     title="Orphans"         className="container py-4 h-full"/>
                        <Tab eventKey="types"       title="Types"           className="container py-4 h-full" />
                        <Tab eventKey="licenses"    title="Licenses"        className="container py-4 h-full" />
                        <Tab eventKey="authors"     title="Authors"         className="container py-4 h-full" />
                        <Tab eventKey="roles"       title="Roles"           className="container py-4 h-full" />
                        <Tab eventKey="plugins"     title="Plugins"         className="container py-4 h-full" />
                        <Tab eventKey="import"      title="Import"          className="container py-4 h-full"> 
                            <AdminResourcesBulkImporter/>
                        </Tab>
                        <Tab eventKey="hooks"       title="Import Hooks"    className="container py-4 h-full" />
                
                </Tabs>


                {/*renderView();*/}

                
                { this.state.error && (
                        <ErrorSnackbar
                            onClose={() => this.setState({ error: null })}
                            message={this.state.error.message}
                        />
                ) }

            </Fragment>

        );
    }
}

export default compose(
    
)(AdminResourcesComponent);