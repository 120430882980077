import React, { Component, Fragment, useState } from 'react';
// import { withOktaAuth } from '@okta/okta-react';
import { Route, Redirect, Link, Outlet } from 'react-router-dom';
import {
    withStyles,
    Typography,
    Fab,
    IconButton,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core';
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import moment from 'moment';
import { find, orderBy } from 'lodash';
import { compose } from 'recompose';

import xml2js from 'xml2js';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import ResourceEditor from          '../components/Admin/Components/ComponentFrame';
import AdminResourcesImporterBrowser    from 'components/Admin/Components/Resources/AdminResourcesImporterBrowser';
import ImportedResourcePreview          from 'components/Admin/Components/Resources/AdminResourcesImportTile';
import ErrorSnackbar from 'components/ErrorSnackbar';
import AdminHeader from 'components/AdminHeader';

const styles = theme => ({
    resources: {
        marginTop: theme.spacing(2),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
    },
});

const API = process.env.REACT_APP_API || 'https://localhost:3001';






class AdminResourcesBulkImporter extends Component {


    state = {
        loading: true,
        resourceTypes: [],
        uploadedResources: [],
        error: null,
        selectedFile: null,
        isFileSelected: false,
        rawFileContents: null,
        currentView: 'uploader',
    };


    componentDidMount() {

        this.getResourceTypes();

    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`${API}${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    // authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
                },
            });
            return await response.json();
        } catch (error) {
            console.error(error);

            this.setState({ error });
        }
    }


    async getResourceTypes() {
        this.setState({ 
            loading: false, 
            resourceTypes: (await this.fetch('get', '/api/resource-types')) || [] 
        });
    }


    fileUploader() {
        return(
             <div>
                <input type="file" name="file" onChange={this.changeHandler} />
                <div>
                    <button onClick={this.handleSubmission}>Submit</button>
                </div>
            </div>
        )
    }



    changeHandler = (event) => {
        // setSelectedFile(event.target.files[0]);
        // setIsSelected(true);

        this.setState({selectedFile: event.target.files[0]})
        this.setState({isFileSelected: true});
    }

    

    handleSubmission = () => {
            const formData = new FormData();

            formData.append('File', this.state.selectedFile);

            console.log(this.state.selectedFile);

            // fetch(
            //     'https://freeimage.host/api/1/upload?key=<YOUR_API_KEY>',
            //     {
            //         method: 'POST',
            //         body: formData,
            //     }
            // )
            //     .then((response) => response.json())
            //     .then((result) => {
            //         console.log('Success:', result);
            //     })
            //     .catch((error) => {
            //         console.error('Error:', error);
            //     });

            if (this.state.selectedFile.type.match("text/xml")) {
                var reader = new FileReader();
                reader.readAsText(this.state.selectedFile);

                let uploadedResources = [];

                reader.onloadend = () => {

                    let xmlString = reader.result;

                    /*/
                    //
                    //      Remove wonky nodes
                    //
                    /*/ 

                    // xmlString.replace(/(<style.*?>?)([^</]*)(<\/style>?)/gi, (match) => {

                    //     /* *
                    //      * ░░░░░▒▒▓▓
                    //      * ░░░░░▒▒▓▓    NEED TO FIX THIS REGEX
                    //      * ░░░░░▒▒▓▓
                    //     */

                    //     let matches = match.matchAll(/(<style.*?>?)([^</]*)(<\/style>?)/g);

                    //     console.log('matches:');
                    //     console.log(matches);

                    //     console.log('returning:');
                    //     console.log(matches[1]);

                    //     return matches[1];

                    // });

                    let xmlObjF = new xml2js.Parser({explicitArray: false, mergeAttrs: true}).parseString(xmlString, function(error, result) {
                        if (error) {
                            console.log("XML PARSING ERROR:");
                            console.log(error);
                        } else {
                            console.log("Parsed XML:");
                            let xmlObj = result;
                            //habri.log(util.inspect(xmlObj, false, null));
                            console.log(xmlObj);

                            // let uploadedResources = [];

                            xmlObj.xml.records.record.forEach(singleRecord => {

                                let uploadedResource = {};

                                console.log('single record:');
                                console.log(singleRecord);


                                /*/
                                //
                                //      Append proto-metadata
                                //
                                /*/ 

                                uploadedResource.metadata = singleRecord;

                                
                                /*/
                                //
                                //      Fetch title
                                //
                                /*/ 

                                if (singleRecord.hasOwnProperty('titles')) {
                                    if (singleRecord.titles.hasOwnProperty('title')) {
                                        uploadedResource.title = singleRecord.titles.title.style["_"];

                                    }
                                }


                                /*/
                                //
                                //      Fetch resource type
                                //
                                /*/ 

                                if (singleRecord.hasOwnProperty('ref-type')) {
                                    uploadedResource.type           = {};
                                    uploadedResource.type.id        = singleRecord['ref-type']['_'];
                                    uploadedResource.type.label     = singleRecord['ref-type']['name'];
                                }


                                /*/
                                //
                                //      Fetch contributors
                                //
                                /*/ 

                                if (singleRecord.hasOwnProperty('contributors')) {

                                    uploadedResource.authors = [];

                                    /*/
                                    //
                                    //      Fetch authors
                                    //
                                    /*/ 

                                    if (singleRecord.contributors.hasOwnProperty('authors')) {
                                        if (singleRecord.contributors.authors.hasOwnProperty('author')) {

                                            let authorOrder = 0;


                                            if (Array.isArray(singleRecord.contributors.authors.author)) {

                                                singleRecord.contributors.authors.author.forEach(singleAuthor => {

                                                    let uploadedAuthor = {};

                                                    uploadedAuthor.ordering = authorOrder;
                                                    uploadedAuthor.role = 'author';
                                                    uploadedAuthor.name = singleAuthor.style['_'];

                                                    // {
                                                    //     "authorid": null,
                                                    //     "ordering": null,
                                                    //     "role": null,
                                                    //     "name": null,
                                                    //     "organization": null
                                                    // }

                                                    uploadedResource.authors.push(uploadedAuthor);

                                                    authorOrder++;

                                                }); 

                                            } else {

                                                let uploadedAuthor = {};

                                                uploadedAuthor.ordering = authorOrder;
                                                uploadedAuthor.role = 'author';
                                                uploadedAuthor.name = singleRecord.contributors.authors.author.style['_'];

                                                // {
                                                //     "authorid": null,
                                                //     "ordering": null,
                                                //     "role": null,
                                                //     "name": null,
                                                //     "organization": null
                                                // }

                                                uploadedResource.authors.push(uploadedAuthor);

                                            }

                                        }
                                    }

                                }


                                /*/
                                //
                                //      Fetch tags
                                //
                                /*/ 

                                if (singleRecord.hasOwnProperty('keywords')) {

                                    uploadedResource.tags = [];

                                    if (singleRecord.keywords.hasOwnProperty('keyword')) {

                                        if (Array.isArray(singleRecord.keywords.keyword)) {

                                            singleRecord.keywords.keyword.forEach(singleKeyword => {

                                                let uploadedTag = {};

                                                uploadedTag['raw_tag'] = singleKeyword.style['_'];

                                                uploadedResource.tags.push(uploadedTag);

                                            });

                                        } else {

                                            let uploadedTag = {};

                                            uploadedTag['raw_tag'] = singleRecord.keywords.keyword.style['_'];

                                            uploadedResource.tags.push(uploadedTag);

                                        }
                                    }

                                }

                                console.log('uploaded resource:');
                                console.log(uploadedResource);

                                uploadedResources.push(uploadedResource);

                            });

                            console.log('uploaded resource set:');
                            console.log(uploadedResources);

                        }
                    });


                    this.setState({
                        rawFileContents: reader.result,
                        uploadedResources: uploadedResources || false
                    })
                }




                /* *
                 * ░░░░░▒▒▓▓
                 * ░░░░░▒▒▓▓    Attempt to parse as XML
                 * ░░░░░▒▒▓▓
                */



                // reader.onload = function (e) {
                //     // xmlFileInfo.innerText = reader.result;
                //     console.log(reader.result);
                // }

                // let fileContents = reader.readAsText(this.state.selectedFile);
                // this.setState({rawFileContents: fileContents});

            } else {
                this.state.error = "File not supported!"
            }
    }




    renderView() {


        console.log('Rendering bulk importer currentView: ');
        console.log(this.state.currentView);

        switch(this.state.currentView) {

            case 'browser':

                return <AdminResourcesImporterBrowser key={`${this.state.currentView}`}/>

            break;

            case 'uploader':

                return(
                    <Fragment key={`results-${this.state.currentView}`}>
                        <input type="file" name="file" key={this.state.currentView} onChange={this.changeHandler} />
                        {this.state.isSelected ? (
                            <div key={this.state.currentView}>
                                <p key={`filename-${this.state.currentView}`}>Filename: {this.state.selectedFile.name}</p>
                                <p key={`filetype-${this.state.currentView}`}>Filetype: {this.state.selectedFile.type}</p>
                                <p key={`filesize-${this.state.currentView}`}>Size in bytes: {this.state.selectedFile.size}</p>
                                <p key={`modified-${this.state.currentView}`}>
                                    lastModifiedDate:{' '}
                                    {this.state.selectedFile.lastModifiedDate.toLocaleDateString()}
                                </p>
                            </div>
                        ) : (
                            <p key={`select-file-for-details-${this.state.currentView}`}>Select a file to show details</p>
                        )}

                        <div key={`submit-container-${this.state.currentView}`}>
                            <Button key={`submit-button-${this.state.currentView}`} onClick={this.handleSubmission}>Submit</Button>
                        </div>




                        {this.state.error ? (
                            <p>{this.state.error}</p>
                        ) : (
                            null
                        )} 


                        {this.state.uploadedResources ? (
                            this.state.uploadedResources.map((uploadedResource, i) => (
                                <ImportedResourcePreview importedResource={uploadedResource} id={i}/>
                            ))
                        ) : (
                            null
                        )}

                    </Fragment>


                    
                );


            break;



            default:

                return(
                    <Fragment key={`results-${this.state.currentView}`}>
                        <p>:-)</p>
                    </Fragment>
                );

            break;


        }

    }






    render() {

        return (
            <Fragment key={`admin-resources-bulk-importer`}>


                {this.renderView()}
            
                
            </Fragment>
        );
    }
}

export default compose(
)(AdminResourcesBulkImporter);