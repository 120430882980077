import React, { Component, Fragment } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Route, Redirect, Link } from 'react-router-dom';
import {
    withStyles,
    Typography,
    Fab,
    IconButton,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import moment from 'moment';
import { Form, Field } from 'react-final-form';
import { find, orderBy } from 'lodash';
import { compose } from 'recompose';

import ResourceEditor   from 'components/ResourceEditor';
import ErrorSnackbar    from 'components/ErrorSnackbar';
// import MetadataBlock    from 'components/MetadataBlock/MetadataBlock';
import TagSet           from 'components/MetadataBlock/TagSet/TagSet';
import BadgeSet         from 'components/MetadataBlock/BadgeSet/BadgeSet';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



// import WordLimit from 'react-word-limit'; 
import trimCharacters from 'trim-characters';

const styles = theme => ({
    resources: {
        marginTop: theme.spacing(2),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(2), 
            right: theme.spacing(2),
        },
    },
});

const API = process.env.REACT_APP_API_URL;

// const history = useHavigate();



const BrowseTypeFilterForm = ({onChange, resourceTypes}) => (

    <Form 
        onSubmit={onChange}
        onChange={onChange}
        render={({onChange}) => (

            <form onChange={onChange}>
                <Field name="filterType" component="select" label="Resource Type" autoFocus> 

                    <option value="all">All types</option>
                    {orderBy(resourceTypes, ['type'], ['asc']).map(resourceType => (
                        <option value={resourceType.id}>{resourceType.type}</option>
                    ))}
                </Field>

            </form>

        )}
    />
); 



const SearchBar = ({onChange, onSubmit = () => ({}) }) => (

    <Form 
        onSubmit={onSubmit}
        onChange={onChange}
        render={({onChange, onSubmit}) => (

            // <form onChange={onChange}>
            //     <Field name="filterType" component="select" label="Resource Type" autoFocus> 

            //         <option value="all">All types</option>
            //         {orderBy(resourceTypes, ['type'], ['asc']).map(resourceType => (
            //             <option value={resourceType.id}>{resourceType.type}</option>
            //         ))}
            //     </Field>

            // </form>

            <form onSubmit={onSubmit} className={`row pt-3`}>
                <div className={`col-12`}>
                    <div className={`d-flex`}>
                        <input type="text" className={`form-control hero-search-field`} id="resourcesSearchQuery" aria-describedby="resourcesSearchQueryHelp" placeholder={`Enter a keyword or phrase,`} onChange={onChange}/>
                        <button type="submit" className={`btn btn-primary hero-search-button`}>search</button>
                    </div>
                </div>
            </form>

        )}
    />
); 




class ResourcesBrowser extends Component {
    state = {
        loading: true,
        resources: [],
        error: null,
        sortBy: 'date',
        limit:  '100',
        start:  '0',
        typeFilter: 'all',
        searchQuery: ''
    };

    componentDidMount() {

        /*/
        //
        //      Get query vars
        //
        /*/ 

        let search = window.location.search;
        let params = new URLSearchParams(search);

        this.setState({
            // accessToken:    localStorage.getItem('JWT')     ||  this.state.accessToken,
            sortBy:         params.get('sortby')            ||  this.state.sortBy,
            limit:          params.get('limit')             ||  this.state.limit,
            start:          params.get('start')             ||  this.state.start
        });

        this.getResourceTypes();
        this.getResources();
    }



    async fetch(method, endpoint, body) {

        let querySet = [];

        switch (endpoint) {
            case '/api/resource-types':
            break;

            default:

                if (this.state.sortBy) {
                    querySet.push(`sortby=${this.state.sortBy}`);
                }

                if (this.state.limit) {
                    querySet.push(`limit=${this.state.limit}`);
                }

                if (this.state.start) {
                    querySet.push(`start=${this.state.start}`);
                }

                if (this.state.typeFilter) {
                    querySet.push(`type=${this.state.typeFilter}`);
                }

            break;
        }

        let queryString = '';

        let queryVarI = 0;

        querySet.forEach( queryVar => {

            if (queryVarI == 0) {
                queryString += '?';
            } else {
                queryString += '&';
            }

            queryString += queryVar;

            queryVarI++;

        });

        // if (accessToken == null) {
        //     this.setState({
        //         isLoading: false,
        //         error: true,
        //     });
        // } else {
            try {
                const response = await fetch(`${endpoint}${queryString}`, {
                    method,
                    body: body && JSON.stringify(body),
                    headers: {
                        'content-type': 'application/json',
                        accept: 'application/json',
                        //authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
                        // authorization: `JWT ${localStorage.getItem('JWT') || null}`,
                    },
                });
            return await response.json();
            
            } catch (error) {
                console.error(error);

                this.setState({ error });
            }
        // }
    }



    async getResourceTypes() {
        this.setState({ 
            loading: false, 
            resourceTypes: (await this.fetch('get', '/api/resource-types')) || [] 
        });
    }


    async getResources(filters) {

        /*/
        //
        //      FILTER PARAMETERS
        //
        //      filters = {
        //          resourceType:   'journalarticles',
        //          sortBy:         'date',
        //          limit:          '100',
        //          start:          0
        //      }
        //
        /*/ 

        // if (!filters) {
            
        //     filters = {
        //         resourceType:   'all',
        //         sortBy:         'date',
        //         limit:          100,
        //         start:          0
        //     }
        // }

        this.setState({ 
            loading: false, 
            resources: (await this.fetch('get', '/api/resources')) || [] 
        });
    }



    parseSearchString() {

    }



    async updateSearch(input) {

        this.setState({
            searchString: input.currentTarget.value
            // searchQuery: (await this.fetch('get', '/api/search')) || []
        })

    }



    async performSearch(e) {

        e.preventDefault();
        console.log(e);

        this.setState({ 
            loading: false, 
            resources: (await this.fetch('get', '/api/search')) || [] 
        });

    }


    saveResource = async (resource) => {

        if (resource.id) {
            await this.fetch('put', `/api/resources/${resource.id}`, resource);
        } else {
            await this.fetch('post', '/api/resources', resource);
        }

        this.props.history.goBack();
        this.getResources();
    }

    async deleteResource(resource) {
        if (window.confirm(`Are you sure you want to delete "${resource.title}"`)) {
            await this.fetch('delete', `/api/resources/${resource.id}`);
            this.getResources();
        }
    }



    preRenderAuthors = ({resource}) => {

        let outputBuffer = [];

        let useAmpersand    = false;
        let useComma        = false;

        let properAuthors   = [];
        let contributors    = [];

        if ('authors' in resource) {
            resource.authors.forEach(singleContributor => {
                switch(singleContributor.role) {

                    case 'submitter':
                        contributors.push(singleContributor);
                    break;

                    default:
                        properAuthors.push(singleContributor);
                    break;
                }
            })
        }


        if (properAuthors.length > 1) {

            //  Prepare to use an ampersand
            useAmpersand    = true;

        }

        if (properAuthors.length > 2) {

            //  Prepare to use a comma
            useComma        = true;

        } 



        // resource.authors.forEach(singleAuthor {
        for (let i = 0; i < properAuthors.length; i++) {

            let author = properAuthors[i];
        
            let nameSeparator   = '';

            if (properAuthors.length > 2) {

                if (i < properAuthors.length - 1) {
                    nameSeparator += ", ";
                }

                if (i == properAuthors.length - 2) {
                    nameSeparator += ' & ';
                }

            } else {
                if (i < properAuthors.length - 1) {
                    nameSeparator += " & ";
                }
            }

                

            // }


            if (author.name && author.name.length > 0) {

                outputBuffer.push(
                    <span className={`single-author-name`} key={author.id}>{author.name}</span>
                );

                if (nameSeparator.length > 0) {
                    outputBuffer.push(
                        <span className={`author-name-separator`}>{nameSeparator}</span>
                    )
                }

            }

        }

        return outputBuffer;

    }



    preRenderYear = ({resource}) => {

        let outputBuffer = [];

        let dateFieldLabel = '';

        let yearValue = false;

        
        /*/
        //
        //      Attempt to extract standardized generic publication date
        //
        /*/ 

        if ('metadataStructure' in resource && 'fields' in resource.metadataStructure) {
            resource.metadataStructure.fields.forEach( metaField => {

                if ('label' in metaField) {
                    if (metaField.label == 'Date') {
                        dateFieldLabel = metaField.name;
                    }
                }

            });
        }

        // if ('metadata' in resource && 'yearofpublication' in resource.metadata && 'value' in resource.metadata.yearofpublication && 'year' in resource.metadata.yearofpublication.value) {
        if ('metadata' in resource && dateFieldLabel in resource.metadata && 'value' in resource.metadata[dateFieldLabel] && 'year' in resource.metadata[dateFieldLabel].value) {
            yearValue = resource.metadata[dateFieldLabel].value.year;
        }

        if (yearValue) {
            outputBuffer.push(
                <span className={`publication-date`}>
                    {yearValue}
                </span>
            );
        }

        return outputBuffer;

    }



    preRenderTags = ({resource}) => {

        let outputBuffer = [];

        outputBuffer.push(

            <TagSet metadataSet={resource.tags}/>            
        );

        return outputBuffer;

    }



    preRenderBadges = ({resource}) => {

        let outputBuffer = [];
        let activeBadges = [];

        resource.tags.forEach( singleTag => {

            if (singleTag.tag == 'openaccess') {

                let activeBadge = Object.assign({}, singleTag);
                activeBadge.raw_tag = '🔓 ' + singleTag.raw_tag;
                activeBadges.push(activeBadge);

            }

        });

        outputBuffer.push(

            <BadgeSet metadataSet={activeBadges}/>            
        );

        return outputBuffer;

    }



    changeTypeFilter = (input) => {

        console.log('HANDLING CHANGE!');
        console.log(input);

        let filterType = input.target.value;

        this.setState({typeFilter: filterType}, () => {
            this.getResources();
        });
        

    }    



    renderResourceEditor = ({ match: { params: { id } } }) => {
        if (this.state.loading) return null;
        const resource = find(this.state.resources, { id: Number(id) });

        if (!resource && id !== 'new') return <Redirect to="/resources" />;

        return <ResourceEditor resource={resource} onSave={this.saveResource} />;
    };









   




    render() {
        const { classes } = this.props;

        // const truncateText = (longText) => {
        //     return trimCharacters(longText, 500, false, '...');
        // }

        return (
            <Fragment>
                {/* <Typography variant="h4">Resources</Typography> */}
                <h2 className={`h4`}>Resources</h2>

                {
                    //<SearchBar onChange={this.updateSearch}/>
                }

                {this.state.resources.length > 0 ? (

                    <Fragment>

                    <div className={`${classes.resources} browse-view`}>
                        <div className={`browse-header`}>
                            <BrowseTypeFilterForm onChange={this.changeTypeFilter} resourceTypes={this.state.resourceTypes}/>
                        </div>

                        <div className={`browse-results`}>

            
                            {orderBy(this.state.resources, ['publishUp', 'title'], ['desc', 'asc']).map(resource => (

                                
                                    <div className={`resource-record`} key={resource.id}>
                            {/*            <div className={`action-initiators`}>
                                            <button component={Link} to={{
                                                pathname: `/resources/${resource.id}`,
                                                state: { 
                                                    resourceID: resource.id
                                                }
                                            }} />
                                            <button onClick={() => this.deleteResource(resource)} />
                                        </div>*/}
                                        <div className={`top-line`}>
                                            <span className={`resource-type`}>{resource.type.label}</span>
                                            {this.preRenderBadges({resource})}
                                        </div>
                                        <a className={`h6 resource-title`} href={`/resources/${resource.id}`}>{resource.title}</a>
                                        <div className={`resource-metadata`}>
                                            <div className={`resource-byline`}>
                                                {this.preRenderYear({resource})}
                                                <span className={`resource-contributors`}>
                                                    {this.preRenderAuthors({resource})} 
                                                </span>
                                            </div>
                                            <span className={`resource-abstract`}>
                                                {ReactHtmlParser(resource.introtext)}
                                            </span>
                                            <span className={`resource-tags`}>
                                                {this.preRenderTags({resource})}
                                            </span>
                                        </div>
                                    </div>

                            ))}
               
                        </div>
                    </div>


                    </Fragment>

                ) : (
                    !this.state.loading && <Typography variant="subtitle1">No resources to display</Typography>
                )}
     
                <Route exact path="/resources/:id" render={this.renderResourceEditor} />
                {this.state.error && (
                    <ErrorSnackbar
                        onClose={() => this.setState({ error: null })}
                        message={this.state.error.message}
                    />
                )}
            </Fragment>
        );
    }
}

export default compose(
    withOktaAuth,
    withStyles(styles),
)(ResourcesBrowser);