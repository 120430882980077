import React, { useState, useRef, useEffect, Fragment }    from 'react';
import { Link, useHistory }             from 'react-router-dom';


import { connect, useDispatch }         from 'react-redux';
// import { registerUser }                 from '../js/actions';
import { loginUser }                    from '../js/actions';


import Toast                            from 'react-bootstrap/Toast';
import Container                        from 'react-bootstrap/Container';
import Row                              from 'react-bootstrap/Row';
import Col                              from 'react-bootstrap/Col';
import Button                           from 'react-bootstrap/Button';
import Form                             from 'react-bootstrap/Form';
import Axios                            from 'axios';

import ErrorSnackbar                    from 'components/ErrorSnackbar';

const siteLogo      = require('../img/hc_logo_color_v1_trans.png').default; 


// const mapDispatchToProps = (dispatch) => {
//     return {
//         loginUser: (credentials) => dispatch(loginUser(credentials))
//     };
// };




const Logout = () => {

    const API = process.env.REACT_APP_API_URL;// || 'https://habricentral.local';

    const history                                       = useHistory();

    // const [registerUsername,    setRegisterUsername]    = useState("");
    // const [registerPassword,    setRegisterPassword]    = useState("");
    const [error,               setError]               = useState({
        type:       null,
        code:       null,
        message:    null
    });
    const [errorMessage,        setErrorMessage]        = useState("");
    // const [loginUsername,       setLoginUsername]       = useState("");
    // const [loginPassword,       setLoginPassword]       = useState("");

    // const dispatch                                      = useDispatch();

    // const useComponentDidMount = (callback) => useEffect({
    //     logout()//);//{ //callback, []);
    //     //logout();
    // });

    // const useComponentWillMount = (callback) => {

    //     const willMount = useRef(true);

    //     if (willMount.current) {
    //         callback();
    //     }

    //     willMount.current = false;
    //     logout();
    // }

    const logout = () => {

        const endpoint = '/api/users/logout';

        console.log(`Attempting to log out via ${API}${endpoint}`);

        Axios({
            withCredentials: true,
            credentials: 'same-origin',
            crossDomain: true,
            url: `${API}${endpoint}`
        }).then((res) => {
            console.log('logout attempted. response:');
            console.log(res);




            /*/
            //
            //      NEED TO SETUP USER DATA PROCESSING HERE (PROBABLY)
            //
            /*/ 




            // /* *
            //  * ░░░░░▒▒▓▓
            //  * ░░░░░▒▒▓▓    Payload schema
            //  * ░░░░░▒▒▓▓
            // */

            // const resPayload = {
            //     status:     null,       //  200
            //     statusText: '',         //  'OK'
            //     error:      {
            //         type:       null,
            //         code:       null,
            //         message:    null        //  'An error has occurred.'
            //     },
            //     data:       {},
            // }

            if (res.hasOwnProperty('data')) {
                if (res.data.hasOwnProperty('error')) {
                    // if (res.data.error) {
                    //     setError({
                    //         type: res.data.error.type || null,
                    //         code: res.data.error.code || null,
                    //         message: res.data.error.message | null
                    //     });
                    // }
                    if (res.data.error) {
                        setErrorMessage(res.data.error.message)
                    }
                }
            }


            // localStorage.setItem('JWT', res.data.token);

            // setCookie('name', 'value', 0)



            // dispatch(loginUser())
            // .then(() => history.push('/'))
            // .catch(() => setError ({ error: true }));
        }).then(() => { 
            history.push('/');
        });

    }

    useEffect(() => {
        logout();
    });

    return (

        <Fragment>

{/*            <Container className="vh-66">
                <Row>
                    <Col sm={{span: 6, offset: 3}}>

                        <img className={`w-100 mb-5 p-5`} src={siteLogo} alt={`HABRI Central`} />

                        <Form className={`user-authenticator block-unit`}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" placeholder="username" onChange={(e) => setLoginUsername(e.target.value)}/>
                                <Form.Text className="text-muted">
                                    Enter your username.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="password" onChange={(e) => setLoginPassword(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3 d-flex gap-2" controlId="formSubmission">
                                <Button className={`flex-fill rounded-pill`} variant="primary" size="lg" type="button" onClick={login}>
                                    Submit
                                </Button>
 
                            </Form.Group>

                        </Form>

                    </Col>
                </Row>
            </Container>

            {errorMessage && (
                <ErrorSnackbar
                    onClose={ () => setErrorMessage(null) }
                    message={ errorMessage }
                />
            )}*/}

        </Fragment>

    );
};

export default connect(null)(Logout);
