import React, { Component, Fragment } from 'react';
// import { withOktaAuth } from '@okta/okta-react';
import { Route, Redirect, Link } from 'react-router-dom';
import {
    withStyles,
    Typography,
    Fab,
    IconButton,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core';
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import moment from 'moment';
import { find, orderBy } from 'lodash';
import { compose } from 'recompose';

import SearchBrowser from 'components/SearchBrowser';
// import ResourceAdder from 'resourceAdder/ResourceAdder';
import ErrorSnackbar from 'components/ErrorSnackbar';

// import WordLimit from 'react-word-limit'; 
import trimCharacters from 'trim-characters';

const styles = theme => ({
    resources: {
        marginTop: theme.spacing(2),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
    },
});

const API = process.env.REACT_APP_API;







class ResourcesFrame extends Component {
    state = {
        loading: true,
        error: null,
    };


    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <div className="container-fluid py-4 h-full">
                    <SearchBrowser/>
                </div>
            </Fragment>
        );
    }

}


export default compose(
    withStyles(styles),
)(ResourcesFrame);