import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { withRouter, Route, Redirect, Link } from 'react-router-dom';
// import { Form, Field } from 'react-final-form';
// import { find, orderBy } from 'lodash';



const TagSet = ({ metadataSet, parentKey = 0 }) => {

    if (metadataSet.length > 0) {

        return (

            <Fragment>

                {
                    metadataSet.map((singleTag, i) => {
                        console.log(singleTag);
                        return (<span className={`metadata-value metadata-tag`} key={`tag-${parentKey + '-' + singleTag.raw_tag}`}><Link to={`/tags/${singleTag.tag}`}>{singleTag.raw_tag}</Link></span>);
                    })
                }

            </Fragment>

        );

    } else {
        return null;
    }

}



export default compose()(TagSet);