import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

class SearchBarSuggestions extends React.Component {

    // state = {
    //     suggestions: this.props
    // };



    // searchSuggestion = ({resTitle, resType, resAction, resTarget}) => {

    //     return (
    //         <Fragment>
    //         </Fragment>
    //     );

    // }



    renderSearchSuggestion = () => {

        const { suggestions } = this.props;

        var outputBuffer = [];

        console.log('search suggestions:');
        console.log(suggestions);

        if (suggestions.length > 0) {
            suggestions.forEach(singleSuggestion => {
                console.log(singleSuggestion)
                outputBuffer.push( 

                        <a href={`${process.env.REACT_APP_API_URL}/resources/${singleSuggestion.id}`} id={`search-suggestion-${singleSuggestion.id}`} className={`hero-search-suggestion search-suggestion`}>

                            <div className={`suggestion-glyph`}>
                            </div>

                            <span className={`suggestion-title`}>{singleSuggestion.title}</span><span className={`suggestion-type`}> in {singleSuggestion.type.label}</span>


                        </a> 

                    )

                {/*this.searchSuggestion('title', 'type', 'action', 'target')*/}
            })
        }


        return (

            <Fragment>

                { outputBuffer }

            </Fragment>

        );
    }



    renderSearchSuggestions = () => {

        const { suggestions } = this.props;

        if (suggestions.length > 0) {


            return (

                <Fragment>

                    <div className={`mx-auto suggestion-container w-100`}>

                            { this.renderSearchSuggestion() }

                    </div>

                </Fragment>

            );
        }
    }



    render() {

        return (
            <Fragment>
                { this.renderSearchSuggestions() }
            </Fragment>

        );

    }
}

export default SearchBarSuggestions;