import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form } from 'react-bootstrap';

class SearchFilterDate extends React.Component {

    state = {
        min:            this.props || 1900,
        max:            this.props || 2023,
        initialValue:   this.props || 1990,
        value:          this.props || 1900,
    };



    onChange = (value) => {



    }



    processChange = (value) => {

        

        console.log('FORM SLIDER:');
        console.log(value);

        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.preventDefault();
        //     event.stopPropagation();
        // }

        // setValidated(true);

    };



    render() {

        const onChange = this.props.onChange || this.processChange;

        const changeHandler = (event) => {

            event.preventDefault();
            event.stopPropagation();
            
            this.setState ({
                value: event.target.value
            });

            onChange(event.target.value);
        }

        return (

            <Fragment>
                <Form>
                    <Form.Group controlId="filterPublicationDate">
                        <Form.Label>Publication Date</Form.Label>
                        <Form.Control type="range" custom onChange={changeHandler}/>
                    </Form.Group>
                </Form>
            </Fragment>

        );

    }
}

export default SearchFilterDate;