// import React, {Component, Fragment } from 'react';
import React, { Fragment } from 'react';
// import { Link } from 'react-router-dom';
// import {
//     AppBar,
//     Button,
//     Toolbar,
//     Typography,
//     withStyles,
// } from '@material-ui/core';

// const styles = {
//     flex: {
//         flex: 1,
//     },
// };



const ComponentFrame = ({ classes }) => (

    <Fragment>

        <div className={`component-frame`}>
            <h4>Component Frame</h4>
            
        </div>

    </Fragment>
);

export default ComponentFrame;
