import React, { Component, Fragment } from 'react';
// import {
//     withStyles,
//     Typography,
//     Fab,
//     IconButton,
//     Paper,
//     List,
//     ListItem,
//     ListItemText,
//     ListItemSecondaryAction,
// } from '@material-ui/core';
// import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
// import moment from 'moment';
// import { find, orderBy } from 'lodash';
import { compose } from 'recompose';

import ResourceBrowser from 'components/ResourceBrowser';
// import ResourceAdder from 'resourceAdder/ResourceAdder';
// import ErrorSnackbar from 'components/ErrorSnackbar';

// import WordLimit from 'react-word-limit'; 
// import trimCharacters from 'trim-characters';

// const API = process.env.REACT_APP_API;







class ResourcesFrame extends Component {
    state = {
        loading: true,
        error: null,
    };


    render() {
        // const { classes } = this.props;

        return (
            <Fragment>
                <div className="container-fluid py-4 h-full">
                    <ResourceBrowser/>
                </div>
            </Fragment>
        );
    }

}


export default compose()(ResourcesFrame);