import React, { createContext, useContext, useReducer }                 from 'react';
import { useHistory }                                                   from 'react-router-dom';
import Axios                                                            from 'axios';
// import { connect } from "react-redux";
// import { checkAuth } from "../actions/index";
// import Login from "../Login";

// import { useContext } from 'react';
// import AuthContext from 'App';


const AuthContext           = createContext();
const AuthDispatchContext   = createContext();  //      <---- what does this actually do?





function reducer(currentState, newState) {

    if (currentState.authUser != newState.authUser) {
        setLocalStorage("hcuser", newState.authUser);
    }

    return { ...currentState, ...newState };

}





function useAuthState() {

    /*/
    //
    //      Neatly serve up the context
    //
    /*/ 

    // validateCurrentSession();
    
    const context = useContext(AuthContext);

    // if (!context) {
    //     throw new Error('')
    // }

    return context;

}




function useAuthDispatch() {

    const context = useContext(AuthDispatchContext);

    return context;

}





const initialState = {

    /*/
    //
    //      NOTE: This should probably be much more cleanly executed (perhaps using an initial session verificatio nrequest to the server + local storage in session storage)
    //
    /*/ 

    authStatus:         "idle",
    authUser:           getLocalStorage("hcuser",       false),
    // authUser:           AuthMgr(),
    authSession:        getLocalStorage("hcsession",    false),
    authError:          false

}





// function currentState() {

//     validateCurrentSession();

//     // const [state, dispatch] = useReducer(reducer, initialState);

//     // authStatus:         "idle",
//     // authUser:           getLocalStorage("hcuser",       false),
//     // // authUser:           AuthMgr(),
//     // authSession:        getLocalStorage("hcsession",    false),
//     // authError:          false

//     return 

// }





// const sessionState = {

//     console.log(validateSession());

// }





/*/
//
//      https://stackoverflow.com/questions/62504525/persistence-with-localstorage-with-usestate-and-usecontext-react-hooks
//
/*/ 

function setLocalStorage(key, value) {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        // catch possible errors:
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    }
}

function getLocalStorage(key, initialValue) {
    try {
        const value = window.localStorage.getItem(key);
        return value ? JSON.parse(value) : initialValue;
    } catch (e) {
        // if error, return initial value
        return initialValue;
    }
}





function AuthProvider(props) {
    
    const [state, dispatch] = useReducer(reducer, initialState);    //      <---- here, dispatch serves as the setter

    return (
        
        <AuthContext.Provider value={state}>
            <AuthDispatchContext.Provider value={dispatch}>
                {props.children}
            </AuthDispatchContext.Provider>
        </AuthContext.Provider>
    );

}





async function validateSession() {



    const endpoint = '/api/session/authenticate';

    const authResponse = Axios({
        method: "POST",
        data: {
            test: "yo"
        },
        withCredentials: true,
        // credentials: 'same-origin',
        crossDomain: true,
        url: `${endpoint}`
    }).then((res) => {

        console.log('auth response:');
        console.log(res);

        // return res;

        // var responseObject = {
        //     authUser:       null,
        //     authError:      null
        // }

        var responseObject = null;

        if (res.hasOwnProperty('data')) {
            if (res.data.hasOwnProperty('id')) {


                if (res.data.id) {

                    //  Login successful

                    console.log('Session valid.');
                    console.log(res.data);

                    // responseObject.user = res.data.id;
                    responseObject = res.data.id;

                    console.log(responseObject);



                }


                // if (res.data.error) {
                //     setError({
                //         type: res.data.error.type || null,
                //         code: res.data.error.code || null,
                //         message: res.data.error.message | null
                //     });
                // }
               

                // if (res.data.error) {
                //     responseObject.error = res.data.error.message
                // }
            }

                // if (res.data.data.hasOwnProperty('uid')) {

                //     if (res.data.data.uid) {

                //         //  Login successful

                //         responseObject.user = res.data.data;


                //     }
                // }
            // }
        }

        return responseObject;

    });

    return authResponse;

}





async function validateCurrentSession(dispatch) {

    const endpoint = '/api/session/authenticate';

    console.log(`Attempting to validate current session ${process.env.REACT_APP_API_URL}${endpoint}`);

    dispatch({ 
        authStatus: "pending" 
    });

    const authResponse = await Axios({
        method: "POST",
        data: {
            test: "yo"
        },
        withCredentials: true,
        // credentials: 'same-origin',
        crossDomain: true,
        url: `${endpoint}`
    }).then((res) => {

        console.log('auth response:');
        console.log(res);

        // return res;

        // var responseObject = {
        //     authUser:       null,
        //     authError:      null
        // }

        var responseObject = null;

        if (res.hasOwnProperty('data')) {
            if (res.data.hasOwnProperty('id')) {


                if (res.data.id) {

                    //  Login successful

                    console.log('Session valid.');
                    console.log(res.data);

                    // responseObject.user = res.data.id;
                    responseObject = res.data.id;

                    console.log(responseObject);



                }


                // if (res.data.error) {
                //     setError({
                //         type: res.data.error.type || null,
                //         code: res.data.error.code || null,
                //         message: res.data.error.message | null
                //     });
                // }
               

                // if (res.data.error) {
                //     responseObject.error = res.data.error.message
                // }
            }

                // if (res.data.data.hasOwnProperty('uid')) {

                //     if (res.data.data.uid) {

                //         //  Login successful

                //         responseObject.user = res.data.data;


                //     }
                // }
            // }
        }

        // return responseObject;
        dispatch({ 
            authStatus: "idle",
            authUser: responseObject 
        });

    });

    // return authResponse;
    // dispatch({ 
    //     authStatus: "idle",
    //     authUser: responseObject 
    // });

}





function attemptLogin(credentials, dispatch) {

    const endpoint = '/api/users/login';

    console.log(`Attempting to log in via ${process.env.REACT_APP_API_URL}${endpoint}`);

    dispatch({ 
        authStatus: "pending" 
    });

    Axios({
        method: "POST",
        data: {
            username: credentials.username,     //loginUsername,
            password: credentials.password      //loginPassword,
        },
        withCredentials: true,
        // credentials: 'same-origin',      //      encouraged..?
        crossDomain: true,                  //      needed..?
        url: `${process.env.REACT_APP_API_URL}${endpoint}`
    }).then((res) => {
        console.log('login attempted. response:');
        console.log(res);

        if (res.hasOwnProperty('data')) {
            if (res.data.hasOwnProperty('error')) {
                // if (res.data.error) {
                //     setError({
                //         type: res.data.error.type || null,
                //         code: res.data.error.code || null,
                //         message: res.data.error.message | null
                //     });
                // }




                if (res.data.error) {

                    dispatch({ 
                        authStatus: "rejected", 
                        authError:  res.data.error.message
                    });

                    // setErrorMessage(res.data.error.message)
                }
            }

            if (res.data.hasOwnProperty('data')) {
                if (res.data.data.hasOwnProperty('uid')) {

                    if (res.data.data.uid) {

                        //  Login successful

                        dispatch({
                            authStatus: "resolved",
                            authUser: res.data.data.uid,
                            authError: null
                        });

                    }
                }
            }
        }

        console.log('logged in user:');
        console.log('...');
    });


        /*/
        //
        //      DEBUG: Verify that logged in user took hold
        //
        /*/ 

        







    
    // const result = async () => {

    //     try {
        
    //         dispatch({ 
    //             authStatus: "pending" 
    //         });

    //         // const result = await getUser(user);

    //         console.log('AuthMgr authenticating...');

    //         /*/
    //         //
    //         //      NEED TO CLEAN THIS UP TO INCLUDE BETTER CONTINGENCY HANDLING, MORE ROBUST USER DATA, ETC.
    //         //
    //         /*/ 

    //         const isSessionValid = await validateSession().then((sessionValid) => {

    //             if (sessionValid.user) {
                
    //                 dispatch({
    //                     authStatus: "resolved",
    //                     authUser: sessionValid.user,
    //                     authError: null
    //                 });

    //                 return true;

    //             } else {

    //                 dispatch({ 
    //                     authStatus: "rejected", 
    //                     authError:  sessionValid.error
    //                 });

    //                 return false

    //             }

    //         });

    //         return isSessionValid;

        
    //     } catch (error) {
            
    //         dispatch({ 
    //             authStatus: "rejected", 
    //             error 
    //         });

    //         return false;

    //     }
    // }

    // return result;

}





function DoLogout(dispatch) {

    let history = useHistory();

    dispatch(initialState);
    history.push("/");

}





export { AuthProvider, useAuthState, useAuthDispatch, attemptLogin, validateCurrentSession, DoLogout };





const AuthMgr = {

    isAuthenticated:        false,
    authenticate: function() {

        console.log('AuthMgr authenticating...');

        fetch('POST', '/api/session/authenticate', {test: "yo"}).then(authResponse => {
        
            console.log('auth response:');
            console.log(authResponse);

            // this.setState({ 
            //     loading: false, 
            //     authenticated: (authResponse) || false 
            // });

            if (authResponse === true) {
                return true;
            } else {
                return false;
            }

        })
    },
    // signOut() {
    //     //  TBD
    //     return null;
    // }

}

export default AuthMgr;