import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

class SearchFilterResourceTypes extends React.Component {

    state = {
        resourceTypes:                  [],
        resourceTypeCounts:             [],
        activeResourceTypes:            []
    };



    componentDidMount() {

        // let resourceTypes = this.props.resourceTypes || this.state.resourceTypes;
        // let activeResourceTypes = this.props.activeResourceTypes || this.state.activeResourceTypes;

        
    };



    componentWillReceiveProps(nextProps) {

        var superResourceTypes = [];

        if (nextProps.resourceTypes && nextProps.resourceTypeCounts) {

            for (var i = 0; i < nextProps.resourceTypes.length; i++) {

                var alreadyAdded = false;
                var singleType = nextProps.resourceTypes[i];

                for (var j = 0; j < nextProps.resourceTypeCounts.length; j++) {
                    if (singleType.alias == nextProps.resourceTypeCounts[j].alias && !alreadyAdded) {

                        singleType['count'] = nextProps.resourceTypeCounts[j].count;
                        superResourceTypes.push(singleType);
                        alreadyAdded = true;
                    }
                }

                
                if (!alreadyAdded) {
                    singleType['count'] = 0;
                    superResourceTypes.push(singleType);
                    alreadyAdded = true;
                }
    
            }

        } else {
            var superResourceTypes = this.state.resourceTypes;

            for (var i = 0; i < superResourceTypes.length; i++) {
                superResourceTypes[i]['count'] = 0;
            }
        }


        this.setState({
            resourceTypes:          superResourceTypes              ||  this.state.resourceTypes,
            resourceTypeCounts:     nextProps.resourceTypeCounts    ||  this.state.resourceTypeCounts,
            activeResourceTypes:    nextProps.activeResourceTypes   ||  this.state.activeResourceTypes
        });
    }



    onChange = (value) => {



    }



    processChange = (value) => {

        // console.log(value);

    }



    removeActiveType = (droppedType) => {
        
        if (this.state.activeResourceTypes.length > 0) {

            let newActiveAliases = [];
            let newActiveTypes = [];

            this.state.activeResourceTypes.forEach((activeType) => {
                if (activeType.alias !== droppedType) {
                    newActiveAliases.push(activeType.alias);
                }
            }, this);

            newActiveAliases.forEach((alias) => {
                this.state.resourceTypes.forEach((resourceType) => {
                    if (resourceType.alias === alias) {
                        newActiveTypes.push(resourceType.alias);
                    }
                })
            })
            
            // this.setState({
            //     activeResourceTypes: newActiveTypes
            // });

            this.props.onChange(newActiveTypes);

        }
    }



    addActiveType = (addedType) => {
        
        if (this.state.resourceTypes.length > 0) {

            let newActiveAliases = [];
            let newActiveTypes = [];
            let alreadyAdded = false;

            if (this.state.activeResourceTypes.length > 0) {

                this.state.activeResourceTypes.forEach((activeType) => {
                    if (activeType.alias === addedType) {
                        alreadyAdded = true;
                    } else {
                        
                    }

                    newActiveAliases.push(activeType.alias);

                }, this);

            }

            if (!alreadyAdded) {
             newActiveAliases.push(addedType);
                this.state.resourceTypes.forEach((resourceType) => {
                    newActiveAliases.forEach((activeAlias) => {
                        if (resourceType.alias === activeAlias) {
                            newActiveTypes.push(resourceType.alias);
                        }
                    })
                })
            }

            // this.setState({
            //     activeResourceTypes: newActiveTypes
            // });

            this.props.onChange(newActiveTypes);
        }

    }


    buttonHandler = (event) => {

        event.preventDefault();
        event.stopPropagation();
        
        // this.setState ({
        //     value: event.target.value
        // });

        const activeTypes = this.state.activeResourceTypes;

        const onChange = this.props.onChange || this.processChange;

        let updatedSet = [];

        if (event.target.attributes.active) {

            /*/
            //
            //      Deactivate
            //
            /*/ 

            activeTypes.forEach(activeType => {
                if (activeType != event.target.value) {
                    updatedSet.push(activeType);
                }
            });


            // event.target.removeAttribute('active');
            // removeActiveType(event.target.value);
        } else {
            
            /*/
            //
            //      Activate
            //
            /*/ 

            let alreadyExists = false;

            activeTypes.forEach(activeType => {
                if (activeType == event.target.value) {
                    alreadyExists = true;
                } else {
                    updatedSet.push(activeType);
                }
            });

            if (!alreadyExists) {
                updatedSet.push(event.target.value);
            }

            // event.target.setAttribute('active', true);
            // addActiveType(event.target.value);
        }

        onChange(updatedSet);

    }



    resourceTypeFilter = (resourceTypes, activeResourceTypes, onChange) => {
        var resourceTypesCount = resourceTypes.length;
        var outputBuffer = [];
        var typeState = {
            active : ""
        };

        if (resourceTypesCount > 0) {

            resourceTypes.map((resourceType, i) => {
                // outputBuffer.push(<ToggleButton className={`filter-resource-type`} key={resourceType.id} value={resourceType.alias}>{resourceType.type}</ToggleButton>)
                typeState.active = '';

                if (activeResourceTypes.length > 0) {
                    activeResourceTypes.map((activeType, j) => {
                        if (activeType === resourceType.alias) {
                            typeState.active = 'active';
                        }
                    });
                }

                if (typeState.active) {
                    outputBuffer.push(<Button className={`filter-resource-type`} onClick={onChange} variant="outline-primary" size="sm" active key={resourceType.id} value={resourceType.alias}>{resourceType.type}<span id={`filter-resource-type-${resourceType.alias}-count`} className={`filter-resource-type-count`}>{resourceType.count}</span></Button>)
                } else {
                    outputBuffer.push(<Button className={`filter-resource-type`} onClick={onChange} variant="outline-primary" size="sm" key={resourceType.id} value={resourceType.alias}>{resourceType.type}<span id={`filter-resource-type-${resourceType.alias}-count`} className={`filter-resource-type-count`}>{resourceType.count}</span></Button>)

                }
            });

        }
        return (outputBuffer);
    }



    render() {

        

        const classes = this.props.className;

        const onChange = this.props.onChange || this.processChange;

        const addActiveType = this.addActiveType;
        const removeActiveType = this.removeActiveType;

        const changeHandler = (event) => {

            event.preventDefault();
            event.stopPropagation();
            
            // this.setState ({
            //     value: event.target.value
            // });

            onChange(event.target.value);
        }

        const buttonHandler = this.buttonHandler;

        const resourceTypes = this.state.resourceTypes;
        const activeResourceTypes = this.state.activeResourceTypes;

        const resourceTypeFilters = this.resourceTypeFilter(resourceTypes, activeResourceTypes, buttonHandler);


        


        //  = () => {
        //     var resourceTypes = this.state.resourceTypes;
        //     var resourceTypesCount = this.state.resourceTypes.length;
        //     var outputBuffer = [];
        //     if (resourceTypesCount > 0) {
        //         outputBuffer.push(<ToggleButtonGroup type="checkbox" defaultValue={[1, 3]} className="mb-2">);

        //         resourceTypes.forEach(resourceType => {
        //             outputBuffer.push(<ToggleButton key={resourceType.id} value={resourceType.alias}>{resourceType.type}</ToggleButton>)
        //         });
        //         outputBuffer.push(</ToggleButtonGroup>);
        //     }
        //     return outputBuffer;
        // }

            
        if (this.state.resourceTypes.length < 1) {
            return null
        } else {

            return (

                        <Fragment>
                            <Form className={`${classes}`} onChange={changeHandler}>
                                <Form.Group controlId="filterResourceTypes">
                                    <Form.Label>Resource Types</Form.Label>
                                    {/*<ToggleButtonGroup className={`filter-resource-types mb-2`} type="checkbox" defaultValue={[1, 3]}>*/}
                                    <div className={`filter-resource-types mb-2`}>

                                        {resourceTypeFilters}

                                    </div>
                                    {/*</ToggleButtonGroup>*/}
                                </Form.Group>
                            </Form>
                        </Fragment>

            );
        }

    }
}

export default SearchFilterResourceTypes;