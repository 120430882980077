import { createStore, applyMiddleware } 		from 'redux';
import thunk 									from 'redux-thunk';
import { configureStore } 						from '@reduxjs/toolkit';
import rootReducer 								from './js/reducers/index';
// import counterReducer 		from './features/counter/counterSlice';

const store = createStore(rootReducer, applyMiddleware(thunk));

// export default configureStore({
// 	reducer: {
// 		counter: counterReducer
// 	},
// });

export default store;