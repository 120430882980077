import React, { Component, Fragment } from 'react';
import { withOktaAuth } from '@okta/okta-react';
// import {
//     withStyles,
//     Card,
//     CardContent,
//     CardActions,
//     Modal,
//     Button,
//     TextField,
// } from '@material-ui/core';
import { compose } from 'recompose';
// import { withRouter, Route, Redirect, Link } from 'react-router-dom';
// import { Form, Field } from 'react-final-form';
// import { find, orderBy } from 'lodash';
import TagSet           from 'components/MetadataBlock/TagSet/TagSet';



const MetadataBlock = ({ metadataSetName, metadataSet = [] }) => {

    if (metadataSet.length > 0) {

        return (

            <Fragment>
                <div className={'metadata-set'}>
                    <div className={`metadata-key-block`}>
                        <span className={`metadata-key`}>{metadataSetName}</span>
                    </div>
                    <div className={`metadata-value-block`}>
                        <TagSet metadataSet={metadataSet}/>
                    </div>
                </div>
            </Fragment>
        )

    } else {
        return null;
    }

}



export default compose()(MetadataBlock);