import React from 'react';
import { Link } from 'react-router-dom';
import Container        from 'react-bootstrap/Container';
import Row              from 'react-bootstrap/Row';
import Col              from 'react-bootstrap/Col';
import Nav              from 'react-bootstrap/Nav';
import {
    AppBar,
    Button,
    Toolbar,
} from '@material-ui/core';
import AuthActions from './AuthActions';

const siteLogo = require('../img/hc_logo_white_trans.png').default;

const AppHeader = () => {


    return ( 

        <Container fluid className={`global-header-set px-0`}>

            <Container fluid className={`global-header-main-outer bg-secondary gx-5`}>

                    <Container fluid className={`global-header-main py-2 gx-5`}>
                        <Row className={`h-100`}>
                            <Col xs={4} sm={4} md={3} lg={2} xl={2} className={`col-2 d-flex h-100`}>
                                <h1 className={`site-logo`}>
                                    <a href="/">
                                        <img src={siteLogo} alt={`HABRI Central`} width="100%"/>
                                    </a>
                                </h1>
                            </Col>
                        </Row>
                    </Container>

            </Container>


            <Container fluid className={`global-header-nav-outer gx-5`}>

                <Container fluid className={`global-header-nav py-2 gx-5`}>

                    <Row className={`h-100`}>
                        <Col className={`col-12 d-flex h-100 justify-content-between`}>

                            <Nav defaultActiveKey="/home" as="ul" className={`global-header-nav-main`}>
                                
                                <Nav.Item as="li">
                                    <Nav.Link className={`link-light`} href="/">Home</Nav.Link>
                                </Nav.Item>
                                
                                <Nav.Item as="li">
                                    <Nav.Link className={`link-light`} href="/tags">Tags</Nav.Link>
                                </Nav.Item>
                                
                                <Nav.Item as="li">
                                    <Nav.Link className={`link-light`} href="/resources">Library</Nav.Link>
                                </Nav.Item>
                                
                                <Nav.Item as="li">
                                    <Nav.Link className={`link-light`} href="/community">Community</Nav.Link>
                                </Nav.Item>

                            </Nav>


                            <AuthActions />

                        </Col>
                    </Row>

                </Container>

            </Container>

        </Container>

    );
};

export default (AppHeader);
