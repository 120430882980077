import { 
    ADD_ARTICLE,
    AUTHENTICATED,
    NOT_AUTHENTICATED 
}                           from '../constants/action-types';
import authorization        from './authReducers';
import { combineReducers }  from 'redux';

const initialState = {
    articles: []
};

// function rootReducer(state = initialState, action) {

//     // if (action.type === ADD_ARTICLE) {
//     //     return Object.assign({}, state, {
//     //         articles: state.articles.concat(action.payload)
//     //     })
//     // }


//     return state;
// };

let reducer = (state = 0, action) => {
    switch (action.type) {
        case 'INCREASE': 
            return state+1;
        case 'DECREASE': 
            return state-1;
        default: 
            return state;
        break;
    }
};


const rootReducer = combineReducers({
    auth: authorization
});

export default rootReducer;