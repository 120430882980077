import React, {Component, Fragment, UseState } from 'react';
import { Link } from 'react-router-dom';
import {
    AppBar,
    Button,
    Toolbar,
    Typography,
    withStyles,
} from '@material-ui/core';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import AuthActions from './AuthActions';


const styles = {
    flex: {
        flex: 1,
    },
};



const AdminHeader = (props) => {

    const handleNavLinkClick = (event, currentView) => {
        props.parentTie(event, currentView);
    };

    return(


        <Fragment>

            <Navbar expand="lg" className={`main-admin-navbar`} id={`main-admin-navbar`}>
                <Container fluid>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">



                            <NavDropdown title="Site" id="admin-nav-dropdown-site">

                                <NavDropdown.Item href="#action/1.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/1.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/1.3">Something</NavDropdown.Item>
                                
                                <NavDropdown.Divider />
                                
                                <NavDropdown.Item href="#action/1.4">Separated link </NavDropdown.Item>

                            </NavDropdown>




                            <NavDropdown title="Users" id="admin-nav-dropdown-users">

                                <NavDropdown.Item href="#action/2.1">Action</NavDropdown.Item>

                            </NavDropdown>









                            <NavDropdown title="Menus" id="admin-nav-dropdown-menus">

                                <NavDropdown.Item href="#action/2.1">Action</NavDropdown.Item>

                            </NavDropdown>









                            <NavDropdown title="Content" id="admin-nav-dropdown-content">

                                <NavDropdown.Item href="#action/2.1">Action</NavDropdown.Item>

                            </NavDropdown>









                            <NavDropdown title="Components" id="admin-nav-dropdown-components">

                                <NavDropdown.Item onClick={(event) => handleNavLinkClick(event, 'resources')}>Resources</NavDropdown.Item>

                            </NavDropdown>









                            <NavDropdown title="Extensions" id="admin-nav-dropdown-extensions">

                                <NavDropdown.Item href="#action/2.1">Action</NavDropdown.Item>

                            </NavDropdown>









                            <NavDropdown title="Help" id="admin-nav-dropdown-help">

                                <NavDropdown.Item href="#action/2.1">Action</NavDropdown.Item>

                            </NavDropdown>



                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </Fragment>

    );
}

export default withStyles(styles)(AdminHeader);
