import React, { Fragment, useState, useEffect }         from 'react';
import ReactDOM                                         from 'react-dom';
import { withRouter, useHistory }                       from 'react-router-dom';
// import { Form, Field } from 'react-final-form';
import MetadataBlock                                    from 'components/MetadataBlock/MetadataBlock';
import TagSet                                           from 'components/MetadataBlock/TagSet/TagSet';
import BadgeSet                                         from 'components/MetadataBlock/BadgeSet/BadgeSet';
import ImportedResourceDestination                      from 'components/Admin/Components/Resources/AdminResourcesImportDestination';
import ReactHtmlParser                                  from 'react-html-parser';
import Tab                                              from 'react-bootstrap/Tab';
import Tabs                                             from 'react-bootstrap/Tabs';
import Button                                           from 'react-bootstrap/Button';
import ButtonGroup                                      from 'react-bootstrap/ButtonGroup';
import ButtonToolbar                                    from 'react-bootstrap/ButtonToolbar';
import validURL                                         from 'valid-url';
import Axios                                            from 'axios';





// export const Component = withRouter(({ history, location }) => {

// })




class ImportedResourcePreview extends React.Component {



    state = {
        loading:                true,
        error:                  null,
        resource:               null, 
        id:                     this.props.id, 
        elaborated:             false,                                       // The resource metadata that will eventually be used to create the proper resource
        importedResource:       this.props.importedResource,          // The resource metadata as imported via XML
        elaboratedResource:     false,
        sourceResource:         this.props.importedResource,
        destinationResource:    this.props.importedResource,
        crossRefResource:       false,                                // The resource metadata as fetched from CrossRef
        potentialDuplicates:    [],                                // A set of existing resources that may be duplicates of the one currently being submitted/evaluated
    }





    componentDidMount() {

        console.log('import tile did mount...');
        console.log(this.state);

        // const onChange = this.props.onChange || (() => {});
        // onChange(this.preprocessSearchQuery(this.state.rawSearchString));

        if (this.state.elaborated !== true) {
            // this.elaborateResource(this.state.importedResource);

            this.fetch('post', '/api/resources/elaborate', this.state.importedResource)
                .then(updatedResource => {

                    this.setState({ 
                        elaborated:                 true, 
                        elaboratedResource:         updatedResource || false, 
                        sourceResource:             updatedResource || false, 
                        destinationResource:        updatedResource || false,
                    });
                })
        }

    } 





    shouldComponentUpdate(nextProps, nextState) {

        let willUpdate = false;

        if (nextState.sourceResource !== this.state.sourceResource) {
            willUpdate = true;
        }



        // if (nextState.updateRequested === true) {
        //     willUpdate true;
        // }

        if (nextState.destinationResource.metadata !== this.state.destinationResource.metadata) {
            willUpdate = true;
        }

        return willUpdate;
    }





    componentDidUpdate() {

        /*/
        //
        //      Perform search with updated parameters
        //
        /*/ 

        console.log('resource preview updated.');
        console.log(this.state.sourceResource);
        console.log(this.state.destinationResource);

        // this.forceUpdate();

        // if (this.state.destinationUpdateRequested) {
        //     // this.getSearchSuggestions(this.state.filterPackage)
        //     this.forceUpdate();
        // }
    }





    // processElaboratedRecord() {

    //     const [elaboratedResource, setElaboratedResource] = useState(null);

    //     const elaboratedResource = async() => {

    //     }

    // }





    // async fetch(method, endpoint, body) {
    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
    //             method,
    //             body: body && JSON.stringify(body),
    //             headers: {
    //                 'content-type': 'application/json',
    //                 accept: 'application/json',
    //                 // authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
    //             },
    //         });

    //         console.log('fetch body:');
    //         console.log(JSON.stringify(body));

    //         return await response.json();
    //     } catch (error) {
    //         console.error(error);

    //         this.setState({ error });
    //     }
    // }



    async fetch(method, endpoint, body) {

    //const register = () => {

        let self = this;

        let elaboratedResource = body;


        return Axios({
            method: "POST",
            data: body,
            withCredentials: true,
            crossDomain: false,
            url: "https://habricentral.local:3001/api/resources/elaborate"
        }).then((res) => {

            let crossRefResource = false;
            
            console.log('elaborate response:');
            console.log(res);

            if (res.hasOwnProperty('data') && res.data.hasOwnProperty('payload') && res.data.payload.hasOwnProperty('resource') && res.data.payload.resource.hasOwnProperty('title')) {
                
                console.log('elaborate title:');
                console.log(res.data.payload.resource.title);
                console.log(res.data.payload.resource); 

                elaboratedResource = res.data.payload.resource;

                if (res.data.payload.resource.hasOwnProperty('crossRefResource') && res.data.payload.resource.crossRefResource.hasOwnProperty('title')) {
                    console.log('CrossRef record found!');
                    console.log(res.data.payload.resource.crossRefResource);

                    crossRefResource = res.data.payload.resource.crossRefResource;
                } else {
                    console.log('no crossRef metadata available');
                }
            }

            return elaboratedResource;



        }).catch( error => {
            console.error(error);
        });

    }





    // async getResourceTypes() {
    //     this.setState({ 
    //         loading: false, 
    //         resourceTypes: (await this.fetch('get', '/api/resource-types')) || [] 
    //     });
    // }


    // elaborateResource(protoResource) {

    //     const elaboratingResource = this.fetch('post', '/api/resources/elaborate', protoResource);

    //     console.log('elaborated resource (y):');
    //     console.log(elaboratedResource);

    //     if (elaboratingResource) {

    //         setElaboratedResource(elaboratedResource);
    //         setElaborated(true);
    //         setSourceResource(elaboratedResource);
    //         setDestinationResource(elaboratedResource);

    //         if (elaboratedResource.hasOwnProperty('crossRefResource')) {
    //             setCrossRefResource(elaboratedResource.crossRefResource);
    //         }


    //         // this.setState({ 
    //         //     elaborated:                 true, 
    //         //     elaboratedResource:         elaboratedResource || false, 
    //         //     sourceResource:             elaboratedResource || false, 
    //         //     destinationResource:        elaboratedResource || false,
    //         // });
    //     }
    // }





    // elaborateResourceViaEffect(protoResource) {

    //     console.log('elaborating resource..!');
    //     console.log(protoResource);


    //     useEffect(() => {

    //         const [elaboratedResource, setElaboratedResource]   = useState(this.state.elaborateResource);
    //         const [elaborated, setElaborated]                   = useState(this.state.elaborated);
    //         const [sourceResource, setSourceResource]           = useState(this.state.sourceResource);
    //         const [destinationResource, setDestinationResource] = useState(this.state.destinationResource);
    //         const [crossRefResource, setCrossRefResource]       = useState(this.state.crossRefResource);

    //         const theResource = async() => {

    //             const elaboratingResource = await this.fetch('post', '/api/resources/elaborate', protoResource);

    //             console.log('elaborated resource (y):');
    //             console.log(elaboratedResource);

    //             if (elaboratingResource) {

    //                 setElaboratedResource(elaboratedResource);
    //                 setElaborated(true);
    //                 setSourceResource(elaboratedResource);
    //                 setDestinationResource(elaboratedResource);

    //                 if (elaboratedResource.hasOwnProperty('crossRefResource')) {
    //                     setCrossRefResource(elaboratedResource.crossRefResource);
    //                 }


    //                 // this.setState({ 
    //                 //     elaborated:                 true, 
    //                 //     elaboratedResource:         elaboratedResource || false, 
    //                 //     sourceResource:             elaboratedResource || false, 
    //                 //     destinationResource:        elaboratedResource || false,
    //                 // });
    //             }
    //         }

    //         elaboratingResource();
    //     })
    // }



    // async elaborateResource(protoResource) {

    //     await this.fetch('post', '/api/resources/elaborate', protoResource);

    // }



    validateDOI = (doi) => {

        console.log("Testing DOI:");
        console.log(doi);

        const doiPattern = /^\b(10[.][0-9]{4,}(?:[.][0-9]+)*\/(?:(?!["&\'<>])\S)+)\b/;
        return doiPattern.test(doi);

    }



    preRenderSubAuthors = (resource) => {

        let outputBuffer = [];

        let useAmpersand    = false;
        let useComma        = false;

        let properAuthors   = [];
        let contributors    = [];

        if ('authors' in resource) {
            resource.authors.forEach(singleContributor => {
                switch(singleContributor.role) {

                    case 'submitter':
                        contributors.push(singleContributor);
                    break;

                    default:
                        properAuthors.push(singleContributor);
                    break;
                }
            })
        }


        if (properAuthors.length > 1) {

            //  Prepare to use an ampersand
            useAmpersand    = true;

        }

        if (properAuthors.length > 2) {

            //  Prepare to use a comma
            useComma        = true;

        } 



        // resource.authors.forEach(singleAuthor {
        for (let i = 0; i < properAuthors.length; i++) {

            let author = properAuthors[i];
        
            let nameSeparator   = '';

            if (properAuthors.length > 2) {

                if (i < properAuthors.length - 1) {
                    nameSeparator += ", ";
                }

                if (i == properAuthors.length - 2) {
                    nameSeparator += ' & ';
                }

            } else {
                if (i < properAuthors.length - 1) {
                    nameSeparator += " & ";
                }
            }

                

            // }


            if (author.name && author.name.length > 0) {

                outputBuffer.push(
                    <span className={`single-author-name`} key={author.id}>{author.name}</span>
                );

                if (nameSeparator.length > 0) {
                    outputBuffer.push(
                        <span className={`author-name-separator`}>{nameSeparator}</span>
                    )
                }

            }

        }

        return outputBuffer;

    }



    preRenderYear = (resource) => {

        let outputBuffer = [];

        let dateFieldLabel = '';

        let yearValue = false;

        
        /*/
        //
        //      Attempt to extract standardized generic publication date
        //
        /*/ 

        if ('metadataStructure' in resource && 'fields' in resource.metadataStructure) {
            resource.metadataStructure.fields.forEach( metaField => {

                if ('label' in metaField) {
                    if (metaField.label == 'Date') {
                        dateFieldLabel = metaField.name;
                    }
                }

            });
        }

        // if ('metadata' in resource && 'yearofpublication' in resource.metadata && 'value' in resource.metadata.yearofpublication && 'year' in resource.metadata.yearofpublication.value) {
        // if ('metadata' in resource && dateFieldLabel in resource.metadata && 'value' in resource.metadata[dateFieldLabel] && typeof(resource.metadata[dateFieldLabel].value) == 'object' && resource.metadata[dateFieldLabel].value != null && 'year' in resource.metadata[dateFieldLabel].value) {
        if ('metadata' in resource && dateFieldLabel in resource.metadata && 'value' in resource.metadata[dateFieldLabel]) {
            yearValue = resource.metadata[dateFieldLabel].value;
        }

        if (yearValue) {
            outputBuffer.push(
                <span className={`publication-date`}>
                    {yearValue}
                </span>
            );
        }

        return outputBuffer;

    }



    preRenderSubYear = (resource) => {

        let outputBuffer = [];

        let dateFieldLabel = '';

        let yearValue = false;

        
        /*/
        //
        //      Attempt to extract standardized generic publication date
        //
        /*/ 

        if ('metadataStructure' in resource && 'fields' in resource.metadataStructure) {
            resource.metadataStructure.fields.forEach( metaField => {

                if ('label' in metaField) {
                    if (metaField.label == 'Date') {
                        dateFieldLabel = metaField.name;
                    }
                }

            });
        }

        // if ('metadata' in resource && 'yearofpublication' in resource.metadata && 'value' in resource.metadata.yearofpublication && 'year' in resource.metadata.yearofpublication.value) {
        if ('metadata' in resource && dateFieldLabel in resource.metadata && 'value' in resource.metadata[dateFieldLabel] && 'year' in resource.metadata[dateFieldLabel].value) {
            yearValue = resource.metadata[dateFieldLabel].value.year;
        }

        if (yearValue) {
            outputBuffer.push(
                <span className={`publication-date`}>
                    {yearValue}
                </span>
            );
        }

        return outputBuffer;

    }



    preRenderTags = (resource) => {

        let outputBuffer = [];

        if ('tags' in resource && resource.tags.length > 0) {

            outputBuffer.push(

                <TagSet metadataSet={resource.tags} parentKey={this.props.sourceResource.title}/>            
            );

        }

        return outputBuffer;

    }



    preRenderBadges = (resource) => {

        let outputBuffer = [];
        let activeBadges = [];

        if ('tags' in resource && resource.tags.length > 0) {

            resource.tags.forEach( singleTag => {

                if (singleTag.tag == 'openaccess') {

                    let activeBadge = Object.assign({}, singleTag);
                    activeBadge.raw_tag = '🔓 ' + singleTag.raw_tag;
                    activeBadges.push(activeBadge);

                }

            });            

            outputBuffer.push(

                <BadgeSet metadataSet={activeBadges}/>            
            );

        }

        return outputBuffer;

    }





    copyMetadataToDestination = (resourceID, fieldName, fieldValue) => {

        console.log('attempting to copy metadata to destination...');

        console.log('source:');
        console.log(this.state.sourceResource);

        console.log('destination:');
        console.log(this.state.destinationResource);

        console.log('resourceID:');
        console.log(resourceID);

        console.log('fieldName:');
        console.log(fieldName);

        console.log('fieldValue:');
        console.log(fieldValue);


        let destinationResource = JSON.parse(JSON.stringify(this.state.destinationResource));

        destinationResource.metadataStructure.fields.forEach(metadataField => {


            if (metadataField.name === fieldName) {
            //     if (destinationResource.metadata.hasOwnProperty(fieldName) {

            //     }

                destinationResource.metadata[fieldName].value = '10.1136/vr.g1495';

            }

        });

        this.setState({ 
            destinationResource: destinationResource
        }); 




    }





    preRenderFieldActions = (resourceID, fieldName, fieldValue) => {

        return (

            <div className={`metadata-actions`}>
                <ButtonToolbar aria-label="Metadata actions">
                  <ButtonGroup className="me-2" aria-label="Copy to destination resource">
                    <Button onClick={() => this.copyMetadataToDestination(resourceID, fieldName, fieldValue)}>Copy</Button>
                  </ButtonGroup>
                  <ButtonGroup className="me-2" aria-label="Other action for destination resource">
                    <Button onClick={() => this.copyMetadataToDestination(resourceID, fieldName, fieldValue)}>Merge</Button>
                  </ButtonGroup>
                </ButtonToolbar>
            </div>

        )

    }





    preRenderMetadata = (resource, ownerName, editable: false) => {

        // let resource = this.state.resources[0];

        console.log("Prerendering resource:");
        console.log(resource);

        if (editable) {
            console.log('resource is editable');
        } else {
            console.log('resource is not editable')
        }

        // const preRenderYear = this.preRenderYear.bind(this);

        let outputBuffer = [];

        outputBuffer.push(

            <div className={'metadata-set'} key={`metadata-set-category`}>
                <div className={`metadata-key-block`}>
                    <span className={`metadata-key`}>Category</span>
                </div>
                <div className={`metadata-value-block`}>
                    <span className={`metadata-value`}><a href={`/resources/browse/${resource.type.name}`}>{resource.type.label}</a></span> 
                </div>
            </div>

        );


        if (!resource.hasOwnProperty('abstract') || !(resource.abstract.length > 0)) {
            resource.abstract = '';
        }

        outputBuffer.push(

            <div className={'metadata-set'} key={`metadata-set-abstract`}>
                <div className={`metadata-key-block`}>
                    <span className={`metadata-key`}>Abstract</span>
                </div>
                <div className={`metadata-value-block`}>
                    <span className={`metadata-value`}>{ReactHtmlParser(resource.abstract)}</span>
                </div>
            </div>

        );
      

        // resource.metadata.forEach(singleField => {

        if (!resource.hasOwnProperty('metadataStructure')) {
            resource.metadataStructure = {
                fields: resource.metadata
            }
        }

        Object.values(resource.metadataStructure.fields).map((singleField, index) => {

            console.log('in structure loop...');
            console.log(resource.metadataStructure);

            console.log('associated metadata:');
            console.log(resource.metadata);

            let dataField = {
                value: ''
            }

            if (resource.metadata.hasOwnProperty(singleField.name) && resource.metadata[singleField.name].hasOwnProperty('value')) {
                dataField = resource.metadata[singleField.name];
            }
        
            if (typeof(dataField) === 'string' || typeof(dataField) === 'number') {
                const singleFieldValue = dataField;
                dataField = {
                    value: singleFieldValue
                };
            }

            if (!dataField.hasOwnProperty('value')) {
                console.log('dataField:');
                console.log(dataField);
                dataField.value = '';
            }

            if (dataField.hasOwnProperty('value') && (!dataField.value)) {
                dataField.value = '';
            }

            console.log('dataField:');
            console.log(dataField);

            // console.log(singleField.value);

            let valueString = '';

            
            if (singleField.hasOwnProperty('type')) {

                switch(singleField.type) {

                    case 'date':

                        if (typeof(dataField.value) === 'string') {
                            valueString += dataField.value;
                        } else {

                            if (dataField.value.year) {
                                // console.log(singleField.value);
                                valueString += dataField.value.year;
                            }

                            if (dataField.value.month) {
                                valueString += dataField.value.month;
                            }

                            if (dataField.value.day) {
                                valueString += dataField.value.day;
                            }
                        }

                    break;


                    case 'geo':

                        if ((dataField.value.value || (dataField.value.lat + dataField.value.lng) == 0)) {
                            if (dataField.value.value.length > 0) {
                                valueString += dataField.value.value;
                            } else if (dataField.value.lat && dataField.value.lat != '0.0' && dataField.value.lng && dataField.value.lng != '0.0') {    
                                valueString += `${dataField.value.lat}, ${dataField.value.lng}`;
                            }
                        }

                    break;


                    case 'languages':

                        if (dataField.value && dataField.value.length > 0) {

                            if (dataField.value == 'en') {
                                valueString += 'English';
                            } else {
                                valueString += dataField.value;
                            }

                        }

                    break;


                    default:
                        valueString = dataField.value;
                    break;

                }

            }



            if (singleField.hasOwnProperty('label') && singleField.label) {

                console.log('single field has label...');

                /*/
                //
                //      Change formatting and advanced behaviors in response to metadata type
                //
                /*/ 

                console.log('generating metadata fields:');
                console.log(singleField);

                switch (singleField.label) {

                    case 'DOI':

                        if (!(this.validateDOI(valueString))) {
                            valueString = '';
                        }

                        outputBuffer.push(

                            <div className={'metadata-set'} key={`metadata-set-${singleField.label}`}>
                                <div className={`metadata-key-block`}>
                                    <span className={`metadata-key`}>{singleField.label}</span>
                                </div>
                                <div className={`metadata-value-block`}>
                                    <span className={`metadata-value`}><a href={`https://dx.doi.org/${valueString}`} target={`_blank`}>{valueString}</a></span>
                                </div>
                                {this.preRenderFieldActions(0, singleField.name, valueString)}
                            </div>

                        );

                    break;



                    case 'URL':

                        if (!(validURL.isUri(valueString))) {
                            valueString = '';
                        }


                        outputBuffer.push(

                            <div className={'metadata-set'} key={`metadata-set-${singleField.label}`}>
                                <div className={`metadata-key-block`}>
                                    <span className={`metadata-key`}>{singleField.label}</span>
                                </div>
                                <div className={`metadata-value-block`}>
                                    <span className={`metadata-value`}><a href={`${valueString}`} target={`_blank`}>{valueString}</a></span>
                                </div>
                            </div>

                        );

                    break;



                    default:

                        outputBuffer.push(

                            <div className={'metadata-set'} key={`metadata-set-${singleField.label}`}>
                                <div className={`metadata-key-block`}>
                                    <span className={`metadata-key`}>{singleField.label}</span>
                                </div>
                                <div className={`metadata-value-block`}>
                                    <span className={`metadata-value`}>{valueString}</span>
                                </div>
                            </div>

                        );

                    break;

                }

            }

        });


        
        // if (resource.type.name == 'bibliographies') {

        //     let childBlocks = [];

        //     const returnChildBlocks = () => {

        //         if (childBlocks.length > 0) {
        //             return childBlocks;
        //         } else {
        //             return null;
        //         }
        //     }

        //     if (resource.childResources.length > 0) {
            

        //         orderBy(resource.childResources, ['title', 'publishUp'], ['asc', 'desc']).map((childResource, index) => {

        //             const childResourceTagBlock = (childResource) => {

        //                 if (childResource.tags.length > 0) {

        //                 return (
        //                     <span className={`resource-tags`}>
        //                         {this.preRenderTags(childResource)}
        //                     </span>
        //                 );

        //                 } else {
        //                     return null
        //                 }

        //             }

        //             childBlocks.push(

                    
        //                 <div className={`resource-record`} key={childResource.id}>
        //                     <div className={`top-line`}>
        //                         <span className={`resource-type`}>{childResource.type.label}</span>
        //                         {this.preRenderBadges(childResource)}
        //                     </div>
        //                     <a className={`h6 resource-title`} href={`/resources/${childResource.id}`}>{childResource.title}</a>
        //                     <div className={`resource-metadata`}>
        //                         <div className={`resource-byline`}>
        //                             {this.preRenderYear(childResource)}
        //                             <span className={`resource-contributors`}>
        //                                 {this.preRenderSubAuthors(childResource)} 
        //                             </span>
        //                         </div>
        //                         <span className={`resource-abstract`}>
        //                             {ReactHtmlParser(childResource.introtext)}
        //                         </span>
    
        //                         {childResourceTagBlock(childResource)}
                            
        //                     </div>
        //                 </div>

        //             );


        //         });
        //     }



        //     outputBuffer.push(

        //         <div className={'metadata-set'} key={`metadata-set-${resource.type.name}`}>
        //             <div className={`metadata-key-block`}>
        //                 <span className={`metadata-key`}>Contents</span>
        //             </div>
        //             <div className={`metadata-value-block`}>

        //             {returnChildBlocks()}

        //             </div>
        //         </div>

        //     );
    

        // }



        // this.preRenderTags({resource});


        outputBuffer.push(

            <MetadataBlock metadataSetName={`Tags`} key={`resource-metadata-tags`} metadataSet={resource.tags} />
            
        );

        return outputBuffer;

    }



    preRenderAuthors = (resource, editable: false) => {


        const altmetricBadgeScript = document.createElement("script");
        altmetricBadgeScript.src = "https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js";
        altmetricBadgeScript.async = true;
        document.body.appendChild(altmetricBadgeScript);

        let outputBuffer = [];

        let useAmpersand    = false;
        let useComma        = false;

        let properAuthors   = [];
        let contributors    = [];

        if ('authors' in resource && resource.authors.length > 0) { 
            resource.authors.forEach(singleContributor => {
                switch(singleContributor.role) {

                    case 'submitter':
                        contributors.push(singleContributor);
                    break;

                    default:
                        properAuthors.push(singleContributor);
                    break;
                }
            })
        }


        if (properAuthors.length > 1) {

            //  Prepare to use an ampersand
            useAmpersand    = true;

        }

        if (properAuthors.length > 2) {

            //  Prepare to use a comma
            useComma        = true;

        } 



        // resource.authors.forEach(singleAuthor {
        for (let i = 0; i < properAuthors.length; i++) {

            let author = properAuthors[i];
        
            let nameSeparator   = '';

            if (properAuthors.length > 2) {

                if (i < properAuthors.length - 1) {
                    nameSeparator += ", ";
                }

                if (i == properAuthors.length - 2) {
                    nameSeparator += ' & ';
                }

            } else {
                if (i < properAuthors.length - 1) {
                    nameSeparator += " & ";
                }
            }

                

            // }


            if (author.name && author.name.length > 0) {

                outputBuffer.push(
                    <span className={`single-author-name`}>{author.name}</span>
                );

                if (nameSeparator.length > 0) {
                    outputBuffer.push(
                        <span className={`author-name-separator`}>{nameSeparator}</span>
                    )
                }

            }

        }

        return outputBuffer;

    }





    // const { location, history } = this.props;


    receiveDestinationChanges = (event, fieldName, fieldValue) => {

        console.log('Destination changes received!');
        console.log(event);
        console.log(event.currentTarget.value);

        let updatedDestinationResource = JSON.parse(JSON.stringify(this.state.destinationResource));

        this.setState({
            destinationResource: updatedDestinationResource
        });

    }





    
    render() {

        let sourceResource            = this.state.sourceResource;
        let destinationResource       = this.state.destinationResource;
        let id                        = this.state.id;

        console.log('rendered resource object:');
        console.log(sourceResource);







        {console.log('pre resource:')}
        {console.log(id)}
        {console.log(sourceResource)}



        let xmlTab =        null;

        if (sourceResource.hasOwnProperty('metadata') ) {

            xmlTab =        <Tab eventKey={`importedXml${id}`} key={`importedXml${id}`} title="Imported XML">
                                {(this.preRenderMetadata(sourceResource, `importedXml${id}`, false))}
                            </Tab>
        }



        let crossRefTab =   null;

        if (this.state.elaboratedResource && this.state.elaboratedResource.crossRefResource && this.state.elaboratedResource.crossRefResource.hasOwnProperty('metadata')) {
            
            crossRefTab =   <Tab eventKey={`crossRef${id}`} key={`crossRef${id}`} title="CrossRef">
                                {(this.preRenderMetadata(sourceResource.crossRefResource, `crossRef${id}`, false))}
                            </Tab>
        }


        let sourceTabs =    <Tabs id={`source${id}`} defaultActiveKey={`importedXml${id}`}>
                                {xmlTab}
                                {crossRefTab} 
                            </Tabs>

        

        return (

            <Fragment key={`resource-${id}`}>

                <div className={`resource-import-preview row mb-3`} key={`resource-${id}`}>
                    <div className={`col-12 row p-3 block-unit`} key={`block-unit-${id}`}>

                        <div className={`col-5 source-resource`} key={`source-resource-${id}`}>

                            <section className={`resource-primary`} key={`resource-primary-${id}`}>
                                <div className={`overview-container`} key={`overview-container-${id}`}>
                                    <div className={`row`} key={`overview-container-sub-row-${id}`}>
                                        <div className={`resource-title-block col-8`} key={`resource-title-block-${id}`}>
                                            <header className={`resource-header h4`} key={`resource-header-${id}`}>{sourceResource.title}</header>
                                            <div className={`resource-byline-block`} key={`resource-byline-block-${id}`}>
                                                {this.preRenderAuthors(sourceResource, false)}
                                                {/*<span className={`resource-byline`}>J. Doe, M. Smith, T. Author, et al.</span>*/}
                                            </div>
                                        </div>
                                        
                                        {/*{this.preRenderMainPayload(sourceResource)}*/}

                                    </div>
                                </div>
                            </section>

                            <section className={`resource-metadata detail-view`} key={`resource-metadata-${id}`}>

                                <div className={`row`} key={`resource-metadata-sub-row-${id}`}>
                                    <div className={`col-12`} key={`resource-metadata-sub-col-${id}`}>






                                        {sourceTabs}

                                        {/*{console.log('pre resource:')}
                                        {console.log(id)}
                                        {console.log(sourceResource)}

                                        <Tabs id={`source${id}`} defaultActiveKey={`importedXml${id}`}>
                                            

                                            <Tab eventKey={`importedXml${id}`} key={`importedXml${id}`} title="Imported XML">
                                                {sourceResource.hasOwnProperty('metadata') ? (
                                                    this.preRenderMetadata(sourceResource, `importedXml${id}`, false)
                                                ) : (
                                                    null
                                                )}
                                            </Tab>


                                            <Tab eventKey={`crossRef${id}`} key={`crossRef${id}`} title="CrossRef">
                                                {sourceResource.hasOwnProperty('crossRefResource') && sourceResource.crossRefResource.hasOwnProperty('metadata') ? (
                                                    this.preRenderMetadata(sourceResource.crossRefResource, `crossRef${id}`, false)
                                                ) : (
                                                    <p>:-)</p>
                                                )}
                                            </Tab>


                                        </Tabs>*/}

                                    </div>
                                </div>

                            </section> 

                        </div>





                        <div className={`col-2 transfer-controls`}>
                        </div>





                        <div className={`col-5 destination-resource`}>

                            <section className={`resource-primary`}>
                                <div className={`overview-container`}>
                                    <div className={`row`}>
                                        <div className={`resource-title-block col-8`}>
                                            <header className={`resource-header h4`}>{destinationResource.title}</header>
                                            <div className={`resource-byline-block`}>
                                                {this.preRenderAuthors(destinationResource, true)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className={`resource-metadata detail-view`}>

                                <div className={`row`}>
                                    <div className={`col-12`}>

                                        {console.log('generating destination resource...')}
                                        {console.log(this.state.destinationResource)}
                                        {console.log(destinationResource)}
                                        {console.log('elaborated resource (x):')}
                                        {console.log(this.state.elaborateResource)}

                                        <ImportedResourceDestination id={id} destinationResource={destinationResource} reportChanges={this.receiveDestinationChanges.bind(this)}/>;

                                    </div>
                                </div>

                            </section> 

                        </div>

                    </div>
                </div>

            </Fragment>

        );
    }
}

export default withRouter(ImportedResourcePreview);