import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { withRouter, Route, Redirect, Link } from 'react-router-dom';



const BadgeSet = ({ metadataSet }) => {

    return(

        <Fragment>
            <div className={`resource-badges`}>

                {
                    metadataSet.map((singleTag, i) => {
                        console.log(singleTag);
                        return (<span className={`metadata-value metadata-tag metadata-badge`} key={`badge-${singleTag.tag}`}><Link to={`/tags/${singleTag.tag}`}>{singleTag.raw_tag}</Link></span>);
                    })
                }

            </div>
        </Fragment>

    );

}



export default compose()(BadgeSet);