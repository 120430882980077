import React, { Component, Fragment } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import {
    withStyles,
    Card,
    CardContent,
    CardActions,
    Modal,
    Button,
    TextField,
} from '@material-ui/core';
import { compose } from 'recompose';
// import { withRouter, Route, Redirect, Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
// import { find, orderBy } from 'lodash';


// const API = process.env.REACT_APP_API || 'http://localhost:3001';



// const styles = theme => ({
//     modal: {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//     },
//     modalCard: {
//         width: '90%',
//         maxWidth: 500,
//     },
//     modalCardContent: {
//         display: 'flex',
//         flexDirection: 'column',
//     },
//     marginTop: {
//         marginTop: theme.spacing(2),
//     },
// });


const ResourceAddPayload = ({ advanceForm, retractForm, processMetadata, resource, values }) => {

    const Advance = e => {
        e.preventDefault();
        advanceForm();
    }



    const Retract = e => {
        e.preventDefault();
        retractForm();
    }



    return (

        <Fragment>
            <form>
                <div className={`row`}>

                    <span>Add payload</span>
                    
                </div>
            </form>
        </Fragment>
    )

}



export default compose()(ResourceAddPayload);