import React, { Component, Fragment } from 'react';
// import { withOktaAuth } from '@okta/okta-react';
import {
    withStyles,
    Card,
    CardContent,
    CardActions,
    Modal,
    Button,
    TextField,
} from '@material-ui/core';
import { compose } from 'recompose';
// import { withRouter, Route, Redirect, Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { find, orderBy } from 'lodash';


// const API = process.env.REACT_APP_API || 'http://localhost:3001';



// const styles = theme => ({
//     modal: {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//     },
//     modalCard: {
//         width: '90%',
//         maxWidth: 500,
//     },
//     modalCardContent: {
//         display: 'flex',
//         flexDirection: 'column',
//     },
//     marginTop: {
//         marginTop: theme.spacing(2),
//     },
// });


const ResourceAddEssentialMetadata = ({ advanceForm, processMetadata, resourceTypes, values, loading }) => {

    const Advance = e => {
        e.preventDefault();
        advanceForm();
        processMetadata('resource.type', e.target.value);
    }



    // const Retract = e => {
    //     e.preventDefault();
    //     retractForm();
    // }



    return (

        <Fragment>
            <form>
                <div className={`row`}>

                        {resourceTypes.length > 0 ? (

                            
                            <Fragment>

                                {orderBy(resourceTypes, ['type'], ['desc', 'asc']).map(resourceType => (
                                    <div className={`col-3 p-5`}>
                                        <button className={`btn btn-sm btn-primary w-100`} value={resourceType.id} onClick={Advance}>{resourceType.type}</button>
                                        <p className={`resource-type-description my-2`}>{resourceType.description}</p>
                                    </div>
                                ))}

                            </Fragment>


                        ) : (
                            !loading && <span>No resource types to display</span>
                        )}
                        
                    
                </div>
            </form>
        </Fragment>
    )

}



export default compose()(ResourceAddEssentialMetadata);