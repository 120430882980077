import React, { Component, Fragment } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Route, Redirect, Link, withRouter } from 'react-router-dom';
import {
    withStyles,
    Typography,
    Fab,
    IconButton,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import moment from 'moment';
// import { Form as FormF, Field } from 'react-final-form';
import { find, orderBy } from 'lodash';
import { compose } from 'recompose';

import ResourceEditor               from 'components/ResourceEditor';
import SearchBar                    from 'components/SearchBar/SearchBar';
import SearchFilterDate             from 'components/SearchBar/SearchFilterDate';
import SearchFilterResourceTypes    from 'components/SearchBar/SearchFilterResourceTypes';
import SearchFilterSorting          from 'components/SearchBar/SearchFilterSorting';
import SearchStringParser           from 'components/SearchBar/SearchStringParser';
import ErrorSnackbar                from 'components/ErrorSnackbar';
// import MetadataBlock    from 'components/MetadataBlock/MetadataBlock';
import TagSet                       from 'components/MetadataBlock/TagSet/TagSet';
import BadgeSet                     from 'components/MetadataBlock/BadgeSet/BadgeSet';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';



// import WordLimit from 'react-word-limit'; 
import trimCharacters from 'trim-characters';




const styles = theme => ({
    resources: {
        marginTop: theme.spacing(2),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(2), 
            right: theme.spacing(2),
        },
    },
});

const API = process.env.REACT_APP_API_URL;

// const history = useHavigate();




const PaginationForm = (({limit, onChange}) => {

    let defaultLimits = [5, 10, 20, 40, 60, 80, 100];

    if (!defaultLimits.includes(parseInt(limit))) {
        defaultLimits.push(parseInt(limit));
    }

    return (
        <Form className={`pagination-selector`} onChange={onChange}>
        
            <Form.Group controlId="results-per-page-selector">
                <Form.Label>Display #</Form.Label>
                <Form.Control as="select" defaultValue={parseInt(limit)}>
                    {
                        defaultLimits.map((singleLimit) => (
                            <option key={`limit-${singleLimit}`}>{singleLimit}</option>
                        ))
                    }
              </Form.Control>
            </Form.Group>

        </Form>
    );

}); 





const LoadMoreButton = (({start, limit, results, page, onClick}) => {

    let totalPageCount      = Math.ceil(results / limit);
    let currentPage         = Math.floor((start / limit) + 1);

    if (totalPageCount == currentPage) {

        return null;

    } else {

        limit += limit;

        return (
            <Row>
                <Col xs={{span: 4, offset: 4}}>
                    <Button className={`w-100 mb-5`} variant="primary btn-lg" value={limit} onClick={onClick}>Load More</Button>
                </Col>
            </Row>
        );
    }
}); 





const PageSelectionForm = (({start, limit, results, page, onClick}) => {

    start   = parseInt(start);
    limit   = parseInt(limit);
    results = parseInt(results);
    page    = parseInt(page);

    let totalPageCount = Math.ceil(results / limit);
    let totalPageCountRemainder = results % limit;

    // if (totalPageCountRemainder == 0 {
    //     totalPageCount--;
    // });

    // if (totalPageCountRemainder > 0) {
    //      totalPageCount++;
    // }

    let currentPage = Math.floor((start / limit) + 1);





    let pages = [];


    /*/
    //
    //      Adjust page number display based on curent page number 
    //
    /*/ 

    let medianPageCount = 4.5;

    if (totalPageCount < 9) {
        medianPageCount = totalPageCount / 2;
    }

    let paginationStart = 1;


    /*/
    //
    //      If the current page is lower than the median, allow it to appear in the 1–median slot in the page selector
    //
    /*/ 

    if (currentPage > Math.ceil(medianPageCount) && currentPage > 4) {
        paginationStart = currentPage - 4;
    }


    pages.push(<a key={`page-start`} className={`page-number start`} data-page={1} href={`/resources/${1}`} onClick={onClick}>{`start`}</a>)

    for (var i = (paginationStart - 1); i < totalPageCount; i++) {
        let j = i + 1;

        if (j == currentPage) {
            pages.push(<span key={`page-${j}`} className={`page-number disabled`} data-page={j} href={`/resources/${j}`} onClick={onClick}>{j}</span>)
        } else {
            pages.push(<a key={`page-${j}`} className={`page-number`} data-page={j} href={`/resources/${j}`} onClick={onClick}>{j}</a>)
        }

        if (i >= paginationStart + Math.floor(medianPageCount * 2) - 2) {
            break;
        }

    }

    pages.push(<a key={`page-end`} className={`page-number end`} data-page={totalPageCount} href={`/resources/${totalPageCount}`} onClick={onClick}>{`end`}</a>)


    let defaultLimits = [5, 10, 20, 40, 60, 80, 100];


    return (

        <div className={`page-selector`}>
            {
                pages.map((page) => (
                    page
                ))
            }
        </div>
    );

}); 



// const SearchBar = ({onChange, onSubmit = () => ({}) }) => (
const SearchBark = (onChange, onSubmit) => (

    <form onSubmit={onSubmit} className={`row pt-3`}>
        <div className={`col-12`}>
            <div className={`d-flex`}>
                <input type="text" className={`form-control hero-search-field`} id="resourcesSearchQuery" aria-describedby="resourcesSearchQueryHelp" placeholder={`Enter a keyword or phrase,`}/>
                <button type="submit" className={`btn btn-primary hero-search-button`}>search</button>
            </div>
        </div>
    </form>

); 




// const SearchFilterDate = (onChange) => {
//   return (
//     <>
//       <Form.Label>Publication Year</Form.Label>
//       <Form.Range min={1800} max={2023}/>
//     </>
//   );
// }




class SearchBrowser extends Component {
    state = {
        preInitialized:             false,
        initialized:                false,
        loading:                    true,
        resources:                  [],
        resourceTypes:              [],
        resourceTypeCounts:          [],
        resultCount:                0,
        error:                      null,
        // rawSearchString:               '',
        // sortby:                     ['date'],
        // limit:                      [20],
        previousLimit:              [false],
        // start:                      [0],
        previousStart:              [false],
        page:                       [1],
        encodedExplodedCoreSearchString     :   [],                 //      [dog,   %22therapy+animal%22,   benefits]
        explodedFullSearchString            :   [],                 //      [dog,   "therapy animal",       type:journalarticles,       benefits]
        encodedExplodedFullSearchString     :   [],                 //      [dog,   %22therapy+animal%22,   type%3Ajournalarticles,     benefits]
        explodedFilterString                :   [],                 //      [type:journalarticles]
        encodedExplodedFilterString         :   [],                 //      [type%3Ajournalarticles]
        encodedCoreSearchString             :   '',                 //      dog+%22therapy+animal%22+benefits
        searchQueryString                   :   '',

        filterPackage                       :   {
            "rawSearchString"                   :   '',
            "limit"                             :   [20],
            "start"                             :   [0],
            "type"                              :   [],
            "filetype"                          :   [],
            "authors"                           :   [],
            "publishedafter"                    :   [],
            "publishedbefore"                   :   [],
            "publisher"                         :   [],
            "submittedafter"                    :   [],
            "submittedbefore"                   :   [],
            "tags"                              :   [],
            "sortby"                            :   ['alt_score'],
            "order"                             :   ['desc'],
            "explodedCoreSearchString"          :   [],                 //      [dog,   "therapy animal",       benefits]
            "encodedExplodedCoreSearchString"   :   [],                 //      [dog,   %22therapy+animal%22,   benefits]
            "explodedFullSearchString"          :   [],                 //      [dog,   "therapy animal",       type:journalarticles,       benefits]
            "encodedExplodedFullSearchString"   :   [],                 //      [dog,   %22therapy+animal%22,   type%3Ajournalarticles,     benefits]
            "explodedFilterString"              :   [],                 //      [type:journalarticles]
            "encodedExplodedFilterString"       :   [],                 //      [type%3Ajournalarticles]
            "encodedCoreSearchString"           :   [],                 //      dog+%22therapy+animal%22+benefits
            "searchQueryString"                 :   ''                  //      ?q=dog+%22therapy+animal%22+benefits&type=journalarticles
        },    
        
        searchUpdateRequired                :   true,
        searchExpansionRequired             :   false,
        pathUpdateRequired                  :   false,
        searchDelayTimeouts                 :   []
    };




    // let filters = {
    //     "rawSearchString"                   :   rawSearchString,
    //     "limit"                             :   existingFilters.limit,
    //     "type"                              :   existingFilters.type,
    //     "filetype"                          :   existingFilters.filetype,
    //     "authors"                           :   existingFilters.authors,
    //     "publishedafter"                    :   existingFilters.publishedafter,
    //     "publishedbefore"                   :   existingFilters.publishedbefore,
    //     "publisher"                         :   existingFilters.publisher,
    //     "submittedafter"                    :   existingFilters.submittedafter,
    //     "submittedbefore"                   :   existingFilters.submittedbefore,
    //     "tags"                              :   existingFilters.tags,
    //     "explodedCoreSearchString"          :   existingFilters.explodedCoreSearchString,               //      [dog,   "therapy animal",       benefits]
    //     "encodedExplodedCoreSearchString"   :   existingFilters.encodedExplodedCoreSearchString,        //      [dog,   %22therapy+animal%22,   benefits]
    //     "explodedFullSearchString"          :   existingFilters.explodedFullSearchString,               //      [dog,   "therapy animal",       type:journalarticles,       benefits]
    //     "encodedExplodedFullSearchString"   :   existingFilters.encodedExplodedFullSearchString,        //      [dog,   %22therapy+animal%22,   type%3Ajournalarticles,     benefits]
    //     "explodedFilterString"              :   existingFilters.explodedFilterString,                   //      [type:journalarticles]
    //     "encodedExplodedFilterString"       :   existingFilters.encodedExplodedFilterString,            //      [type%3Ajournalarticles]
    //     "encodedCoreSearchString"           :   existingFilters.encodedCoreSearchString,                //      dog+%22therapy+animal%22+benefits
    //     "searchQueryString"                 :   existingFilters.searchQueryString                       //      ?q=dog+%22therapy+animal%22+benefits&type=journalarticles
    // }




    // static propTypes = {
    //     match: PropTypes.object.isRequired,
    //     location: PropTypes.object.isRequired,
    //     history: PropTypes.object.isRequired
    //   };


    componentWillMount() {      








        /*/
        //
        //      Get query vars
        //
        /*/ 

        // const resourceTypeSet = this.getResourceTypes().then((resourceTypes) => {


            


        // });




        // // const resTypeSetup = async() => {
        // //     const resTypeSet = await this.fetch('get', '/api/resource-types').then((resourceTypes) => {


        // if (!this.state.preInitialized) {

            

        // // } else if (!this.state.initialized) {

        //     this.getResourceTypes();

            

        // } else {




            

        // }
    }

    componentDidMount() {



        if (this.state.resourceTypes.length < 1) {
            this.getResourceTypes();
        }




        



        
        

        // this.getResourceTypes();
        // this.prepareToPerformSearch();
    }



    componentDidUpdate() {


        if (!this.state.initialized) {


            let search = window.location.search;
            let params = new URLSearchParams(search);
            let newFilter = this.packageSearchFilters();


            console.log('history:');
            console.log(window.history);

            console.log("search params?");
            console.log(params);

            let qSearchString           =           params.get('q')                     || '';
            let qSortBy                 =           [params.get('sortby')]              || this.state.filterPackage.sortby;
            let qOrder                  =           [params.get('order')]               || this.state.filterPackage.order;
            let qLimit                  =           [parseInt(params.get('limit'))]     || this.state.filterPackage.limit;
            let qStart                  =           [parseInt(params.get('start'))]     || this.state.filterPackage.start;
            let qFilterResourceTypes    =           params.getAll('type')               || this.state.filterPackage.type;
            let qFileType               =           params.getAll('filetype')           || this.state.filterPackage.filetype;
            let qAuthors                =           params.getAll('author')             || this.state.filterPackage.authors;
            let qPublishedAfter         =           [params.get('publishedafter')]      || this.state.filterPackage.publishedafter;
            let qPublishedBefore        =           [params.get('publishedbefore')]     || this.state.filterPackage.publishedbefore;
            let qSubmittedAfter         =           [params.get('submittedafter')]      || this.state.filterPackage.submittedafter;
            let qSubmittedBefore        =           [params.get('submittedbefore')]     || this.state.filterPackage.submittedbefore;
            let qPublisher              =           params.getAll('publisher')          || this.state.filterPackage.publisher;
            let qTags                   =           params.getAll('tag')                || this.state.filterPackage.tags;


            if (isNaN(qLimit[0])) {
                qLimit = [20];
            }

            if (Array.isArray(qLimit) && qLimit.length == 0) {
                qLimit = [20];
            }

            if (isNaN(qStart[0])) {
                qStart = [0];
            }

            if (Array.isArray(qStart) && qStart.length == 0) {
                qStart = [0];
            }

            if (qSortBy == undefined) {
                qSortBy = newFilter.sortby;
            }

            if (qOrder == undefined) {
                qOrder = newFilter.order;
            }



            let nullChecks = [
                qPublishedAfter,
                qPublishedBefore,
                qSubmittedAfter,
                qSubmittedBefore,
                qSortBy
            ]

            nullChecks.forEach( nullCheckField => {
                if (nullCheckField[0] == null) {
                    nullCheckField = [];
                }
            })


            newFilter.rawSearchString   = qSearchString;
            newFilter.limit             = qLimit;
            newFilter.type              = qFilterResourceTypes;
            newFilter.filetype          = qFileType;
            newFilter.authors           = qAuthors;
            newFilter.publishedafter    = qPublishedAfter;
            newFilter.publishedbefore   = qPublishedBefore;
            newFilter.publisher         = qPublisher;
            newFilter.submittedafter    = qSubmittedAfter;
            newFilter.submittedbefore   = qSubmittedBefore;
            newFilter.tags              = qTags;
            newFilter.sortby            = qSortBy;
            newFilter.order             = qOrder;




            let sortModes = [
                {
                    label:      'date published',
                    type:       'date_pub',
                    direction:  'desc',
                    mode:       'numeric',
                    active:     'false'
                },
                {
                    label:      'date added',
                    type:       'date_add',
                    direction:  'desc',
                    mode:       'numeric',
                    active:     'false'
                },
                {
                    label:      'title',
                    type:       'title',
                    direction:  'asc',
                    mode:       'alpha',
                    active:     'false'
                },
                {
                    label:      'Altmetric score',
                    type:       'alt_score',
                    direction:  'desc',
                    mode:       'numeric',
                    active:     'false'
                },
                {
                    label:      'mainstream popularity',
                    type:       'alt_cohort_pub',
                    direction:  'desc',
                    mode:       'numeric',
                    active:     'false'
                },
                {
                    label:      'practitioner popularity',
                    type:       'alt_cohort_doc',
                    direction:  'desc',
                    mode:       'numeric',
                    active:     'false'
                },
                {
                    label:      'researcher popularity',
                    type:       'alt_cohort_sci',
                    direction:  'desc',
                    mode:       'numeric',
                    active:     'false'
                },
                {
                    label:      'science communicator popularity',
                    type:       'alt_cohort_com',
                    direction:  'desc',
                    mode:       'numeric',
                    active:     'false'
                }
            ];            


            let parsedFilter = SearchStringParser(newFilter);

            this.setState({
                filterPackage:              parsedFilter,
                sortModes:                  sortModes,
                // pathUpdateRequired:         true,
                initialized:                true
            });

        } else if (this.state.initialized && !this.state.loading) {

            let existingFilters = this.state.filterPackage;//this.packageSearchFilters();

            // const newSearchFilters      = SearchStringParser(existingFilters);
            const newSearchQueryString  = existingFilters.searchQueryString;

            if (existingFilters.limit != undefined && existingFilters.limit.length > 0)  {

                if (newSearchQueryString === this.state.filterPackage.searchQueryString) {

                    /*/
                    //
                    //      Update URI to reflect latest search parameter changes
                    //
                    /*/ 
                    
                    if (this.state.pathUpdateRequired) {
                        // this.updatePath();
                    }


                    /*/
                    //
                    //      Perform search with updated parameters
                    //
                    /*/ 

                    if (this.state.searchUpdateRequired) {
                        this.performSearch();
                        this.setState({
                            filterPackage: existingFilters,
                            searchUpdateRequired: false
                        })
                    } else if (this.state.searchExpansionRequired) {
                        this.expandSearch();
                    }

                } else {

                    // this.setState({
                    //     filterPackage: existingFilters
                    // });

                }
            }
        } else {



        }



            
        
    }





    async fetch(method, endpoint, body, queryString = '') {

        // if (queryString.length > 0) {

        
        /*/
        //
        //      Make sure query string is valid even if q argument is missing
        //
        /*/ 

        if (queryString.match(/^\&/)) { 
            queryString = queryString.replace(/^\&/,'?').replace(/\s+/g, '+').trim();
        }

        try {
            const response = await fetch(`${API}${endpoint}${queryString}`, {
                method,
                // body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    //authorization: `Bearer ${await this.props.authService.getAccessToken()}`,
                    // authorization: `JWT ${localStorage.getItem('JWT') || null}`,
                },
            });

            if (response.ok) {
                return await response.json();
            } else {
                return {
                    error: {
                        message: 'Search request failed. Please try again later.'
                    }
                }
            }
        
        } catch (err) {
            console.error(err);

            this.setState({ 
                error: {
                    message: err
                }
            });
        }
    }



    processSearchString() {


        let querySet = [];
        let queryString = '';

        if (this.state.searchString.length > 0) {
            queryString += `&q=${this.state.searchString}`;
        } else {

        }


        if (this.state.filterPackage.sortby.length > 0) {
            queryString += `&sortby=${this.state.filterPackage.sortby}`;
        } else {

        }


        if (typeof(this.state.filterPackage.limit[0]) == 'number') {
            if (this.state.filterPackage.limit[0] > 0) {
                queryString += `&limit=${this.state.filterPackage.limit[0]}`;
            }
        } else {
            if (this.state.filterPackage.limit[0].length > 0) {
                queryString += `&limit=${this.state.filterPackage.limit[0]}`;
            } else {

            }
        }


        if (typeof(this.state.filterPackage.start) == 'number') {
            if (this.state.filterPackage.start >= 0) {
                queryString += `&start=${this.state.filterPackage.start}`;
            }
        } else {
            if (this.state.filterPackage.start.length > 0) {
                queryString += `&start=${this.state.filterPackage.start}`;
            } else {

            }
        }


        if (this.state.filterPackage.filterResourceTypes.length > 0) {
            this.state.filterPackage.filterResourceTypes.forEach((resourceType) => {
                queryString += `&type=${resourceType}`;
            })
        }


        if (this.state.filterPackage.sortby) {
            querySet.push(`sortby=${this.state.filterPackage.sortby}`);
        }

        if (this.state.filterPackage.limit[0]) {
            querySet.push(`limit=${this.state.filterPackage.limit[0]}`);
        }

        if (this.state.filterPackage.start[0]) {
            querySet.push(`start=${this.state.filterPackage.start[0]}`);
        }

        

        // if (queryString.match(/^\&/)) { 
        //     queryString = queryString.replace(/^\&/,'?').replace(/\s+/g, ' ').trim();
        // }

        queryString = queryString.replace(/^\&/,'?').replace(/\s+/g, ' ').trim();


        return queryString;

    }



    async getResourceTypes() {
        this.setState({ 
            loading: false, 
            preInitialized: true,
            resourceTypes: (await this.fetch('get', '/api/resource-types')) || [] 
        });
    }



    async getResources(filters) {

        /*/
        //
        //      FILTER PARAMETERS
        //
        //      filters = {
        //          resourceType:   'journalarticles',
        //          sortby:         'date',
        //          limit:          '100',
        //          start:          0
        //      }
        //
        /*/ 

        // if (!filters) {
            
        //     filters = {
        //         resourceType:   'all',
        //         sortby:         'date',
        //         limit:          100,
        //         start:          0
        //     }
        // }

        this.setState({ 
            loading: false, 
            resources: (await this.fetch('get', '/api/resources')) || [] 
        });
    }



    parseSearchString() {

    }



    updateSearch(filters) {

        // updatedFilters = packageSearchFilters();

        this.setState({
            filterPackage:      filters,
            // pathUpdateRequired: true 
        }, this.updatePath() )
    }



    updateSearchDelayed(filters) {

        let existingTimeouts = this.state.searchDelayTimeouts;

        /*/
        //
        //      Clear older entries
        //
        /*/ 

        existingTimeouts.forEach(singleTimeout => {
            clearTimeout(singleTimeout.timeout);
        });



        // set search input lag timeout

        console.log('waiting to fetch suggestions...');

        const timeoutDate = new Date();
        const searchPackage = {
            "time"          : timeoutDate,
            "filters"       : filters
        }


        existingTimeouts.push({
            "time" : timeoutDate,
            "timeout" : setTimeout( (searchPkg) => {
                console.log('waiting to fetch suggestions...');
                console.log(this)

                let existingTimeouts = this.state.searchDelayTimeouts;

                /*/
                //
                //      Clear older entries
                //
                /*/ 

                existingTimeouts.forEach(singleTimeout => {
                    clearTimeout(singleTimeout.timeout);
                });

                this.setState({
                    filterPackage: searchPackage.filters,
                    searchUpdateRequired: true,
                    searchDelayTimeouts: []
                });
            }, 400, searchPackage)
        });

        
        this.setState({
            filterPackage:                  filters,
            // rawSearchString:                   filters.rawSearchString,
            // limit:                          filters.limit,
            // filterResourceTypes:            filters.type,
            // filterFileType:                 filters.filetype,
            // filterAuthors:                  filters.authors,
            // filterPublishedAfter:           filters.publishedafter,
            // filterPublishedBefore:          filters.publishedbefore,
            // filterSubmittedAfter:           filters.submittedafter,
            // filterSubmittedBefore:          filters.submittedbefore,
            // filterPublisher:                filters.publisher,
            // filterTags:                     filters.tags,

            // explodedCoreSearchString            :   filters.explodedCoreSearchString,               //      [dog,   "therapy animal",       benefits]
            // encodedExplodedCoreSearchString     :   filters.encodedExplodedCoreSearchString,        //      [dog,   %22therapy+animal%22,   benefits]
            // explodedFullSearchString            :   filters.explodedFullSearchString,               //      [dog,   "therapy animal",       type:journalarticles,       benefits]
            // encodedExplodedFullSearchString     :   filters.encodedExplodedFullSearchString,        //      [dog,   %22therapy+animal%22,   type%3Ajournalarticles,     benefits]
            // explodedFilterString                :   filters.explodedFilterString,                   //      [type:journalarticles]
            // encodedExplodedFilterString         :   filters.encodedExplodedFilterString,            //      [type%3Ajournalarticles]
            // encodedCoreSearchString             :   filters.encodedCoreSearchString,                //      dog+%22therapy+animal%22+benefits

            // searchQueryString:              filters.searchQueryString,
            searchDelayTimeouts:            existingTimeouts,
        });
    }



    updatePath() {
        const { match, location, history } = this.props;

        // console.log('ROUTERING:');
        // console.log(match);
        // console.log(location);
        // console.log(history);

        if (history.location.search != (`${encodeURI(this.state.filterPackage.searchQueryString)}`)) {

            history.push({
                pathname: '/search',
                search:   this.state.filterPackage.searchQueryString,
                state: {
                    pathUpdateRequired: false,
                    loading:            true
                }
            });

        }


    }




    prepareToPerformSearch = (filterPackage, e = null) => {

        if (typeof(filterPackage) === 'undefined') {
            filterPackage = this.state.filterPackage;
        }


        if (e) {
            e.preventDefault();
        }



        this.setState({
            filterPackage: filterPackage,
            searchUpdateRequired: true
        })



        // const { match, location, history } = this.props;

        // history.push({
        //     pathname: '/search',
        //     search:   filterPackage.searchQueryString,
        //     state: {
        //         filterPackage:          filterPackage,
        //         searchUpdateRequired:   true,
        //         pathUpdateRequired:     false,
        //         loading:                true
        //     }
        // });
    }




    packageSearchFilters() {

        return {
            "rawSearchString"                   :   this.state.filterPackage.rawSearchString,
            "limit"                             :   this.state.filterPackage.limit,
            "start"                             :   this.state.filterPackage.start,
            "type"                              :   this.state.filterPackage.type, //rosebud
            "filetype"                          :   this.state.filterPackage.filetype,
            "authors"                           :   this.state.filterPackage.authors,
            "publishedafter"                    :   this.state.filterPackage.publishedafter,
            "publishedbefore"                   :   this.state.filterPackage.publishedbefore,
            "publisher"                         :   this.state.filterPackage.publisher,
            "submittedafter"                    :   this.state.filterPackage.submittedafter,
            "submittedbefore"                   :   this.state.filterPackage.submittedbefore,
            "tags"                              :   this.state.filterPackage.tags,
            "sortby"                            :   this.state.filterPackage.sortby,
            "order"                             :   this.state.filterPackage.order,
            "explodedCoreSearchString"          :   this.state.explodedCoreSearchString,               //      [dog,   "therapy animal",       benefits]
            "encodedExplodedCoreSearchString"   :   this.state.encodedExplodedCoreSearchString,        //      [dog,   %22therapy+animal%22,   benefits]
            "explodedFullSearchString"          :   this.state.explodedFullSearchString,               //      [dog,   "therapy animal",       type:journalarticles,       benefits]
            "encodedExplodedFullSearchString"   :   this.state.encodedExplodedFullSearchString,        //      [dog,   %22therapy+animal%22,   type%3Ajournalarticles,     benefits]
            "explodedFilterString"              :   this.state.explodedFilterString,                   //      [type:journalarticles]
            "encodedExplodedFilterString"       :   this.state.encodedExplodedFilterString,            //      [type%3Ajournalarticles]
            "encodedCoreSearchString"           :   this.state.filterPackage.encodedCoreSearchString,                //      dog+%22therapy+animal%22+benefits
            "searchQueryString"                 :   this.state.filterPackage.searchQueryString                       //      ?q=dog+%22therapy+animal%22+benefits&type=journalarticles
        }
    }



    async performSearch(e = null) {

        let outboundFilters = this.state.filterPackage;



        /*/
        //
        //      TRYING TO RECONCILE NEW SEARCHSTRINGPARSER WITH EXISTING METHODS
        //
        /*/ 



        const processedFilters = SearchStringParser(outboundFilters); //this.processSearchString();

        const fetchedSearch = await this.fetch('get', '/api/search', undefined, processedFilters.searchQueryString);

        console.log(fetchedSearch);

        let resourceTypeCounts = [];

        if ('resources' in fetchedSearch) {

            if ('subCounts' in fetchedSearch) {

                let existingResourceTypes = this.state.resourceTypes;

                for (var i = 0; i < existingResourceTypes.length; i++) {
                    if (existingResourceTypes[i].alias in fetchedSearch.subCounts) {
                        let resourceTypeCountObj = {};
                        resourceTypeCountObj['id']      = existingResourceTypes[i].id;
                        resourceTypeCountObj['alias']   = existingResourceTypes[i].alias;
                        resourceTypeCountObj['type']    = existingResourceTypes[i].type;
                        resourceTypeCountObj['count']   = fetchedSearch.subCounts[existingResourceTypes[i].alias];
                        resourceTypeCounts.push(resourceTypeCountObj);
                    }
                }

                console.log('Results by resource type:');
                console.log(resourceTypeCounts);

            }

            this.setState({ 
                loading: false, 
                filterPackage: processedFilters,
                searchUpdateRequired: false,
                // pathUpdateRequired: true,
                resources: (fetchedSearch.resources)    || [] ,
                resultCount: fetchedSearch.resultCount  || 0,
                resourceTypeCounts: resourceTypeCounts   || []

            }, this.updatePath() );

        } else {

            this.setState({ 
                loading: false, 
                filterPackage: processedFilters,
                searchUpdateRequired: false,
                // pathUpdateRequired: true,
                error: {
                    message: "An error occurred while attempting to perform your search.",
                }
            }, this.updatePath() );

        }
    }



    async expandSearch(e = null) {


        if (this.state.filterPackage.searchQueryString.length > 0) {

            if (parseInt(this.state.filterPackage.limit[0]) < this.state.resources.length) {

                let expandedResources = this.state.resources;
                expandedResources.length = parseInt(this.state.filterPackage.limit[0]);

                this.setState({ 
                    loading: false, 
                    searchExpansionRequired: false,
                    // pathUpdateRequired: true,
                    resources: expandedResources || []
                }, this.updatePath() );

            } else {

                let tempLimit = parseInt(this.state.filterPackage.limit[0]) - this.state.resources.length;
                let tempStart = parseInt(this.state.filterPackage.start[0]) + this.state.resources.length;

                const processedSearchString = this.state.filterPackage.searchQueryString.replace(/limit=\d*/, `limit=${tempLimit}`).replace(/start=\d*/, `start=${tempStart}`); //this.processSearchString();

                const fetchedSearch = await this.fetch('get', '/api/search', undefined, processedSearchString);

                console.log(fetchedSearch);


                if ('resources' in fetchedSearch) {

                    let expandedResources = this.state.resources;

                    fetchedSearch.resources.map((singleResult) => (
                        expandedResources.push(singleResult)
                    ));

                    this.setState({ 
                        loading: false, 
                        searchExpansionRequired: false,
                        // pathUpdateRequired: true,
                        resources: expandedResources || [] ,
                        resultCount: fetchedSearch.resultCount
                    }, this.updatePath() );

                } else {

                    this.setState({ 
                        loading: false, 
                        searchExpansionRequired: false,
                        // pathUpdateRequired: true,
                        error: {
                            message: "An error occurred while attempting to perform your search.",
                        }
                    }, this.updatePath() );

                }
            }
        }
    }




    preRenderAuthors = ({resource}) => {

        let outputBuffer = [];

        let useAmpersand    = false;
        let useComma        = false;

        let properAuthors   = [];
        let contributors    = [];

        if ('authors' in resource) {
            resource.authors.forEach(singleContributor => {
                switch(singleContributor.role) {

                    case 'submitter':
                        contributors.push(singleContributor);
                    break;

                    default:
                        properAuthors.push(singleContributor);
                    break;
                }
            })
        }


        if (properAuthors.length > 1) {

            //  Prepare to use an ampersand
            useAmpersand    = true;

        }

        if (properAuthors.length > 2) {

            //  Prepare to use a comma
            useComma        = true;

        } 



        // resource.authors.forEach(singleAuthor {
        for (let i = 0; i < properAuthors.length; i++) {

            let author = properAuthors[i];
        
            let nameSeparator   = '';

            if (properAuthors.length > 2) {

                if (i < properAuthors.length - 1) {
                    nameSeparator += ", ";
                }

                if (i == properAuthors.length - 2) {
                    nameSeparator += ' & ';
                }

            } else {
                if (i < properAuthors.length - 1) {
                    nameSeparator += " & ";
                }
            }

                

            // }


            if (author.name && author.name.length > 0) {

                outputBuffer.push(
                    <span className={`single-author-name`} key={author.id}>{author.name}</span>
                );

                if (nameSeparator.length > 0) {
                    outputBuffer.push(
                        <span className={`author-name-separator`}>{nameSeparator}</span>
                    )
                }

            }

        }

        return outputBuffer;

    }



    preRenderYear = ({resource}) => {

        let outputBuffer = [];

        let dateFieldLabel = '';

        let yearValue = false;

        
        /*/
        //
        //      Attempt to extract standardized generic publication date
        //
        /*/ 

        if ('metadataStructure' in resource && 'fields' in resource.metadataStructure) {
            resource.metadataStructure.fields.forEach( metaField => {

                if ('label' in metaField) {
                    if (metaField.label == 'Date') {
                        dateFieldLabel = metaField.name;
                    }
                }

            });
        }

        // if ('metadata' in resource && 'yearofpublication' in resource.metadata && 'value' in resource.metadata.yearofpublication && 'year' in resource.metadata.yearofpublication.value) {
        // if ('metadata' in resource && dateFieldLabel in resource.metadata && 'value' in resource.metadata[dateFieldLabel] && 'year' in resource.metadata[dateFieldLabel].value) {
        if ('metadata' in resource && dateFieldLabel in resource.metadata && 'value' in resource.metadata[dateFieldLabel]) {
            yearValue = resource.metadata[dateFieldLabel].value;
        }

        if (yearValue) {
            outputBuffer.push(
                <span key={`resource-${resource.id}-year`} className={`publication-date`}>
                    {yearValue}
                </span>
            );
        }

        return outputBuffer;

    }



    preRenderTags = ({resource}) => {

        let outputBuffer = [];

        outputBuffer.push(

            <TagSet metadataSet={resource.tags}/>            
        );

        return outputBuffer;

    }



    preRenderBadges = ({resource}) => {

        let outputBuffer = [];
        let activeBadges = [];

        resource.tags.forEach( singleTag => {

            if (singleTag.tag == 'openaccess') {

                let activeBadge = Object.assign({}, singleTag);
                activeBadge.raw_tag = '🔓 ' + singleTag.raw_tag;
                activeBadges.push(activeBadge);

            }

        });

        outputBuffer.push(

            <BadgeSet metadataSet={activeBadges}/>            
        );

        return outputBuffer;

    }



    changeTypeFilter = (input) => {

        console.log('HANDLING CHANGE!');
        console.log(input);

        let filterType = input.target.value;

        this.setState({typeFilter: filterType}, () => {
            this.getResources();
        });
        

    }    




    changePage = (e) => {

        e.preventDefault();

        let newPage = e.target.getAttribute('data-page');
        let newStart = newPage * parseInt(this.state.filterPackage.limit[0]) - parseInt(this.state.filterPackage.limit[0]);

        let filterClone = this.state.filterPackage;
        filterClone.start = [newStart];
        let cleanFilter = SearchStringParser(filterClone);

        this.setState({
            filterPackage: cleanFilter,
            searchUpdateRequired: true
        })
    }



    renderResourceEditor = ({ match: { params: { id } } }) => {
        if (this.state.loading) return null;
        const resource = find(this.state.resources, { id: Number(id) });

        if (!resource && id !== 'new') return <Redirect to="/resources" />;

        return <ResourceEditor resource={resource} onSave={this.saveResource} />;
    };



    updatePublicationDateFilter = (value) => {

        this.setState({
            filterPublishedAfter: [value],
            start: [0]
        });

        // this.applyFilters()


    }



    updateResourceTypeFilter = (activeResourceTypes) => {

        let filterClone = this.state.filterPackage;

        filterClone.type = activeResourceTypes;
        filterClone.start = [0];

        // var alreadyExists = false;
        // var filtersModified = false;

        // for (var i = 0; i < activeResourceTypes.length; i++) {

        //     alreadyExists = false;

        //     for (var k = 0; k < this.state.filterPackage.type.length; k++) {

        //         if (this.state.filterPackage.type[k] == activeResourceTypes[i]) {
        //             alreadyExists = true;
        //         }
        //     }

        //     if (!alreadyExists) {
        //         // filterClone.type.push(activeResourceTypes[i]);
        //         filtersModified = true;
        //     }
        // }

        // if (filtersModified) {
        //     filterClone.start = [0];
        // }

        let cleanFilter = SearchStringParser(filterClone);

        this.setState({
            filterPackage: cleanFilter,
            searchUpdateRequired: true,
            // pathUpdateRequired: true,
        },  );

    }



    updateSortFilter = (sortingModes = []) => {

        let sortby       = this.state.filterPackage.sortby[0];
        let order  = this.state.filterPackage.order[0];

        if (Array.isArray(sortingModes) && sortingModes.length > 0) {

            sortingModes.forEach(sortMode => {

                if (sortMode.active == 'true') {
                    sortby = sortMode.type;
                    order = sortMode.direction;
                }

            })

        }

        let filters = this.state.filterPackage;

        filters.sortby = [sortby];
        filters.order = [order];

        let cleanFilter = SearchStringParser(filters);



        this.setState({
            // activeSortType:         [activeSortType],
            // activeorder:    [activeorder],
            filterPackage:          cleanFilter,
            searchUpdateRequired:   true,
            // pathUpdateRequired:     true
        }, this.updatePath() );

    }



    updatePagination = (e) => {

        let newLimit = parseInt(e.target.value);

        console.log(`page limit updating from ${this.state.filterPackage.limit[0]} to ${newLimit}`);

        let searchExpansionRequired = this.state.searchExpansionRequired;

        if (newLimit != parseInt(this.state.filterPackage.limit[0])) {
            searchExpansionRequired = true;
        }

        let previousLimit = this.state.filterPackage.limit[0];
        let previousStart = this.state.filterPackage.start[0];

        let filterClone = this.state.filterPackage;
        filterClone.limit = [newLimit];
        let cleanFilter = SearchStringParser(filterClone);

        this.setState({
            filterPackage: cleanFilter,
            previousLimit: [previousLimit],
            previousStart: [previousStart],
            searchExpansionRequired: searchExpansionRequired,
            pathUpdateRequired: false
        });

    }




    // applyFilters() {
    //     let filters = this.packageSearchFilters();
    //     this.updateSearch(filters);
    // }





 


   




    render() {

        const { classes } = this.props;
        const resultCount = this.state.resultCount;
        // const truncateText = (longText) => {
        //     return trimCharacters(longText, 500, false, '...');
        // }

        const performSearch = this.performSearch.bind(this);
        const updateSearch = this.updateSearch.bind(this);
        const updateSearchDelayed = this.updateSearchDelayed.bind(this);
        const updatePublicationDateFilter = this.updatePublicationDateFilter.bind(this);
        const updateResourceTypeFilter = this.updateResourceTypeFilter.bind(this);
        const updateSortFilter = this.updateSortFilter.bind(this);
        const updatePagination = this.updatePagination.bind(this);
        // const resourceTypes = this.state.resourceTypes;

        

        if (this.state.resourceTypes.length < 1 || !this.state.initialized) {
            return <div></div>
        } else {

            let upperViewingLimit = parseInt(this.state.resultCount);

            if ((parseInt(this.state.filterPackage.start[0]) + parseInt(this.state.filterPackage.limit[0])) > parseInt(resultCount)) {
                upperViewingLimit = resultCount;
            } else {
                upperViewingLimit = (parseInt(this.state.filterPackage.start[0]) + parseInt(this.state.filterPackage.limit[0]))
            }


            return (
            <Fragment>
                {/* <Typography variant="h4">Resources</Typography> */}
                <h2 className={`h4`}>Search</h2>

                {
                    <SearchBar onSubmit={this.prepareToPerformSearch} onChange={updateSearchDelayed} filterPackage={this.state.filterPackage} placeholderText={this.state.filterPackage.rawSearchString} searchQuery={this.state.filterPackage.searchQueryString} limit={this.state.filterPackage.limit} type={this.state.filterPackage.filterResourceTypes} filetype={this.state.filterPackage.filterFileType} authors={this.state.filterPackage.filterAuthors} publishedafter={this.state.filterPackage.filterPublishedAfter} publishedbefore={this.state.filterPackage.filterPublishedBefore} submittedafter={this.state.filterPackage.filterSubmittedAfter} submittedbefore={this.state.filterPackage.filterSubmittedBefore} tags={this.state.filterPackage.filterTags}/>
                }

                    <Row>
                        <Col xs={2} className={`pe-2`}>

                            <div className={`filter-pane mt-2`}>
                                <SearchFilterResourceTypes className={`filter-resource-types d-flex flex-column`} resourceTypes={this.state.resourceTypes} resourceTypeCounts={this.state.resourceTypeCounts} activeResourceTypes={this.state.filterPackage.type} onChange={updateResourceTypeFilter}/>
                                {/*<SearchFilterDate className={`filter-publication-date`} onChange={updatePublicationDateFilter}/>*/}
                                <SearchFilterSorting className={`filter-sorting`} sortModes={this.state.sortModes} activeSortMode={this.state.filterPackage.sortby} activeorder={this.state.filterPackage.order} onChange={updateSortFilter}/>

                            </div> 

                        </Col>

                        <Col xs={10}>

                            {this.state.resources.length > 0 ? (

                                <Fragment>

                                <div className={`${classes.resources} browse-view`}>

                                    <div className={`browse-results`}>

                            
                                    {
                                        this.state.resources.map(resource => (

                                            
                                                <div className={`resource-record`} key={resource.id}>
                                        {/*            <div className={`action-initiators`}>
                                                        <button component={Link} to={{
                                                            pathname: `/resources/${resource.id}`,
                                                            state: { 
                                                                resourceID: resource.id
                                                            }
                                                        }} />
                                                        <button onClick={() => this.deleteResource(resource)} />
                                                    </div>*/}
                                                    <div className={`top-line`}>
                                                        <span className={`resource-type`}>{resource.type.label}</span>
                                                        {this.preRenderBadges({resource})}
                                                    </div>
                                                    <a className={`h6 resource-title`} href={`/resources/${resource.id}`}>{resource.title}</a>
                                                    <div className={`resource-metadata`}>
                                                        <div className={`resource-byline`}>
                                                            {this.preRenderYear({resource})}
                                                            <span className={`resource-contributors`}>
                                                                {this.preRenderAuthors({resource})} 
                                                            </span>
                                                        </div>
                                                        <span className={`resource-abstract`}>
                                                            {ReactHtmlParser(resource.abstract)}
                                                        </span>
                                                        <span className={`resource-tags`}>
                                                            {this.preRenderTags({resource})}
                                                        </span>
                                                    </div>
                                                </div>

                                        ))
                                    }

                                        
                            
                                    </div>
                                    
                                    <div className={`results-footer`}>

                                        
                                                <LoadMoreButton start={this.state.filterPackage.start[0]} limit={this.state.filterPackage.limit[0]} results={this.state.resultCount} page={this.state.page} onClick={updatePagination}/>
                   

                                        <Row>
                                            <Col xs={12} className={`page-control`}>

                                                <div className={`page-selection`}>
                                                    <PageSelectionForm start={this.state.filterPackage.start[0]} limit={this.state.filterPackage.limit[0]} results={this.state.resultCount} page={this.state.page} onClick={this.changePage}/>
                                                </div>
                                                <div className={`page-details`}>
                                                    <PaginationForm onChange={updatePagination} limit={this.state.filterPackage.limit[0]} />
                                                    <span>Results {parseInt(this.state.filterPackage.start[0]) + 1} – {upperViewingLimit} of {String(resultCount)}</span>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                    
                                </div>


                                </Fragment>

                            ) : (
                                !this.state.loading && <Typography variant="subtitle1">No resources to display</Typography>
                            )}

                        </Col>
                    </Row>
            </Fragment>
            );
        }
    }
}
// export default withRouter(SearchBrowser);
export default compose(
    withRouter,
    withStyles(styles)
)(SearchBrowser);