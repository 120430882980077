import React, { Component, Fragment } from 'react';
// import { withOktaAuth } from '@okta/okta-react';
import { withRouter, Route, Redirect, Link } from 'react-router-dom';
import {
    withStyles,
    Typography,
    Fab,
    IconButton,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core';
// import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import moment from 'moment';
import { find, orderBy } from 'lodash';
import { compose } from 'recompose';

// import ResourceEditor from '../components/Admin/Components/ComponentFrame';
// import ErrorSnackbar from '../components/ErrorSnackbar';
// import AdminHeader from '../components/AdminHeader';

const styles = theme => ({
    resources: {
        marginTop: theme.spacing(2),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
    },
});

// const API = process.env.REACT_APP_API || 'http://localhost:3001';



class AdminDashboardGauge extends Component {

    constructor(props) {

        //  pass props to the parent's constructor(?)
        super(props);

        //  set up this component's state(?)
        this.state = {
            gaugeName: this.props.gaugeName,
            error: null,
        };

    };


    

    render() {

        return (

            <div className={`col-6 dashboard-gauge`}>
                <div className={`row`}>
                    <div className={`col-12`}>
                        <h2 className={`h5`}>{this.props.gaugeName}</h2>
                    </div>
                </div>
                <div className={`row`}>
                    <div className={`col-12`}>
                        <p className={`null`}>{this.props.gaugeName}</p>
                    </div>
                </div>
            </div>

        );

    };
};

export default compose(
    withStyles(styles),
)(AdminDashboardGauge);